import { Dispatch, SetStateAction, useContext } from 'react';
import colors from '../../../colors';
import { CONTENT_MARGIN } from '../../../styleConstants';
import { UiTexts } from '../../../model';
import { LanguageContext } from '../../../contexts/language-context';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type InputProps = {
  openState: boolean;
  openStateHandler: Dispatch<SetStateAction<boolean>>;
  titleKey: keyof UiTexts;
  buttonLabelKey?: keyof UiTexts;
  message: keyof UiTexts;
};

const CustomMessageDialog = (props: InputProps): JSX.Element => {
  const { titleKey, buttonLabelKey, message, openState, openStateHandler } = props;

  const { getText } = useContext(LanguageContext);

  const handleClose = () => {
    openStateHandler(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: colors.contentBackground,
          boxShadow: 'none',
          borderRadius: 0,
          padding: CONTENT_MARGIN,
          whiteSpace: 'pre-wrap',
        },
      }}
      style={{ zIndex: 13000 }}
      aria-labelledby='custom-dialog-title'
      open={openState}
      onClose={handleClose}
    >
      {titleKey && (
        <DialogTitle id='custom-dialog-title'>
          <Typography variant='h6'>{getText(titleKey)}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Typography>{getText(message)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button size='large' variant='contained' onClick={() => handleClose()}>
          {buttonLabelKey ? getText(buttonLabelKey) : getText('dialog-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomMessageDialog;
