import { useContext } from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { SepticTankForm, WeeklyFrequencyEnum } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ShowSummaryItem } from '../../../../../util/showDataUtils';
import { LanguageContext } from '../../../../../contexts/language-context';

interface Props {
  septicTankOption: string;
  septicTank: Partial<SepticTankForm>;
}

const PropertySepticTankSummary = ({ septicTankOption, septicTank }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const subtitleMargin = LargerThanBreakpoint('md') ? 2 : 1;

  const translateWeeklyFrequencyCode = (code: string) => {
    switch (code) {
      case WeeklyFrequencyEnum.EveryHalfAYear:
        return getText('need-for-emptying-weekly-frequency-half-year');
      case WeeklyFrequencyEnum.EveryYear:
        return getText('need-for-emptying-weekly-frequency-year');
      case WeeklyFrequencyEnum.EverySecondYear:
        return getText('need-for-emptying-weekly-frequency-two-year');
      case WeeklyFrequencyEnum.EveryThirdyear:
        return getText('need-for-emptying-weekly-frequency-three-year');
      case WeeklyFrequencyEnum.Other:
        return getText('need-for-emptying-weekly-frequency-other');
    }
  };

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6' sx={{ paddingTop: '10px' }} marginBottom={subtitleMargin}>
          {septicTankOption}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='septic-tank-covers-number' data={septicTank.numberOfLids?.toString()} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='septic-tank-volume' data={septicTank.volume?.toString()} />
      </Grid>
      {septicTank.blackWater && (
        <Grid item xs={1}>
          <ShowSummaryItem
            labelKey='need-for-emptying-black-water-resident-count'
            data={septicTank.blackWater?.residentCount?.toString()}
          />
        </Grid>
      )}

      {(septicTank.grayWater && (
        <>
          <Grid item xs={1}>
            <ShowSummaryItem
              labelKey='need-for-emptying-weekly-frequency-dropdown-label'
              data={translateWeeklyFrequencyCode(septicTank.grayWater?.weeklyFrequency)}
            />
          </Grid>
          {septicTank.grayWater.other && (
            <Grid item xs={1}>
              <ShowSummaryItem labelKey='need-for-emptying-other-input-label' data={septicTank.grayWater?.other} />
            </Grid>
          )}
        </>
      )) ||
        (septicTank.blackWater && (
          <>
            <Grid item xs={1}>
              <ShowSummaryItem
                labelKey='need-for-emptying-weekly-frequency-dropdown-label'
                data={translateWeeklyFrequencyCode(septicTank.blackWater?.weeklyFrequency)}
              />
            </Grid>
            {septicTank.blackWater.other && (
              <Grid item xs={1}>
                <ShowSummaryItem labelKey='need-for-emptying-other-input-label' data={septicTank.blackWater?.other} />
              </Grid>
            )}
          </>
        ))}
      <Grid item xs={2}>
        <Typography variant='body2' color={palette.text.secondary}>
          {getText('septic-tank-access-limitations-header')}
        </Typography>
        {septicTank.noLimits && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-limits')}</Typography>
          </Stack>
        )}
        {septicTank.noWinterEmptyings && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-winter')}</Typography>
          </Stack>
        )}
        {septicTank.locked && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-locked')}</Typography>
          </Stack>
        )}
        {septicTank.weightLimit && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-weight-limit')}</Typography>
          </Stack>
        )}
        {septicTank.other && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-take-other-steps')}</Typography>
          </Stack>
        )}
      </Grid>
      {septicTank.weightLimit && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-weight-limit-details'
            data={septicTank.weightLimitDetails}
          />
        </Grid>
      )}
      {septicTank.other && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-option-weight-other'
            data={septicTank.otherDetails}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <ShowSummaryItem labelKey='septic-tank-access-limitations-location' data={septicTank.location} />
      </Grid>
    </Grid>
  );
};

export default PropertySepticTankSummary;
