import { createContext } from 'react';
import { PathWithName } from './BreadcrumbBarContextProvider';

interface Props {
  pathsWithNames: PathWithName;
  updateNameByPath: (newName: string, path: string) => void;
  addCustomPathName: (info: PathWithName) => void;
}

export const BreadcrumbBarContext = createContext<Props>({
  pathsWithNames: {},
  updateNameByPath: () => null,
  addCustomPathName: () => null,
});
export default BreadcrumbBarContext;
