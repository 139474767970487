import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { BillingInfoFormData } from './models';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { LargerThanBreakpoint } from '../../../util/viewportUtils';
import CustomerTypeSection from './add-billing-form/CustomerTypeSection';
import { overlayDialogPadding } from '../../../components/common-materialui/dialogs/OverlayDialog';
import PrivateBillingAddressSection from './add-billing-form/PrivateBillingAddressSection';
import PrivateBillingContactSection from './add-billing-form/PrivateBillingContactSection';
import { useMutation } from '@tanstack/react-query';
import { BillingInfoInput, BillingInfoReply } from '../../../service/authService';
import AuthContext from '../../../contexts/auth-context';
import ProgressComponent from '../../../components/common-materialui/ProgressComponent';
import { get3LetterLanguage } from '../../../util/util';
import ExtendedAlert from '../../../components/common-materialui/status-handling/ExtendedAlert';
import { BillingInfo, CommonDetails, ExtendedInvoiceMethodType, OnlineBroker, UiTexts } from '../../../model';
import CompanyBillingInfoAddressSection from './add-billing-form/CompanyBillingAddressSection';
import BillingExistingSection from './add-billing-form/BillingExistingSection';
import { setValuesToFormFromBillingInfo } from './add-billing-form/utils';
import { convertExtendedInvoicingMethod, validateEInvoiceInformation } from '../../../util/invoicingMethodUtils';

interface Props {
  onPhaseChange: () => void;
  onCancel: () => void;
  customerType: 'company' | 'private';
  setCustomerType: Dispatch<SetStateAction<'private' | 'company'>>;
  form: UseFormReturn<BillingInfoFormData, any>;
  billingId: string;
  setBillingId: React.Dispatch<React.SetStateAction<string>>;
  billingInfos?: BillingInfo[];
  updateCommonDetails: (updatedDetails: Partial<CommonDetails>) => void;
  brokers?: OnlineBroker[];
}

const BillingInfoForm = ({
  onPhaseChange,
  onCancel,
  customerType,
  setCustomerType,
  form,
  billingId,
  setBillingId,
  billingInfos,
  updateCommonDetails,
  brokers,
}: Props): JSX.Element => {
  const formContentRef = useRef(null);
  const { getText, lang } = useContext(LanguageContext);
  const [errorAlert, setErrorAlert] = useState<keyof UiTexts | null>(null);
  const authService = useContext(AuthContext);
  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';
  const { addBillingAccount, refreshLogin } = authService;

  // Assumes that if billing id exists, then an existing billing information has been selected
  // See BillingExistingSection.tsx
  const hasSelectedExisting = billingId.trim() !== '';

  const selectedBillingInfo: BillingInfo | undefined = useMemo(() => {
    if (billingInfos && billingInfos.length > 0) {
      return billingInfos.find((info) => info.id === billingId);
    }
    return undefined;
  }, [billingInfos, billingId]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    resetField,
  } = form;

  useEffect(() => {
    if (selectedBillingInfo && formContentRef?.current && hasSelectedExisting) {
      setValuesToFormFromBillingInfo(selectedBillingInfo, setValue, reset);
    }
  }, [selectedBillingInfo, formContentRef?.current]);

  function PhaseChangeIfExisting() {
    if (hasSelectedExisting) {
      const companyName = getValues('companyName');
      const firstName = getValues('firstName');
      const lastName = getValues('lastName');
      const address = getValues('address');
      const postCode = getValues('postCode');
      const city = getValues('city');
      const contactFirstName = getValues('contactFirstName');
      const contactLastName = getValues('contactLastName');
      const phoneNumber = getValues('phoneNumber');
      const email = getValues('email');
      updateCommonDetails({
        companyName,
        firstName,
        lastName,
        address,
        postCode,
        city,
        contactFirstName,
        contactLastName,
        phoneNumber,
        email,
      });
      onPhaseChange();
    }
  }

  const useSameBillingContactPerson = useWatch({
    control,
    name: 'useSameBillingContactPerson',
  });

  useEffect(() => {
    if (useSameBillingContactPerson) {
      const firstName = getValues('firstName');
      setValue('contactFirstName', firstName);

      const lastName = getValues('lastName');
      setValue('contactLastName', lastName);

      updateCommonDetails({ contactFirstName: firstName, contactLastName: lastName });
    } else {
      resetField('contactFirstName');
      resetField('contactLastName');
    }
  }, [useSameBillingContactPerson, getValues, setValue, resetField]);

  function convertAndSendAddBillingAccount(data: BillingInfoFormData) {
    if (!hasSelectedExisting) {
      const inputData: BillingInfoInput = {
        personalIdentificationNumber: customerType === 'private' ? data.personalIdentificationNumber : undefined,
        companyName: customerType === 'company' ? data.companyName : undefined,
        companyCode: customerType === 'company' ? data.companyCode : undefined,
        billingEmail: data.billingEmail,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        postCode: data.postCode,
        city: data.city,
        language: get3LetterLanguage(lang),
        email: null,
        phoneNumber: data.phoneNumber,
        emailInvoicing: false, //why is this here ?
        consumerEInvoiceType: convertExtendedInvoicingMethod(data.consumerEInvoiceType),
        contactName: data.contactLastName + ' ' + data.contactFirstName,
      };
      if (
        data.consumerEInvoiceType === ExtendedInvoiceMethodType.OnlineInvoicing &&
        validateEInvoiceInformation(data.eInvoiceInformation)
      ) {
        inputData.eInvoiceInformation = {
          address: data.eInvoiceInformation?.address ?? '',
          operator: data.eInvoiceInformation?.operator ?? '',
          ediCode: data.eInvoiceInformation?.ediCode ?? '',
        };
      }

      const {
        companyName,
        firstName,
        lastName,
        address,
        postCode,
        city,
        contactFirstName,
        contactLastName,
        phoneNumber,
        billingEmail,
      } = data;

      updateCommonDetails({
        companyName,
        firstName,
        lastName,
        address,
        postCode,
        city,
        contactFirstName,
        contactLastName,
        phoneNumber,
        email: billingEmail,
      });
      addNewBillingAccount(inputData);
    } else {
      PhaseChangeIfExisting();
    }
  }

  const { mutate: addNewBillingAccount, isLoading: addNewBillingAccountIsLoading } = useMutation(
    (data: BillingInfoInput) => addBillingAccount(data),
    {
      onSuccess: (id: BillingInfoReply) => {
        // Token needs to be updated so that created billing account can be used.
        refreshLogin();
        setBillingId(id.billingAccountNumber);
        onPhaseChange();
      },
      onError: () => setErrorAlert('error-general-message'),
    }
  );

  function renderPrivate() {
    if (customerType !== 'private') {
      return null;
    }

    return (
      <>
        <Container>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('emptying-address-billing-address')}</Typography>
            <PrivateBillingAddressSection disabled={hasSelectedExisting} control={control} errors={errors} />
          </Stack>
        </Container>

        <Divider />

        <Container ref={formContentRef}>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('billing-data-billing-contact-person')}</Typography>
            <PrivateBillingContactSection
              disabled={hasSelectedExisting}
              control={control}
              errors={errors}
              customerType={customerType}
            />
          </Stack>
        </Container>
      </>
    );
  }

  function renderCompany() {
    if (customerType !== 'company') {
      return null;
    }

    return (
      <>
        <Container>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('emptying-address-billing-address')}</Typography>
            <CompanyBillingInfoAddressSection
              disabled={hasSelectedExisting}
              control={control}
              errors={errors}
              brokers={brokers}
            />
          </Stack>
        </Container>

        <Divider />

        <Container ref={formContentRef}>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('billing-data-billing-contact-person')}</Typography>
            <PrivateBillingContactSection
              disabled={hasSelectedExisting}
              control={control}
              errors={errors}
              customerType={customerType}
            />
          </Stack>
        </Container>
      </>
    );
  }

  function renderCustomerTypeSelect() {
    if (hasSelectedExisting) {
      return null;
    }

    return (
      <>
        <Container>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('billing-info-type-selector')}</Typography>
            <CustomerTypeSection radioValue={customerType} setRadioValue={setCustomerType} />
          </Stack>
        </Container>

        <Divider />
      </>
    );
  }

  return (
    <Stack direction='column'>
      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>{getText('billing-info-existing-selector')}</Typography>
          <BillingExistingSection
            formReset={reset}
            billingInfos={billingInfos}
            billingId={billingId}
            setBillingId={setBillingId}
            setCustomerType={setCustomerType}
          />
        </Stack>
      </Container>

      <Divider />

      {renderCustomerTypeSelect()}

      {renderPrivate()}
      {renderCompany()}
      <Divider />

      <ProgressComponent isLoading={addNewBillingAccountIsLoading} />
      <Container>
        <ExtendedAlert
          open={!!errorAlert}
          onClose={() => setErrorAlert(null)}
          severity='error'
          sx={{ marginBottom: 2 }}
        >
          {errorAlert ? getText(errorAlert) : null}
        </ExtendedAlert>

        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='secondary' onClick={onCancel}>
              {getText('add-emptying-location-cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='large'
              onClick={handleSubmit(
                (x) => convertAndSendAddBillingAccount(x),
                () => PhaseChangeIfExisting()
              )}
            >
              {hasSelectedExisting ? getText('add-emptying-location-next') : getText('add-emptying-location-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default BillingInfoForm;
