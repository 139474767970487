import React, { useContext, useEffect, useState, useRef, forwardRef } from 'react';
import { InputAdornment, TextField, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';
import { validateInput, Validation } from '../../../util/input/validation';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { Stack } from '@mui/material';
import colors from '../../../colors';

const ERROR_COLOR = colors.errorBackground;
const INPUT_BORDER_COLOR = colors.inputBorder;
const INPUT_BG_COLOR = colors.contentBackground;
const INPUT_FOCUSED_COLOR = colors.inputFocused;
const ERROR_TEXT_COLOR = colors.errorText;
const LABEL_TEXT_COLOR = colors.lightLabel;
const GUIDE_TEXT_BG_COLOR = colors.contentBackground;

type DateFieldProps = {
  val: string;
  label?: keyof UiTexts;
  placeholder?: keyof UiTexts;
  guideText?: string;
  validations?: Validation[];
  showErrors?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const usedTheme = createTheme({
  palette: {
    error: {
      main: ERROR_TEXT_COLOR,
    },
  },
});

const DateField = forwardRef((props: DateFieldProps, ref): JSX.Element => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const {
    val,
    label,
    placeholder,
    guideText,
    validations,
    showErrors, // This is needed to hide errors before submit
    disabled,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const isError = showErrors && !!errorMessage;

  useEffect(() => {
    if (validations) {
      const errorMessages = validateInput(val, validations)
        .map((key) => getText(key))
        .join(', ');
      setErrorMessage(errorMessages);

      // This prevents form submission and puts focus on first invalid field.
      fieldRef.current?.setCustomValidity(errorMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations, val, showErrors]);

  const { getText } = useContext(LanguageContext);

  const inputProps = {
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position='end'>
        <CalendarIcon />
      </InputAdornment>
    ),
  };

  const visibleGuideText = showErrors && errorMessage !== '' ? errorMessage : guideText;

  return (
    <Stack direction={'column'}>
      <ThemeProvider theme={usedTheme}>
        <TextField
          {...props}
          InputProps={inputProps}
          inputRef={fieldRef}
          label={label ? getText(label) : null}
          hiddenLabel={!label}
          placeholder={placeholder ? getText(placeholder) : ''}
          variant='filled'
          error={isError}
          helperText={visibleGuideText}
          margin='dense'
          disabled={disabled}
          sx={{
            '& .MuiInputBase-root': {
              fontFamily: 'SpaceGrotesk-Regular',
              fontWeight: 500,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0,
              border: isError ? `1px solid ${ERROR_COLOR}` : `1px solid ${INPUT_BORDER_COLOR}`,
              backgroundColor: INPUT_BG_COLOR,
              borderRadius: 0,
              transition: usedTheme.transitions.create(['border-color']),
              '&:hover': {
                backgroundColor: INPUT_BG_COLOR,
              },
              '&.Mui-focused': {
                backgroundColor: INPUT_BG_COLOR,
                borderColor: INPUT_FOCUSED_COLOR,
              },
            },
            '& .MuiFormLabel-root': {
              fontFamily: 'SpaceGrotesk-Regular',
              fontWeight: 500,
              color: LABEL_TEXT_COLOR,
              '&.Mui-focused': {
                color: LABEL_TEXT_COLOR,
              },
            },
            '& .MuiFormHelperText-root': {
              fontFamily: 'SpaceGrotesk-Regular',
              backgroundColor: isError ? ERROR_COLOR : GUIDE_TEXT_BG_COLOR,
              marginLeft: 0,
              marginTop: '0.4rem',
              paddingLeft: '0.5rem',
              paddingTop: isError ? '0.5rem' : 0,
              paddingBottom: '0.5rem',
              width: 'calc(100% - 0.5rem)',
            },
          }}
        />
      </ThemeProvider>
    </Stack>
  );
});

export default DateField;
