import { Stack, useTheme, Typography, Box, IconButton, Tooltip } from '@mui/material';
import { useContext, FC, useState } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { ContactPerson } from '../../../model';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import EditContactPerson from './edit-emptying-contact-person/EditContactPerson';

interface Props {
  contactPerson?: ContactPerson;
  emptyingId?: string;
  isCompany?: boolean;
}

const EmptyingLocationContactInfo: FC<Props> = ({ contactPerson, emptyingId, isCompany }): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const [modalOpenState, setModalOpenState] = useState<boolean>(false);

  function renderModal() {
    return (
      <EditContactPerson
        open={modalOpenState}
        handleClose={setModalOpenState}
        contactPerson={contactPerson}
        emptyingId={emptyingId}
        isCompany={isCompany}
      />
    );
  }

  function renderInfoText() {
    return (
      <Stack direction='column'>
        <Typography variant='subtitle2' color={palette.text.secondary}>
          {getText('emptying-location-contact-person')}
        </Typography>

        <Box mt={1}>
          <Typography variant='subtitle1'>{contactPerson?.name}</Typography>
          <Typography variant='body1'>{contactPerson?.email}</Typography>
          <Typography variant='body1'>{contactPerson?.phoneNumber}</Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction='row'
        p={2}
        bgcolor={palette.info.background}
        sx={{ border: `1px solid ${palette.info.border}` }}
        justifyContent={'space-between'}
      >
        {renderInfoText()}
        <Tooltip title={getText('emptying-address-emptying-edit')} placement='top' arrow>
          <IconButton
            aria-label='edit-emptying-contact'
            sx={{ maxHeight: '40px', marginY: '-10px' }}
            onClick={() => setModalOpenState(true)}
          >
            <EditIcon color={palette.text.links} />
          </IconButton>
        </Tooltip>
      </Stack>
      {renderModal()}
    </>
  );
};

export default EmptyingLocationContactInfo;
