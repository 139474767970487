import FormThemeProvider from '../FormThemeProvider';
import { ThemeProvider, Typography } from '@mui/material';

// Using \n doesn't work in schema unless whiteSpace pre-line is defined
const CustomDescriptionField = ({ description }: any) => {
  const theme = FormThemeProvider;

  return (
    <ThemeProvider theme={theme}>
      <Typography display={'block'} sx={{ whiteSpace: 'pre-line' }}>
        {description}
      </Typography>
    </ThemeProvider>
  );
};

export default CustomDescriptionField;
