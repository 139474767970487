import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { OtherForm, OtherRadioGroupOptionsEnum, WeeklyFrequencyEnum } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ShowSummaryItem } from '../../../../../util/showDataUtils';
import { LanguageContext } from '../../../../../contexts/language-context';

interface Props {
  other: Partial<OtherForm>;
}

const PropertyOtherOptionsSummary = ({ other }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const subtitleMargin = LargerThanBreakpoint('md') ? 2 : 1;

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6' sx={{ paddingTop: '10px' }} marginBottom={subtitleMargin}>
          {getText('waste-water-treatment-option-G-secondary')}
        </Typography>
      </Grid>
      {(other.selection === OtherRadioGroupOptionsEnum.grayWaterFilter && (
        <Grid item xs={1}>
          <ShowSummaryItem
            labelKey='well-collection-waste-water-treatment'
            data={getText('other-grey-water-filter-option')}
          />
        </Grid>
      )) ||
        (other.selection === OtherRadioGroupOptionsEnum.groundFilter && (
          <Grid item xs={1}>
            <ShowSummaryItem
              labelKey='well-collection-waste-water-treatment'
              data={getText('other-ground-filter-option')}
            />
          </Grid>
        )) ||
        (other.selection === OtherRadioGroupOptionsEnum.noWasteWaterTreatmentSystem && (
          <>
            <Grid item xs={1}>
              <ShowSummaryItem
                labelKey='well-collection-waste-water-treatment'
                data={getText('other-no-waste-water-treatment-system-option')}
              />
            </Grid>
            <Grid item xs={1}>
              <ShowSummaryItem labelKey='other-no-waste-water-treatment-system-input' data={other.handlingMethod} />
            </Grid>
          </>
        ))}
    </Grid>
  );
};

export default PropertyOtherOptionsSummary;
