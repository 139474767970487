import { useContext } from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CustomerServiceFeedbackRow } from './CustomerServiceFeedbackRow';
import { CustomerServiceFeedbackCard } from './CustomerServiceFeedbackCard';
import ApiContext from '../../contexts/api-context';
import { LanguageContext } from '../../contexts/language-context';
import { DataTable } from '../../components/common-materialui/data-table/DataTable';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import { TableHeaderProps } from '../../components/common-materialui/data-table/interfaces';
import { EmptyingInfo, FeedbackInfo, UiTexts } from '../../model';
import { compareDesc } from 'date-fns';

interface CustomerServiceFeedbackProps {
  emptyingLocations: EmptyingInfo[] | null | undefined;
}

interface SkeletonProps {
  isMobile?: boolean;
}

const CustomerServiceFeedback = ({ emptyingLocations }: CustomerServiceFeedbackProps) => {
  const api = useContext(ApiContext);
  const theme = useTheme();
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { getText } = useContext(LanguageContext);

  const { isLoading, data, error } = useQuery<FeedbackInfo[]>(['customer-service-feedback'], () =>
    api.fetchFeedbackItems()
  );

  const CustomerTableSkeleton = ({ isMobile }: SkeletonProps): JSX.Element => {
    return (
      <>
        {isMobile ? (
          <>
            <Skeleton variant='rectangular' height={100} width={'100%'} sx={{ my: 0.2 }} />
            <Skeleton variant='rectangular' height={100} width={'100%'} sx={{ my: 0.2 }} />
            <Skeleton variant='rectangular' height={100} width={'100%'} sx={{ my: 0.2 }} />
          </>
        ) : (
          <>
            <Skeleton variant='rectangular' height={55} width={'100%'} sx={{ my: 0.2 }} />
            <Skeleton variant='rectangular' height={55} width={'100%'} sx={{ my: 0.2 }} />
            <Skeleton variant='rectangular' height={55} width={'100%'} sx={{ my: 0.2 }} />
          </>
        )}
      </>
    );
  };

  const renderFeedbackSkeleton = () => <CustomerTableSkeleton isMobile={smallerThanMd} />;

  const feedbackTableHeaders = (getText: (key: keyof UiTexts, params?: string[] | undefined) => string) => {
    const headers: TableHeaderProps[] = [
      {
        id: 'subject',
        label: getText('feedback-table-header-subject'),
        alignment: 'left',
      },
      {
        id: 'message',
        label: getText('feedback-table-header-message'),
        alignment: 'left',
      },
      {
        id: 'location',
        label: getText('feedback-table-header-location'),
        alignment: 'left',
      },
      {
        id: 'date',
        label: getText('feedback-table-header-date'),
        alignment: 'left',
      },
    ];

    return headers;
  };

  const renderRows = () => {
    return data
      ?.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
      .map((feedback) => (
        <CustomerServiceFeedbackRow key={feedback.id} feedback={feedback} locations={emptyingLocations} />
      ));
  };

  const renderCards = (): any => {
    return data
      ?.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
      .map((feedback) => (
        <CustomerServiceFeedbackCard key={feedback.id} feedback={feedback} locations={emptyingLocations} />
      ));
  };

  return (
    <>
      <ExceptionHandler error={error}>
        <ContentLoadingHandler isLoading={isLoading} skeleton={renderFeedbackSkeleton()}>
          <DataTable headers={feedbackTableHeaders(getText)} customRows={renderRows()} cards={renderCards()} />
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default CustomerServiceFeedback;
