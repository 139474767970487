import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { Language } from '../../model';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import { ForgotUserNameFormData } from './ForgotUserName.mode';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import HttpError from '../../util/HttpError';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';

type ForgotUsernameProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const ForgotUsername = (props: ForgotUsernameProps): JSX.Element => {
  const history = useHistory();
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { forgotUsername } = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotUserNameFormData>();

  const email = useWatch({
    control,
    name: 'email',
  });

  const { mutateAsync: remindUserName, isLoading: remindUserNameLoading } = useMutation(() => forgotUsername(email));

  const handleForgotUserName = async (data: ForgotUserNameFormData) => {
    try {
      await remindUserName();
      showDialog('message-success-generic-title', 'forgot-username-ok-message', () => history.replace('/login'));
    } catch (err: unknown) {
      if (err instanceof HttpError) {
        if (
          err.statusCode === 400 &&
          err.response != null &&
          err.response.type != null &&
          err.response.type === '/problems/e-services-users/user-account-not-valid'
        ) {
          showDialog('forgot-password-account-not-valid-title', 'forgot-password-account-not-valid-message');
        }
      } else {
        showDialog('error-general-title', 'error-general-message');
      }
    }
  };

  return (
    <>
      <LogoNavigationLanguageBar />
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '530px',
            margin: 'auto',
            border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant='h3'>{getText('forgot-username-header')}</Typography>
              <Typography variant={'body2'} color={palette.text.primary}>
                {getText('forgot-username-description')}
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <ControlTextInput
                control={control}
                name='email'
                label='email'
                error={errors.email}
                validations={['required', 'email']}
              />
              <Link className='small-link' to='/login'>
                {getText('forgot-username-return-to-login-link')}
              </Link>
              <Button
                variant='contained'
                onClick={(e) => void handleSubmit(handleForgotUserName)(e)}
                disabled={remindUserNameLoading}
              >
                {remindUserNameLoading ? (
                  <ProgressComponent variant='circle' isLoading={remindUserNameLoading} />
                ) : (
                  <Typography py={1} variant='button'>
                    {getText('forgot-username-send-button')}
                  </Typography>
                )}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default ForgotUsername;
