import { FinnishSSN } from 'finnish-ssn';
import { FinnishBusinessIds } from 'finnish-business-ids';
import { UiTexts } from '../../model';

type IsEqualTo = { equalToValue: string; };
type IsNotEqualTo = { notEqualToValue: string; };
type MinLength = { minLength: number; };
type Custom = { validate: () => boolean; messageKey: keyof UiTexts; };

export type Validation =
  | 'required'
  | 'hetu'
  | 'company-id'
  | 'email'
  | 'username'
  | 'password'
  | 'integer'
  | 'positive-integer'
  | 'gt_zero'
  | 'phone'
  | 'post-code'
  | 'integer-not-start-zero'
  | IsEqualTo
  | IsNotEqualTo
  | MinLength
  | Custom;

type ErrorMsgKey = keyof UiTexts;

const requiredValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    errorMessages.push('validation-required');
  }
};

const hetuValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!FinnishSSN.validate(value)) {
    errorMessages.push('validation-invalid-ssn');
  }
};

const companyIdValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!FinnishBusinessIds.isValidBusinessId(value)) {
    errorMessages.push('validation-invalid-company-id');
  }
};

const emailValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    return;
  }
  if (!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
    errorMessages.push('validation-invalid-email');
  }
};

const usernameValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    errorMessages.push('validation-required');
    return;
  }
  if (!value.match(/^[0-9a-zA-Z-._@]+$/)) {
    errorMessages.push('validation-invalid-username');
  }
};

const passwordValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value || value.length < 12) {
    errorMessages.push('validation-invalid-password');
  }
};

const integerValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    return;
  }

  if (!value.match(/^\d+$/)) {
    errorMessages.push('validation-invalid-integer');
  }
};

const positiveIntegerValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (value && !value.toString().match(/^[0-9]+$/)) {
    errorMessages.push('validation-invalid-positive-integer');
  }
};

const gtZeroValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  const regEx = /^(?=.*[1-9])\d*$/;
  if (!regEx.test(value)) {
    errorMessages.push('validation-greater-than-zero');
  }
};

const phoneValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    return;
  }

  // Remove whitespaces
  const cleanedValue = value.replace(/\s/g, '');

  // Phonenumber can start with 0 or + and must contain only numbers after that
  const regEx = /^[0\+]\d+$/;

  if (!regEx.test(cleanedValue)) {
    errorMessages.push('validation-invalid-phone');
  }
};

const postCodeValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    return;
  }
  const regEx = /^[0-9]+$/;
  if (!regEx.test(value) || value.length !== 5) {
    errorMessages.push('validation-invalid-post-code');
  }
};

const integerNotStartZeroValidation = (value: string, errorMessages: ErrorMsgKey[]) => {
  if (!value) {
    return;
  }
  const regEx = /^(?!0)[0-9].*$/;
  if (!regEx.test(value)) {
    errorMessages.push('validation-integer-not-start-zero');
  }
};

export const validateInput = (value: string, validations: Validation[]): ErrorMsgKey[] => {
  let errorMessages: ErrorMsgKey[] = [];

  validations.forEach((validation) => {
    switch (validation) {
      case 'required':
        requiredValidation(value, errorMessages);
        break;

      case 'hetu':
        hetuValidation(value, errorMessages);
        break;

      case 'company-id':
        companyIdValidation(value, errorMessages);
        break;

      case 'email':
        emailValidation(value, errorMessages);
        break;

      case 'username':
        usernameValidation(value, errorMessages);
        break;

      case 'password':
        passwordValidation(value, errorMessages);
        break;

      case 'integer':
        integerValidation(value, errorMessages);
        break;

      case 'positive-integer':
        positiveIntegerValidation(value, errorMessages);
        break;

      case 'gt_zero':
        gtZeroValidation(value, errorMessages);
        break;

      case 'phone':
        phoneValidation(value, errorMessages);
        break;

      case 'post-code':
        postCodeValidation(value, errorMessages);
        break;

      case 'integer-not-start-zero':
        integerNotStartZeroValidation(value, errorMessages);
        break;

      default:
        const eqVal = (validation as IsEqualTo).equalToValue;
        const notEqVal = (validation as IsNotEqualTo).notEqualToValue;
        const minLengthVal = (validation as MinLength).minLength;
        const customValidation = (validation as Custom).validate;
        const customValidationMessageKey = (validation as Custom).messageKey;

        if (eqVal && eqVal !== value) {
          errorMessages.push('validation-not-matching');
        }
        if (notEqVal && notEqVal === value) {
          errorMessages.push('validation-matching');
        }
        if (minLengthVal && value.length > 0 && value.length < minLengthVal) {
          errorMessages.push('validation-too-short');
        }
        if (customValidation && !customValidation()) {
          errorMessages.push(customValidationMessageKey);
        }
        break;
    }
  });
  return errorMessages;
};
