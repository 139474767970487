import { Button, Container, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState, useContext } from 'react';
import { ReactComponent as MenuIcon } from './../../../assets/icons/menu.svg';
import AuthContext from '../../../contexts/auth-context';
import MenuDialog from './MenuDialog';
import { renderLogo } from './renderUtils';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';

const LogoNavigationMenuBar = (): JSX.Element => {
  const authService = useContext(AuthContext);
  const { getText } = useContext(LanguageContext);

  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  // TODO: Decide if these could be moved to theme.
  // The question is: Is menu button unique or can it be styled with generic button styling?
  const menuButtonSharedStyles = {
    height: '44px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'divider',
    m: 0,
    color: 'text.primary',
  };

  const smallMenuButton = () => {
    return (
      <Button sx={{ ...menuButtonSharedStyles, minWidth: '44px', maxWidth: '44px' }} onClick={() => setOpen(!open)}>
        <MenuIcon />
      </Button>
    );
  };

  const bigMenuButton = () => {
    return (
      <Button
        sx={{
          ...menuButtonSharedStyles,
          minWidth: '131px',
          maxWidth: '131px',
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction='row' spacing={1}>
          <MenuIcon color='secondary' />
          <Typography variant='body1'>{getText('menu')}</Typography>
        </Stack>
      </Button>
    );
  };

  return (
    <>
      <Stack direction='column'>
        <Container
          sx={{
            [theme.breakpoints.up('xs')]: {
              height: 80,
              px: 1,
            },
            [theme.breakpoints.up('md')]: {
              height: 100,
              px: 1.5,
            },
            [theme.breakpoints.up('lg')]: {
              px: 2,
            },
            py: 1.5,
          }}
        >
          <Stack direction={'row'} height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            {renderLogo(authService, theme, history)}
            {largerThanPhone ? bigMenuButton() : smallMenuButton()}
          </Stack>
        </Container>
        <Divider />
      </Stack>
      <MenuDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default LogoNavigationMenuBar;
