import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { Language } from '../../model';
import HttpError from '../../util/HttpError';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import { useMutation } from '@tanstack/react-query';
import { useForm, useWatch } from 'react-hook-form';
import ControlCheckboxInput from '../../components/common-materialui/form/ControlCheckboxInput';
import TermsAndConditions from '../../components/common-materialui/TermsAndConditions';

type ResetPasswordProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

export interface ResetPasswordForm {
  password: string;
  confirmedPassword: string;
  termsAndConditions: boolean;
}

const ResetPassword = ({ language, setLanguage }: ResetPasswordProps): JSX.Element => {
  const history = useHistory();
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { resetPassword } = useContext(AuthContext);
  const showDialog = useContext(DialogContext);
  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    try {
      const pageUrl: string = decodeURIComponent(window.location.href);
      const splitBase = pageUrl.split('reset-password?');
      const splitVar = splitBase[1].split('&');
      setToken(splitVar[0].split('token=')[1]);
      setEmail(splitVar[1].split('email=')[1]);
      setUserName(splitVar[2].split('username=')[1]);
      const langParam = splitVar[3].split('newLang=')[1];
      if (langParam != null) {
        setLanguage(langParam as Language);
      } else {
        setLanguage('fi');
      }
    } catch (err: unknown) {
      console.error(err);
      showDialog('error-general-title', 'error-general-message', () => history.replace('/login'));
    }
  }, []);

  const { mutateAsync: getNewPassword, isLoading: getNewPasswordLoading } = useMutation((data: ResetPasswordForm) =>
    resetPassword({ email, userName, token, password: data.password })
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>();

  const password = useWatch({
    control,
    name: 'password',
  });

  const terms = useWatch({
    control,
    name: 'termsAndConditions',
  });

  const handleResetPassword = async (data: ResetPasswordForm) => {
    try {
      await getNewPassword(data);
      showDialog('message-success-generic-title', 'reset-password-ok-message', () => history.replace('/login'));
    } catch (err: unknown) {
      if (err instanceof HttpError) {
        if (
          err.statusCode === 400 &&
          err.response != null &&
          err.response.type != null &&
          err.response.type === '/problems/e-services-users/user-account-not-valid'
        ) {
          showDialog('forgot-password-account-not-valid-title', 'forgot-password-account-not-valid-message');
        } else if (
          err.statusCode === 400 &&
          err.response != null &&
          err.response.type != null &&
          err.response.type === '/problems/identity-operation-failed'
        ) {
          showDialog('reset-password-identity-failed-header', 'reset-password-identity-failed-message');
        } else {
          showDialog('error-general-title', 'error-general-message');
        }
      } else {
        showDialog('error-general-title', 'error-general-message');
      }
    }
  };

  return (
    <>
      <LogoNavigationLanguageBar />
      {email && token ? (
        <Container>
          <Box
            sx={{
              backgroundColor: palette.background.level2,
              padding: 3,
              maxWidth: '530px',
              margin: 'auto',
              border: `1px solid ${palette.divider}`,
            }}
          >
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Typography variant='h3'>{getText('reset-password-header')}</Typography>
              </Stack>
              <Stack spacing={0}>
                <ControlTextInput
                  control={control}
                  name='password'
                  label='password'
                  inputType='password'
                  error={errors.password}
                  validations={['required', 'password']}
                />
                <ControlTextInput
                  control={control}
                  name='confirmedPassword'
                  label='confirm-pw'
                  inputType='password'
                  error={errors.confirmedPassword}
                  validations={['required', { equalToValue: password }]}
                />
                <ControlCheckboxInput
                  label={<TermsAndConditions type='terms-of-service' />}
                  validations={['required']}
                  control={control}
                  name='termsAndConditions'
                  error={errors.termsAndConditions}
                />
              </Stack>
              <Stack spacing={3} justifyContent={'center'} textAlign={'center'}>
                <Button
                  variant='contained'
                  disabled={!terms}
                  onClick={(e) => void handleSubmit(handleResetPassword)(e)}
                >
                  {getNewPasswordLoading ? (
                    <ProgressComponent variant='circle' isLoading={getNewPasswordLoading} />
                  ) : (
                    <Typography py={1} variant='button'>
                      {getText('reset-password-send-button')}
                    </Typography>
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Container>
      ) : (
        <ProgressComponent isLoading />
      )}
      ;
    </>
  );
};

export default ResetPassword;
