import React, { useContext } from 'react';
import { ReactComponent as TrashIcon } from '../assets/trash-2.svg';
import { LanguageContext } from '../contexts/language-context';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import { Button, IconButton, Typography } from '@mui/material';

export function ArrayFieldTemplateAddAndRemove(props: ArrayFieldTemplateProps) {
  const { getText } = useContext(LanguageContext);

  return (
    <React.Fragment>
      {props.items.map((element: any) => (
        <div>
          {element.children}
          {element.hasRemove && (
            <IconButton type='button' sx={{ marginLeft: '0.5rem', minWidth: '90%' }}>
              <TrashIcon onClick={element.onDropIndexClick(element.index)} />
            </IconButton>
          )}
        </div>
      ))}
      {props.canAdd && (
        <Button onClick={props.onAddClick}>
          <Typography py={1} variant='button'>
            {getText('array-add-button')}
          </Typography>
        </Button>
      )}
    </React.Fragment>
  );
}
