import { InvoiceInfo } from '../model';

export function groupInvoices(invoices: InvoiceInfo[]): Map<string, InvoiceInfo[]> {
  const invoiceGrouper = (invoiceMap: Map<string, InvoiceInfo[]>, invoice: InvoiceInfo): Map<string, InvoiceInfo[]> => {
    const groupNumber = invoice.customerNumber;
    const billingName = invoice.billingName;
    const uniqueValue = billingName + ', ' + groupNumber;
    let invoices = invoiceMap.get(uniqueValue);

    if (!invoices) {
      invoices = [invoice];
    } else {
      invoices.push(invoice);
    }
    invoiceMap.set(uniqueValue, invoices);
    return invoiceMap;
  };

  invoices.sort((a, b) => b.invoiceNumber - a.invoiceNumber);

  return invoices.reduce(invoiceGrouper, new Map());
}
