import React, { useEffect } from 'react';
import FormThemeProvider from '../FormThemeProvider';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, ThemeProvider } from '@mui/material';
import { getUiSchemaTitle } from './utils';

const CustomCheckboxesWidget = function (props: any) {
  const [value, setValue] = React.useState<Array<any>>([]);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [label, setLabel] = React.useState<string>(props.label);
  const theme = FormThemeProvider;

  const initialize = (properties: any) => {
    const enumValues = [...(properties?.schema?.items?.enum ?? null)];
    const defaultValues = properties.schema.default ?? [];
    let enumNames = properties.schema.items?.enumNames ? { ...properties.schema.items?.enumNames } : { ...enumValues };
    if (properties?.uiSchema != null) {
      const schemaNames = properties.uiSchema['ui:options']?.enumNames ?? { ...enumNames };
      if (schemaNames != null) {
        for (let i = 0; i < schemaNames.length; i++) {
          enumNames[i] = schemaNames[i];
        }
      }
    }

    setLabel(getUiSchemaTitle(props));

    let data: Array<any> = enumValues.map((x: any) => [x, x]);
    for (let i = 0; i < enumValues.length; i++) {
      let text = enumNames[i] ?? enumValues[i];
      data[i] = [enumValues[i], text];
    }

    setValue(defaultValues);
    setOptions(data);

    props.onChange(defaultValues);
  };

  useEffect(() => {
    initialize(props);
  }, [props.formContext.formId, props.formContext.language]);

  const onChange = (event: any) => {
    if (options == null) {
      return;
    }
    const indexValue = event.target.value;
    const index = Number(indexValue);
    const checked = event.target.checked;
    let convertedValue = options[index][0];

    let values = value;
    if (!checked) {
      const index = values.indexOf(convertedValue);
      if (index > -1) {
        values.splice(index, 1);
      }
    } else {
      values.push(convertedValue);
    }

    if (values.length > 0 && typeof values[0] === 'number') {
      values.sort(function (a, b) {
        return a - b;
      });
    } else {
      values.sort();
    }

    setValue(values);
    props.onChange(values);
  };

  if (options === null) {
    return <FormControl />;
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl required={props?.required}>
        <FormLabel>{label + '*'}</FormLabel>
        <FormGroup>
          {options.map((opt: any, index: any) => (
            <FormControlLabel
              key={`${props.id}-control-${opt[0]}`} // Use a unique identifier instead of the index
              control={<Checkbox key={`${props.id}-check-${opt[0]}`} value={index} onChange={onChange} />}
              label={opt[1]}
              checked={value.includes(opt[0])}
            />
          ))}
        </FormGroup>
      </FormControl>
    </ThemeProvider>
  );
};

export default CustomCheckboxesWidget;
