import { useContext, useEffect } from 'react';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import { useHistory } from 'react-router-dom';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';

type LogoutProps = {};

const Logout = (props: LogoutProps): JSX.Element => {
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    RedirectToLogout();
  }, []);

  const RedirectToLogout = () => {
    if (authService.hasSamlSession()) {
      const url = api.getSamlLogoutUrl();
      authService.clearSession();
      window.location.replace(url);
    } else {
      authService.logout();
      history.replace('/login');
    }
  };

  return <ProgressComponent isLoading />;
};

export default Logout;
