import { useEffect, useState } from 'react';
import { labelValue } from '@rjsf/utils';
import FormThemeProvider from '../FormThemeProvider';
import { Checkbox, FormControl, FormControlLabel, ThemeProvider } from '@mui/material';
import { getUiSchemaTitle } from './utils';

export default function CustomCheckboxWidget(props: any) {
  const [label, setLabel] = useState<string>(props.label);
  const theme = FormThemeProvider;

  const requiredLabel = props.required ? ' *' : '';

  useEffect(() => {
    setLabel(getUiSchemaTitle(props));
  }, [props.formContext.formId, props.formContext.language]);

  return (
    <ThemeProvider theme={theme}>
      <FormControl required={props.required}></FormControl>
      <FormControlLabel
        control={
          <Checkbox
            id={props.id}
            name={props.id}
            checked={typeof props.value === 'undefined' ? false : Boolean(props.value)}
            required={props.required}
            disabled={props.disabled || props.readonly}
            autoFocus={props.autofocus}
            onChange={() => props.onChange(!props.value)}
          />
        }
        label={labelValue(label, props.hideLabel, false) + requiredLabel}
      />
    </ThemeProvider>
  );
}
