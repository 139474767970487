import { useContext } from 'react';
import { CustomerType, Language } from '../../../model';
import { Box, Button, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import ControlTextInput from '../../../components/common-materialui/form/ControlTextInput';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  DisabledStates,
  RegisterFormData,
  RenderBasicInformationSection,
  renderUsernamePasswordSection,
} from '../sharedRegisterSections';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../contexts/auth-context';
import { useMutation } from '@tanstack/react-query';
import { IdentifiedExistingCustomerConnectParams } from '../../../service/authService';
import ProgressComponent from '../../../components/common-materialui/ProgressComponent';
import { getAlertWithErrorMessages } from '../../../components/common-materialui/status-handling/httpErrorUtils';

interface Props {
  identificationId: string;
  firstName: string;
  lastName: string;
  customerType: CustomerType | null;
  language: Language;
  onPreviousPhaseChange: () => void;
}

const CompleteExistingCustomerRegistration = ({
  identificationId,
  firstName,
  lastName,
  customerType,
  language,
  onPreviousPhaseChange,
}: Props): JSX.Element => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { logIn, connectExistingIdentifiedCustomer } = useContext(AuthContext);
  const history = useHistory();
  const methods = useForm<RegisterFormData>({
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      language: language,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const disabledBasicInformation: DisabledStates = {
    firstName: !!firstName,
    lastName: !!lastName,
  };

  const currentUsername = useWatch({
    control,
    name: 'username',
  });

  const currentPassword = useWatch({
    control,
    name: 'password',
  });

  const {
    mutate: loginAfterRegister,
    isLoading: loginAfterRegisterLoading,
    error: loginAfterRegisterError,
    reset: loginAfterRegisterReset,
  } = useMutation(() => logIn(currentUsername, currentPassword), {
    onSuccess: () => history.push('/emptying-infos'),
  });

  const {
    mutate: register,
    isLoading: registerLoading,
    error: registerError,
    reset: registerReset,
  } = useMutation((data: IdentifiedExistingCustomerConnectParams) => connectExistingIdentifiedCustomer(data), {
    onSuccess: () => loginAfterRegister(),
  });

  function mapAndRegister(data: RegisterFormData) {
    if (data.invoicingNumber && data.customerNumber) {
      const inputData: IdentifiedExistingCustomerConnectParams = {
        invoiceNumber: data.invoicingNumber,
        customerNumber: data.customerNumber,
        phoneNumber: data.phoneNumber,
        email: data.email,
        userName: data.username,
        password: data.password,
        identificationId: identificationId,
        language: data.language,
        //firstName: data.firstName,
        //lastName: data.lastName,
      };
      register(inputData);
    }
  }

  function renderHeader() {
    return (
      <Stack spacing={2}>
        <Typography variant='h3'>{getText('login-info-create-account-existing')}</Typography>
        <Typography variant={'body2'} color={palette.text.primary}>
          {getText('register-existing-account-description')}
        </Typography>
      </Stack>
    );
  }

  function renderCustomershipInformationSection() {
    return (
      <Stack spacing={0}>
        <Typography mb={2} variant='h5'>
          {getText('register-customership-information')}
        </Typography>
        <ControlTextInput
          control={control}
          name='invoicingNumber'
          label='basic-info-invoice-number'
          error={errors.invoicingNumber}
          validations={['required', 'integer']}
        />
        <ControlTextInput
          control={control}
          name='customerNumber'
          label='basic-info-customer-number'
          error={errors.customerNumber}
          validations={['required']}
        />
      </Stack>
    );
  }

  function clearErrors() {
    registerReset();
    loginAfterRegisterReset();
  }

  function renderSendButtonAndLink() {
    return (
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          {registerError || loginAfterRegisterError
            ? getAlertWithErrorMessages(registerError || loginAfterRegisterError, getText, clearErrors)
            : null}
          <Box>
            <Button size='large' color='secondary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='large'
              onClick={(e) => void handleSubmit(mapAndRegister)(e)}
              disabled={
                customerType === null || customerType.length === 0 || registerLoading || loginAfterRegisterLoading
              }
            >
              {registerLoading || loginAfterRegisterLoading ? (
                <ProgressComponent variant='circle' isLoading={registerLoading || loginAfterRegisterLoading} />
              ) : (
                getText('create-user')
              )}
            </Button>
          </Box>
        </Stack>
      </Container>
    );
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '515px',
            margin: 'auto',
            border: `none`,
            //border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            {renderHeader()}
            {renderCustomershipInformationSection()}
            <Divider />
            <RenderBasicInformationSection
              getText={getText}
              titleKey='basic-info-modify'
              disabledStates={disabledBasicInformation}
            />
            {renderUsernamePasswordSection(control, errors, getText, currentPassword)}
          </Stack>
        </Box>
      </Container>
      {renderSendButtonAndLink()}
    </FormProvider>
  );
};

export default CompleteExistingCustomerRegistration;
