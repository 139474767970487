import { useContext } from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { ClosedTankForm, WeeklyFrequencyEnum } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ShowSummaryItem } from '../../../../../util/showDataUtils';
import { LanguageContext } from '../../../../../contexts/language-context';

interface Props {
  closedTankOption: string;
  closedTank: Partial<ClosedTankForm>;
}

const PropertyClosedTankSummary = ({ closedTankOption, closedTank }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const subtitleMargin = LargerThanBreakpoint('md') ? 2 : 1;

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6' sx={{ paddingTop: '10px' }} marginBottom={subtitleMargin}>
          {closedTankOption}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='closed-tank-volume' data={closedTank.volume?.toString()} />
      </Grid>
      {(closedTank.needForEmptying?.weeklyFrequency === WeeklyFrequencyEnum.OnDemand && (
        <Grid item xs={1}>
          <ShowSummaryItem
            labelKey='need-for-emptying-weekly-frequency-dropdown-label'
            data={getText('need-for-emptying-closed-tank-weekly-frequency-on-demand')}
          />
        </Grid>
      )) ||
        (closedTank.needForEmptying?.weeklyFrequency === WeeklyFrequencyEnum.Other && (
          <>
            <Grid item xs={1}>
              <ShowSummaryItem
                labelKey='need-for-emptying-weekly-frequency-dropdown-label'
                data={getText('need-for-emptying-weekly-frequency-other')}
              />
            </Grid>
            <Grid item xs={2}>
              <ShowSummaryItem
                labelKey='need-for-emptying-closed-tank-other-input-label'
                data={closedTank.needForEmptying.other}
              />
            </Grid>
          </>
        ))}
      <Grid item xs={2}>
        <Typography variant='body2' color={palette.text.secondary}>
          {getText('septic-tank-access-limitations-header')}
        </Typography>
        {closedTank.noLimits && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-limits')}</Typography>
          </Stack>
        )}
        {closedTank.noWinterEmptyings && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-winter')}</Typography>
          </Stack>
        )}
        {closedTank.locked && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-locked')}</Typography>
          </Stack>
        )}
        {closedTank.weightLimit && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-weight-limit')}</Typography>
          </Stack>
        )}
        {closedTank.other && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-take-other-steps')}</Typography>
          </Stack>
        )}
      </Grid>
      {closedTank.weightLimit && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-weight-limit-details'
            data={closedTank.weightLimitDetails}
          />
        </Grid>
      )}
      {closedTank.other && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-option-weight-other'
            data={closedTank.otherDetails}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <ShowSummaryItem labelKey='septic-tank-access-limitations-location' data={closedTank.location} />
      </Grid>
    </Grid>
  );
};

export default PropertyClosedTankSummary;
