import { useState, useContext, useEffect, useCallback } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import ApiContext from '../../contexts/api-context';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { InvoiceInfo } from '../../model';
import { groupInvoices } from '../../util/invoiceLogic';
import DropdownSelect from '../../components/common-materialui/DropdownSelect';
import { DataTable } from '../../components/common-materialui/data-table/DataTable';
import { InvoicesTableSkeleton, invoiceTableHeaders } from './invoicesUtils';
import { InvoiceRow } from './InvoiceRow';
import { InvoiceCard } from './InvoiceCard';
import ExceptionHandler, { InfoBoxElements } from '../../components/common-materialui/status-handling/ExceptionHandler';
import { ReactComponent as InfoImage } from '../../assets/icons/NoInvoicesBackground.svg';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';

const Invoices = (): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const api = useContext(ApiContext);
  const [error, setError] = useState(null);
  const [displayFetchFailedDialog, setDisplayFetchFailedDialog] = useState<boolean>(false);
  const [infoMessageKeys, setInfoMessageKeys] = useState<InfoBoxElements | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string>('');
  const [currentInvoiceGroup, setCurrentInvoiceGroup] = useState<InvoiceInfo[]>([]);
  const [invoices, setInvoices] = useState<InvoiceInfo[] | null>(null);
  const [invoiceGroups, setInvoiceGroups] = useState<Map<string, InvoiceInfo[]>>(new Map());
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = useCallback((event: any) => {
    setSelectedCustomerNumber(event.target.value);
  }, []);

  function getInvoiceInfo() {
    setIsLoading(true);
    return api.fetchInvoices().then((infos) => {
      setInvoices(infos);
      if (infos.length < 1) {
        setInfoMessageKeys({
          imageElement: <InfoImage />,
          headerKey: 'invoice-page-no-invoices-found-header',
          descriptionKey: 'invoice-page-no-invoices-found-description',
        });
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getInvoiceInfo().catch((err: any) => {
      setError(err);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!!invoices) {
      //TODOInvoices, Uses invoiceLogic.tsx groupInvoices function, move file here now or later?
      setInvoiceGroups(groupInvoices(invoices));
    }
  }, [invoices]);

  useEffect(() => {
    if (invoiceGroups.size > 0) {
      setSelectedCustomerNumber(invoiceGroups.keys().next().value);
    }
  }, [invoiceGroups]);

  useEffect(() => {
    const current = invoiceGroups.get(selectedCustomerNumber) ?? [];
    setCurrentInvoiceGroup(current);
    if (current.length < 1 && !isLoading) {
      setInfoMessageKeys({
        imageElement: <InfoImage />,
        headerKey: 'invoice-page-no-invoices-found-header',
        descriptionKey: 'invoice-page-no-invoices-found-description',
      });
    }
    if (current.length > 0 && !isLoading) {
      setInfoMessageKeys(undefined);
    }
  }, [selectedCustomerNumber]);

  function headerContent() {
    return (
      <Stack spacing={3} justifyContent='space-between'>
        <Typography variant='h2'>
          {getText('invoices')}
          <Typography variant='body1' mt={1}>
            {getText('invoices-header-description')}
          </Typography>
        </Typography>
        {
          <DropdownSelect
            items={Array.from(invoiceGroups.keys()).map((key) => {
              return { value: key, text: key };
            })}
            value={selectedCustomerNumber}
            onChange={handleChange}
            width={'328px'}
          />
        }
      </Stack>
    );
  }

  function renderCards(info: InvoiceInfo[]) {
    return info.map((x, idx) => {
      return <InvoiceCard key={x.invoiceNumber} info={x} api={api} setFetchErrorState={setDisplayFetchFailedDialog} />;
    });
  }

  function renderRows(info: InvoiceInfo[]) {
    return info.map((x, idx) => {
      return <InvoiceRow key={x.invoiceNumber} info={x} api={api} setFetchErrorState={setDisplayFetchFailedDialog} />;
    });
  }

  const handleClose = useCallback(() => {
    setDisplayFetchFailedDialog(false);
  }, []);

  function renderFailedFetchDialog() {
    return (
      <Dialog open={displayFetchFailedDialog} onClose={handleClose}>
        <DialogTitle id='alert-dialog-title'>
          <Typography>{getText('error-general-title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='body1'>{getText('error-fetching-file')}</Typography>
          <Button sx={{ marginBottom: '0', marginTop: '1rem' }} variant='contained' onClick={handleClose}>
            {getText('dialog-ok')}
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  function renderSkeleton() {
    return <InvoicesTableSkeleton isMobile={smallerThanMd} />;
  }

  return (
    <>
      {renderFailedFetchDialog()}
      <Header content={headerContent()} />
      <ProgressComponent isLoading={isLoading} />

      <ExceptionHandler error={error} infoBoxElements={infoMessageKeys}>
        <ContentLoadingHandler isLoading={isLoading} skeleton={renderSkeleton()}>
          <DataTable
            headers={invoiceTableHeaders(getText)}
            customRows={renderRows(currentInvoiceGroup)}
            cards={renderCards(currentInvoiceGroup)}
          />
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default Invoices;
