import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import { UiTexts } from '../../../model';
import { Validation, validateInput } from '../../../util/input/validation';
import FormErrorField from './FormErrorField';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/language-context';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: FieldError | undefined;
  label: keyof UiTexts | JSX.Element;
  validations: Validation[];
  disabled?: boolean;
}

const ControlCheckboxInput = <T extends FieldValues>({
  control,
  name,
  error,
  label,
  validations,
  disabled = false,
}: Props<T>) => {
  const { getText } = useContext(LanguageContext);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: { validateFunc: (v) => validateInput(v, validations)[0] },
      }}
      render={({ field: { onChange, value } }) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={!!value} onChange={onChange} />}
              label={typeof label === 'string' ? getText(label) : label}
              disabled={disabled}
            />
          </FormGroup>
          <FormErrorField errorMsg={error?.message} />
        </>
      )}
    />
  );
};

export default ControlCheckboxInput;
