import React, { Dispatch, SetStateAction, useContext, useEffect, useState, useRef } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';
import { validateInput, Validation } from '../../../util/input/validation';
import { Stack } from '@mui/material';
import { InputAdornment, TextField, ThemeProvider, createTheme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import colors from '../../../colors';

const ERROR_COLOR = colors.errorBackground;
const INPUT_BORDER_COLOR = colors.inputBorder;
const INPUT_BG_COLOR = colors.contentBackground;
const INPUT_FOCUSED_COLOR = colors.inputFocused;
const ERROR_TEXT_COLOR = colors.errorText;
const LABEL_TEXT_COLOR = colors.lightLabel;
const GUIDE_TEXT_BG_COLOR = colors.contentBackground;

type TextInputProps = {
  val: string;
  setter: Dispatch<SetStateAction<string>>;
  label?: keyof UiTexts;
  placeholder?: keyof UiTexts;
  inputType?: string;
  minValue?: number;
  guideText?: string;
  validations?: Validation[];
  showErrors?: boolean;
  adornmentStart?: JSX.Element;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  multiline?: boolean;
  maxLength?: number;
};

const usedTheme = createTheme({
  palette: {
    error: {
      main: ERROR_TEXT_COLOR,
    },
  },
});

const TextInput = (props: TextInputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const {
    val,
    setter,
    label,
    placeholder,
    inputType,
    minValue,
    guideText,
    validations,
    showErrors,
    adornmentStart,
    onChange,
    disabled,
    multiline,
    maxLength,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const isError = showErrors && !!errorMessage;

  const { getText } = useContext(LanguageContext);
  const theme = useTheme();

  useEffect(() => {
    handleValidation();
  }, [validations, val, showErrors]);

  const handleValidation = () => {
    if (validations) {
      const errorMessages = validateInput(val, validations)
        .map((key) => getText(key))
        .join(', ');
      setErrorMessage(errorMessages);
      ref.current?.setCustomValidity(errorMessages);
    }
  };

  const getAdornment = () =>
    adornmentStart ? <InputAdornment position='start'>{adornmentStart}</InputAdornment> : null;

  const getGuideText = () =>
    !guideText && multiline && maxLength ? `${maxLength - val.length}/${maxLength}` : guideText;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    } else {
      setter(e.target.value);
    }
  };

  const inputStyles = {
    fontFamily: 'SpaceGrotesk-Regular',
    fontWeight: 500,
    lineHeight: '30px',
    paddingLeft: multiline ? '0.5rem' : '0',
    border: isError ? `1px solid ${ERROR_COLOR}` : `1px solid ${INPUT_BORDER_COLOR}`,
    borderRadius: 0,
    backgroundColor: INPUT_BG_COLOR,
    transition: theme.transitions.create(['border-color']),
    '&:hover': {
      backgroundColor: INPUT_BG_COLOR,
    },
    '&.Mui-focused': {
      backgroundColor: INPUT_BG_COLOR,
      borderColor: INPUT_FOCUSED_COLOR,
    },
  };

  const labelStyles = {
    fontFamily: 'SpaceGrotesk-Regular',
    fontWeight: 500,
    color: LABEL_TEXT_COLOR,
    '&:hover': {
      color: LABEL_TEXT_COLOR,
    },
    '&.Mui-focused': {
      color: LABEL_TEXT_COLOR,
    },
  };

  const helperTextStyles = {
    fontFamily: 'SpaceGrotesk-Regular',
    backgroundColor: isError ? ERROR_COLOR : GUIDE_TEXT_BG_COLOR,
    marginLeft: '0px',
    marginTop: '0.4rem',
    paddingLeft: '0.5rem',
    paddingTop: isError ? '0.5rem' : '0rem',
    paddingBottom: '0.5rem',
    width: 'calc(100% - 0.5rem)',
  };

  return (
    <Stack direction={'column'}>
      <ThemeProvider theme={usedTheme}>
        <TextField
          inputRef={ref}
          type={inputType || 'text'}
          value={val}
          onChange={handleInputChange}
          label={label ? getText(label) : null}
          placeholder={placeholder ? getText(placeholder) : ''}
          variant='filled'
          error={isError}
          helperText={showErrors && errorMessage !== '' ? errorMessage : getGuideText()}
          margin='dense'
          disabled={disabled}
          multiline={multiline}
          minRows={multiline ? 5 : 1}
          InputProps={{
            startAdornment: getAdornment(),
            disableUnderline: true,
            inputProps: {
              min: minValue,
              maxLength: maxLength ?? null,
            },
            sx: inputStyles,
          }}
          InputLabelProps={{
            sx: labelStyles,
          }}
          FormHelperTextProps={{
            sx: helperTextStyles,
          }}
        />
      </ThemeProvider>
    </Stack>
  );
};

export default TextInput;
