import { useContext, useEffect, useState } from 'react';
import { Stack, Container, Box, Button, Divider, Typography, Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { LanguageContext } from '../../../../../contexts/language-context';
import SepticTank, { defaultSepticTankValues, defaultSepticTankValuesOptionB } from './SepticTank';
import TreatmentPlant, { defaultTreatmentPlantValues } from './TreatmentPlant';
import ClosedTank, { defaultClosedTankValues } from './ClosedTank';
import Popover from '../../../../../components/common-materialui/popover/Popover';
import WasteWaterTreatmentOptions, { defaultPropertyWaterSupplyFormValues } from './WasteWaterTreatmentOptions';
import FormErrorField from '../../../../../components/common-materialui/form/FormErrorField';
import Other, { defaultOtherValues } from './Other';
import {
  OtherForm,
  OtherRadioGroupOptionsEnum,
  ClosedTankForm,
  SepticTankForm,
  TreatmentPlantForm,
  WasteWaterOptionsEnum,
  WellCollectionState,
  WellCollectionWCType,
  WasteWaterTreatmentOptionsForm,
} from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ReactComponent as Help } from '../../../../../assets/help-circle.svg';

interface Props {
  onNextPhaseChange: () => void;
  onPreviousPhaseChange: () => void;
  wellCollectionInfo: WellCollectionState;
  updateWasteWaterInfo: (values: string[]) => void;
  updateSepticTankInfo: (values: Partial<SepticTankForm>) => void;
  updateSepticTankOptionBInfo: (values: Partial<SepticTankForm>) => void;
  updateOtherInfo: (values: Partial<OtherForm>) => void;
  updateTreatmentPlantInfo: (values: Partial<TreatmentPlantForm>) => void;
  updateClosedTankInfo: (values: Partial<ClosedTankForm>) => void;
  resetSepticTankInfo: () => void;
  resetSepticTankOptionBInfo: () => void;
  resetTreatmentPlantInfo: () => void;
  resetClosedTankInfo: () => void;
  resetOtherInfo: () => void;
}

const WasteWaterTreatment = ({
  onNextPhaseChange,
  onPreviousPhaseChange,
  wellCollectionInfo,
  updateWasteWaterInfo,
  updateSepticTankInfo,
  updateSepticTankOptionBInfo,
  updateTreatmentPlantInfo,
  updateOtherInfo,
  updateClosedTankInfo,
  resetSepticTankInfo,
  resetSepticTankOptionBInfo,
  resetTreatmentPlantInfo,
  resetClosedTankInfo,
  resetOtherInfo,
}: Props): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { getText } = useContext(LanguageContext);
  const maxNumberOfSelection = wellCollectionInfo.houseHoldWaterInfo.wcType === WellCollectionWCType.WaterWC ? 2 : 1;
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
  } = useForm<WasteWaterTreatmentOptionsForm>({ defaultValues: defaultPropertyWaterSupplyFormValues });

  const {
    control: septicTankControl,
    formState: { errors: septicTankErrors },
    handleSubmit: handleSepticTankSubmit,
    setValue: setSepticTankValue,
    getValues: getSepticTankValues,
    reset: resetSepticTankForm,
  } = useForm<SepticTankForm>({ defaultValues: defaultSepticTankValues });

  const {
    control: septicTankOptionBControl,
    formState: { errors: septicTankOptionBErrors },
    handleSubmit: handleSepticOptionBTankSubmit,
    setValue: setSepticTankOptionBValue,
    getValues: getSepticTankOptionBValues,
    reset: resetSepticTankOptionBForm,
  } = useForm<SepticTankForm>({ defaultValues: defaultSepticTankValuesOptionB });

  const {
    control: treatmentPlantControl,
    formState: { errors: treatmentPlantErrors },
    handleSubmit: handleTreatmentPlantSubmit,
    setValue: setTreatmentPlantValue,
    getValues: getTreatmentPlantValue,
    reset: resetTreatmentPlantForm,
  } = useForm<TreatmentPlantForm>({ defaultValues: defaultTreatmentPlantValues });

  const {
    control: closedTankControl,
    formState: { errors: closedTankErrors },
    handleSubmit: handleClosedTankSubmit,
    setValue: setClosedTankValue,
    getValues: getClosedTankValue,
    reset: resetClosedTankForm,
  } = useForm<ClosedTankForm>({ defaultValues: defaultClosedTankValues });

  const {
    control: otherControl,
    formState: { errors: otherErrors },
    handleSubmit: handleOtherSubmit,
    setValue: setOtherValue,
    getValues: getOtherValues,
    reset: resetOtherForm,
  } = useForm<OtherForm>({ defaultValues: defaultOtherValues });

  const optionA = useWatch({
    control,
    name: 'A',
  });

  useEffect(() => {
    if (!optionA) {
      resetSepticTankForm();
    }
  }, [optionA, resetSepticTankForm]);

  const optionB = useWatch({
    control,
    name: 'B',
  });

  useEffect(() => {
    if (!optionB) {
      resetSepticTankOptionBForm();
    }
  }, [optionB, resetSepticTankOptionBForm]);

  const optionC = useWatch({
    control,
    name: 'C',
  });

  useEffect(() => {
    if (!optionC) {
      resetTreatmentPlantForm();
    }
  }, [optionC, resetTreatmentPlantForm]);

  const optionD = useWatch({
    control,
    name: 'D',
  });

  useEffect(() => {
    if (!optionD) {
      resetTreatmentPlantForm();
    }
  }, [optionD, resetTreatmentPlantForm]);

  const optionE = useWatch({
    control,
    name: 'E',
  });

  useEffect(() => {
    if (!optionE) {
      resetClosedTankForm();
    }
  }, [optionE, resetClosedTankForm]);

  const optionF = useWatch({
    control,
    name: 'F',
  });

  useEffect(() => {
    if (!optionF) {
      resetClosedTankForm();
    }
  }, [optionF, resetClosedTankForm]);

  const optionG = useWatch({
    control,
    name: 'G',
  });

  useEffect(() => {
    if (!optionG) {
      resetOtherForm();
    }
  }, [optionG, resetOtherForm]);

  const handleOtherPhaseChange = () => {
    const otherValues = getOtherValues();
    updateOtherInfo(otherValues);

    return new Promise((resolve, reject) => {
      handleOtherSubmit((data) => {
        resolve(data);
      })();
    });
  };

  const handleSepticTankOptionAPhaseChange = () => {
    const septicTankValues = getSepticTankValues();
    updateSepticTankInfo(septicTankValues);

    return new Promise((resolve, reject) => {
      handleSepticTankSubmit((data) => {
        resolve(data);
      })();
    });
  };

  const handleSepticTankOptionBPhaseChange = () => {
    const septicTankValues = getSepticTankOptionBValues();
    updateSepticTankOptionBInfo(septicTankValues);

    return new Promise((resolve, reject) => {
      handleSepticOptionBTankSubmit((data) => {
        resolve(data);
      })();
    });
  };

  const handleClosedTankPhaseChange = () => {
    const closedTankValues = getClosedTankValue();
    updateClosedTankInfo(closedTankValues);

    return new Promise((resolve, reject) => {
      handleClosedTankSubmit((data) => {
        resolve(data);
      })();
    });
  };

  const handleTreatmentPlantPhaseChange = () => {
    const treatmentPlantValues = getTreatmentPlantValue();
    updateTreatmentPlantInfo(treatmentPlantValues);

    return new Promise((resolve, reject) => {
      handleTreatmentPlantSubmit((data) => {
        resolve(data);
      })();
    });
  };

  const checkForValidSelection = (options: WasteWaterTreatmentOptionsForm) => {
    const checkedOptions = Object.values(options).filter((option) => option);
    if (checkedOptions.length !== maxNumberOfSelection && !(optionB || optionD || optionE)) {
      if (wellCollectionInfo.houseHoldWaterInfo.wcType !== WellCollectionWCType.DryCompostFreezingOrBurning) {
        setErrorMessage('waste-water-treatment-validation-message-wc-water');
        return;
      } else {
        setErrorMessage('waste-water-treatment-validation-message-wc-water-gray');
        return;
      }
    }
  };

  const getOptionsToBeValidated = () => {
    const optionsToBeValidated = [];

    if (optionA) {
      optionsToBeValidated.push(handleSepticTankOptionAPhaseChange);
    } else {
      resetSepticTankInfo();
      resetSepticTankForm();
    }

    if (optionB) {
      optionsToBeValidated.push(handleSepticTankOptionBPhaseChange);
    } else {
      resetSepticTankOptionBInfo();
      resetSepticTankOptionBForm();
    }

    if (optionC || optionD) {
      optionsToBeValidated.push(handleTreatmentPlantPhaseChange);
    } else {
      resetTreatmentPlantInfo();
      resetTreatmentPlantForm();
    }

    if (optionE || optionF) {
      optionsToBeValidated.push(handleClosedTankPhaseChange);
    } else {
      resetClosedTankInfo();
      resetClosedTankForm();
    }

    if (optionG) {
      optionsToBeValidated.push(handleOtherPhaseChange);
    } else {
      resetOtherInfo();
      resetOtherForm();
    }

    return optionsToBeValidated;
  };

  const handleNext = async () => {
    const values = getValues();

    checkForValidSelection(values);

    updateWasteWaterInfo(Object.keys(values).filter((key) => values[key as WasteWaterOptionsEnum]));
    const optionsToBeValidated = getOptionsToBeValidated();

    await Promise.all(optionsToBeValidated.map((validateFunction) => validateFunction()))
      .then(() => onNextPhaseChange())
      .catch(() => {});
  };

  useEffect(() => {
    //update checkboxes based on reducer state
    const { wasteWater, septicTank, septicTankOptionB, other, treatmentPlant, closedTank } = wellCollectionInfo;

    if (wasteWater?.length) {
      wasteWater?.forEach((option) => setValue(option as WasteWaterOptionsEnum, true as never, { shouldDirty: true }));
    }

    if (septicTank) {
      Object.entries(septicTank).forEach(([key, value]) => {
        setSepticTankValue(key as keyof SepticTankForm, value as string | number | boolean);
      });
    }

    if (septicTankOptionB) {
      Object.entries(septicTankOptionB).forEach(([key, value]) => {
        setSepticTankOptionBValue(key as keyof SepticTankForm, value as string | number | boolean);
      });
    }

    if (other) {
      Object.entries(other).forEach(([key, value]) => {
        setOtherValue(key as keyof OtherForm, value as OtherRadioGroupOptionsEnum | string);
      });
    }

    if (treatmentPlant) {
      Object.entries(treatmentPlant).forEach(([key, value]) => {
        setTreatmentPlantValue(key as keyof TreatmentPlantForm, value as string | number | boolean);
      });
    }
    if (closedTank) {
      Object.entries(closedTank).forEach(([key, value]) => {
        setClosedTankValue(key as keyof ClosedTankForm, value as string | number | boolean);
      });
    }
  }, [
    wellCollectionInfo,
    setValue,
    setSepticTankValue,
    setSepticTankOptionBValue,
    setTreatmentPlantValue,
    setOtherValue,
    setClosedTankValue,
  ]);

  return (
    <Stack direction='column'>
      <Container>
        <Grid container direction={direction} wrap='nowrap' marginTop={2}>
          <Grid item xs={12} sm={7} md={8}>
            <Stack>
              <Typography variant='h5' sx={{ paddingTop: '10px' }}>
                {getText('waste-water-treatment-header')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Typography variant='h6' sx={{ fontWeight: '450' }}>
                {getText('waste-water-treatment-options-header')}
              </Typography>
              <Popover content={getText('waste-water-popover-content')} popoverButton={<Help />} />
            </Stack>
            <WasteWaterTreatmentOptions
              wellCollectionInfo={wellCollectionInfo}
              control={control}
              errors={errors}
              dirtyFields={dirtyFields}
              maxNumberOfSelection={maxNumberOfSelection}
              setErrorMessage={setErrorMessage}
            />
            <Stack>{errorMessage && <FormErrorField errorMsg={errorMessage} />}</Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      {optionA && (
        <SepticTank
          control={septicTankControl}
          errors={septicTankErrors}
          setSepticTankValue={setSepticTankOptionBValue}
          formHeader={'waste-water-treatment-option-A'}
          grayWater={optionA}
          blackWater={false}
        />
      )}
      {optionB && (
        <SepticTank
          control={septicTankOptionBControl}
          errors={septicTankOptionBErrors}
          setSepticTankValue={setSepticTankValue}
          formHeader={'waste-water-treatment-option-B'}
          grayWater={false}
          blackWater={optionB}
        />
      )}
      {(optionC || optionD) && (
        <TreatmentPlant
          control={treatmentPlantControl}
          errors={treatmentPlantErrors}
          setTreatmentPlantValue={setTreatmentPlantValue}
          formHeader={optionC && !optionD ? 'waste-water-treatment-option-C' : 'waste-water-treatment-option-D'}
          grayWater={optionC}
          blackWater={optionD}
        />
      )}
      {(optionE || optionF) && (
        <ClosedTank
          control={closedTankControl}
          setClosedTankValue={setClosedTankValue}
          errors={closedTankErrors}
          formHeader={optionE && !optionF ? 'waste-water-treatment-option-E' : 'waste-water-treatment-option-F'}
        />
      )}
      {optionG && (
        <Other control={otherControl} errors={otherErrors} wcType={wellCollectionInfo.houseHoldWaterInfo.wcType} />
      )}
      <Divider />
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='primary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Box>
            <Button variant='contained' size='large' onClick={handleNext}>
              {getText('dialog-next')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default WasteWaterTreatment;
