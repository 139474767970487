import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  FormLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Skeleton,
  Box,
} from '@mui/material';
import CustomerServiceFeedback from './CustomerServiceFeedback';
import { LanguageContext } from '../../contexts/language-context';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import { EmptyingInfo, FeedbackInput } from '../../model';
import Header from '../../components/common-materialui/header/Header';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import DropdownSelect from '../../components/common-materialui/DropdownSelect';
import FormErrorField from '../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../util/input/validation';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import ApiMessageDialog from '../../components/common-materialui/dialogs/ApiMessageDialog';

const CustomerService = () => {
  const [contactOption, setContactOption] = useState<'phone' | 'email' | 'noContact' | undefined>(undefined);
  const [selectedEmptyingLocation, setSelectedEmptyingLocation] = useState<EmptyingInfo | undefined>(undefined);
  const [messageCategoryName, setMessageCategoryName] = useState<string | undefined>(undefined);
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const { getText, lang } = useContext(LanguageContext);

  const api = useContext(ApiContext);
  const queryClient = useQueryClient();

  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'column' : 'row';
  const authService = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset: resetFormFields,
  } = useForm({
    defaultValues: {
      emptyingLocation: '',
      subject: '',
      message: '',
      contactOption: 'noContact',
      phoneNumber: selectedEmptyingLocation?.contactPerson.phoneNumber || '',
      emailAddress: selectedEmptyingLocation?.contactPerson.email || '',
    },
  });

  const {
    isLoading: emptyingInfoIsLoading,
    data: emptyingInfo,
    error: emptyingInfoError,
  } = useQuery<EmptyingInfo[] | null>(['emptying-info'], () => api.fetchEmptyingInfos());

  const {
    isLoading: messageCategoryIsLoading,
    data: messageCategory,
    error: messageCategoryError,
  } = useQuery(['message-categories'], () => api.fetchMessageCategories(authService.getTenantId(), lang));

  const { mutate: sendFeedback } = useMutation(
    (data: FeedbackInput) => {
      // Remove whitespaces from phone number
      const modifiedData = { ...data, phone: data.phone?.replace(/\s/g, '') };
      return api.sendFeedback(modifiedData);
    },
    {
      onSuccess: () => {
        setConfirmationDialog(true);
        resetFormFields();
        queryClient.invalidateQueries(['customer-service-feedback']);
      },
      onError: () => {},
    }
  );

  const isCurrentContactMethod = (value: string): boolean => {
    if (value === contactOption) {
      return true;
    }
    return false;
  };

  const renderHeaderContent = (): JSX.Element => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('customer-service-header')}
          <Typography variant='body1' mt={1}>
            {getText('customer-service-header-description')}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderSkeleton = (): JSX.Element => {
    return (
      <>
        <Box>
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        </Box>
        <Box marginTop={2}>
          <Skeleton variant='rectangular' height={56} width={'100%'}></Skeleton>
        </Box>
        <Box marginTop={2}>
          <Skeleton variant='rectangular' height={200} width={'100%'}></Skeleton>
        </Box>
      </>
    );
  };

  return (
    <>
      <ApiMessageDialog
        open={confirmationDialog}
        titleMessageKey='customer-service-confirmation-title'
        buttonLabelKey='dialog-ok'
        descriptionMessageKey='customer-service-confirmation-message'
        onClose={() => setConfirmationDialog(false)}
      />
      <Header content={renderHeaderContent()} />
      <ExceptionHandler error={emptyingInfoError || messageCategoryError}>
        <ContentLoadingHandler
          isLoading={emptyingInfoIsLoading || messageCategoryIsLoading}
          skeleton={renderSkeleton()}
        >
          <Grid
            container
            direction={largerThanPhone ? 'row' : 'column'}
            wrap='nowrap'
            style={{ backgroundColor: theme.palette.background.level2, marginRight: 4, marginLeft: -6 }}
          >
            <Grid item xs={6} sm={6} md={3} lg={4}>
              <Typography marginBottom={3} variant='h5' paddingLeft={2} paddingTop={2}>
                {getText('customer-service-contact-us-title')}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={9} lg={8} paddingRight={2}>
              <Stack paddingTop={2}>
                <Controller
                  control={control}
                  name='emptyingLocation'
                  rules={{
                    validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DropdownSelect
                        label={getText('customer-service-emptying-location-placeholder')}
                        items={
                          emptyingInfo?.map((location) => ({
                            value: location.id,
                            text: `${location.name}, ${location.address.street}, ${location.address.postalCode} ${location.address.postOffice}`,
                          })) || []
                        }
                        value={value}
                        onChange={(event) => {
                          onChange(event);

                          const currentEmptyingLocation = emptyingInfo?.find(
                            (location) => location.id === event.target.value
                          );
                          setSelectedEmptyingLocation(currentEmptyingLocation);
                        }}
                      />
                    );
                  }}
                />
                <FormErrorField errorMsg={formErrors.emptyingLocation?.message} />
              </Stack>

              <Stack paddingTop={1}>
                <Controller
                  control={control}
                  name='subject'
                  rules={{
                    validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DropdownSelect
                        label={getText('customer-service-subject-placeholder')}
                        items={messageCategory?.map((message) => ({ value: message.id, text: message.name })) || []}
                        value={value}
                        onChange={(event) => {
                          onChange(event);

                          const currentMessageCategoryName = messageCategory?.find(
                            (message) => message.id === event.target.value
                          );
                          setMessageCategoryName(currentMessageCategoryName?.name);
                        }}
                      />
                    );
                  }}
                />
                <FormErrorField errorMsg={formErrors.subject?.message} />
              </Stack>

              <Stack paddingTop={1}>
                <ControlTextInput
                  control={control}
                  name='message'
                  label='customer-service-message-placeholder'
                  error={formErrors.message}
                  validations={['required']}
                />
              </Stack>

              <Stack marginTop={4}>
                <Controller
                  control={control}
                  name='contactOption'
                  render={({ field: { onChange } }) => {
                    return (
                      <>
                        <FormControl>
                          <FormLabel style={{ marginBottom: 4 }}>
                            {getText('customer-service-contact-option-placeholder')}
                          </FormLabel>
                          <RadioGroup
                            defaultValue='noContact'
                            onChange={(event) => {
                              onChange(event);
                              setContactOption(event.target.value as never);
                            }}
                          >
                            <FormControlLabel
                              sx={{ marginRight: 5 }}
                              value='phone'
                              control={<Radio />}
                              label={getText('customer-service-contact-option-phone-value')}
                            />

                            {contactOption === 'phone' && (
                              <Stack marginLeft={4}>
                                <ControlTextInput
                                  control={control}
                                  name='phoneNumber'
                                  label='customer-service-contact-option-phone-placeholder'
                                  error={formErrors.phoneNumber}
                                  maxLength={15}
                                  validations={['required', 'phone']}
                                />
                              </Stack>
                            )}
                            <FormControlLabel
                              sx={{ marginRight: 5 }}
                              value='email'
                              control={<Radio />}
                              label={getText('customer-service-contact-option-email-value')}
                            />
                            {contactOption === 'email' && (
                              <Stack marginLeft={4}>
                                <ControlTextInput
                                  control={control}
                                  name='emailAddress'
                                  label='customer-service-contact-option-email-placeholder'
                                  error={formErrors.emailAddress}
                                  validations={['required', 'email']}
                                />
                              </Stack>
                            )}
                            <FormControlLabel
                              value='noContact'
                              control={<Radio />}
                              label={getText('customer-service-contact-options-no-contact-value')}
                            />
                          </RadioGroup>
                        </FormControl>
                      </>
                    );
                  }}
                />
              </Stack>

              <Stack maxWidth={150} marginY={3}>
                <Button
                  variant='contained'
                  size='large'
                  onClick={handleSubmit((data) => {
                    const feedbackInput = {
                      customerNumber: data.emptyingLocation,
                      header: messageCategoryName || '',
                      message: data.message,
                      email: isCurrentContactMethod('email') ? data.emailAddress : undefined,
                      phone: isCurrentContactMethod('phone') ? data.phoneNumber : undefined,
                      feedbackCategoryId: data.subject,
                      messageCategory: messageCategoryName || '',
                      name: selectedEmptyingLocation?.name || '',
                      address: selectedEmptyingLocation?.address || { street: '', postOffice: '', postalCode: '' },
                    };

                    sendFeedback(feedbackInput);
                  })}
                >
                  {getText('customer-service-send-message')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ContentLoadingHandler>
      </ExceptionHandler>
      <CustomerServiceFeedback emptyingLocations={emptyingInfo} />
    </>
  );
};

export default CustomerService;
