import { format, getISOWeek, setISOWeek, startOfWeek } from 'date-fns';
import { EmptyingInterval } from '../model';

/**
 * Returns tomorrow
 */
export function tomorrow(): Date {
  const today = new Date();
  return new Date(today.setDate(today.getDate() + 1));
}

export function dateInFuture(days: number): Date {
  const today = new Date();
  return new Date(today.setDate(today.getDate() + days));
}

export function doWeekIntervalsOverlap(start1: number, end1: number, start2: number, end2: number): boolean {
  const weeksOfInterval1 = getWeeksOfInterval(start1, end1);
  const weeksOfInterval2 = getWeeksOfInterval(start2, end2);
  const overlaps = weeksOfInterval1.filter((x) => weeksOfInterval2.includes(x)).length > 0;
  return overlaps;
}

export function getWeeksOfInterval(start: number, end: number): number[] {
  let weeks = [];
  if (end > start) {
    for (let i = start; i <= end; i++) {
      weeks.push(i);
    }
  } else {
    for (let i = start; i <= 53; i++) {
      weeks.push(i);
    }
    for (let i = 1; i <= end; i++) {
      weeks.push(i);
    }
  }
  return weeks;
}

/**
 * Finds the valid emptying interval for a given date from given intervals.
 *
 * @param {number} intervals All emptying intervals.
 * @param {number} date Date that specifies the returned interval.
 * @return {EmptyingInterval | undefined} Emptying interval or undefined if there's no valid interval for given date.
 */
export function getEmptyingIntervalForDate(intervals: EmptyingInterval[], date: Date): EmptyingInterval | undefined {
  const week = getWeekNumber(date);
  const currentInterval = intervals.find((i) => isCurrentInterval(week, i));
  return currentInterval;
}

function isCurrentInterval(week: number, interval: EmptyingInterval): boolean {
  if (interval.startWeek > interval.endWeek) {
    return !(week > interval.endWeek && week < interval.startWeek);
  } else {
    return week >= interval.startWeek && week <= interval.endWeek;
  }
}

/**
 * Prints interval as date range from start date of start week to end date of end week in format:
 * d.m.yyyy - d.m.yyyy
 *
 * @param {number} year Current year.
 * @param {number} startWeek Week number of interval start week.
 * @param {number} endWeek Week number of interval end week.
 * @return {string} Interval start date - end date.
 */
export function printIntervalStartAndEndDates(year: number, startWeek: number, endWeek: number): string {
  if (startWeek === 1 && endWeek === 53) {
    return '';
  }

  const startYear = year;
  let endYear = startYear;
  if (endWeek < startWeek) {
    endYear++;
  }
  const startWeekFirstDate = getFirstDateOfWeek(startYear, startWeek);
  const endWeekFirstDate = getFirstDateOfWeek(endYear, endWeek);
  const endWeekLastDate = new Date(endWeekFirstDate);
  endWeekLastDate.setDate(endWeekLastDate.getDate() + 6);

  const startStr = printDate(startWeekFirstDate);
  const endStr = printDate(endWeekLastDate);

  return `${startStr} - ${endStr}`;
}

export function getWeekNumber(date: Date): number {
  return getISOWeek(date);
}

export function getStartOfWeek(date: Date): Date {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export function printDate(date: Date): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'd.M.yyyy');
}

export function printddMMDate(date: Date | null): string {
  if (date === null) {
    return '-';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'dd.MM.yyyy');
}

export function printComparableDate(date: Date): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'yyyy-MM-dd');
}

export function printDateHoursMinutes(date: Date | undefined): string | undefined {
  if (!date || typeof date === 'string') {
    return '-';
  }

  return date.toLocaleTimeString('fi-FI', { hour12: false, hour: '2-digit', minute: '2-digit' });
}

export function getFirstDateOfWeek(year: number, week: number): Date {
  const dateInWeek = setISOWeek(new Date(year, 1, 2), week);
  return getStartOfWeek(dateInWeek);
}

export function lastWeekOfTheYear(year?: number | null): number {
  year = year ?? new Date().getFullYear();
  const lastDate = new Date(year, 11, 31); // the month is 0-indexed
  const week = getISOWeek(lastDate);
  if (week == 53) {
    return week;
  }
  return 52;
}
