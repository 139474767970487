import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditContract from './edit-contract/EditContract';
import { LanguageContext } from '../../../../contexts/language-context';
import { Button, Skeleton, Stack, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../../../components/common-materialui/header/Header';
import ExceptionHandler from '../../../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../../../components/common-materialui/status-handling/ContentLoadingHandler';
import BreadcrumbBarContext from '../../../../contexts/BreadcrumbBarContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ContractInfo,
  CustomerEServiceSettings,
  EmptyingInfo,
  PriceCalculationRequest,
  PriceCalculationResult,
} from '../../../../model';
import ApiContext from '../../../../contexts/api-context';
import { printAddress } from '../emptyingLocationUtils';
import ContractServiceInformationSection from './ContractServiceInformationSection';
import ContractPriceSection from './ContractPriceSection';
import ContractCalendar from './ContractCalendar';
import AuthContext from '../../../../contexts/auth-context';
import OverlayDialog from '../../../../components/common-materialui/dialogs/OverlayDialog';
import OrderAdditionalCollection from './order-additional-collection/OrderAdditionalCollection';
import { isStringNumeric } from '../../../../util/util';

interface ContractParams {
  id: string;
  contractPathInfo: string;
}

const Contract = (): JSX.Element => {
  const { id: emptyingId, contractPathInfo } = useParams<ContractParams>();
  const [customerNumber, contractPosition] = contractPathInfo.split('_');
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const { fetchContractDetails, fetchEmptyingInfo, fetchEServiceCustomerSettings, calculatePrice } =
    useContext(ApiContext);

  const theme = useTheme();
  const { palette } = useTheme();

  const { addCustomPathName } = useContext(BreadcrumbBarContext);

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const [showOrderAdditionalCollection, setShowOrderAdditionalCollection] = useState<boolean>(false);
  const [showEditContract, setShowEditContract] = useState<boolean>(false);
  const [priceResponseData, setPriceResponseData] = useState<PriceCalculationResult>();
  const eServiceCustomerSettingKeys: string[] = ['ExtraEmptyingMinDays'];
  const fallbackExtraEmptyingMinDays: number = 1;

  const handleCloseOrderAdditionalColletionDialog = useCallback(() => {
    setShowOrderAdditionalCollection(false);
  }, []);

  const handleCloseEditContract = useCallback(() => {
    setShowEditContract(false);
  }, []);

  const {
    isLoading: emptyingInfoIsLoading,
    data: emptyingInfo,
    error: emptyingInfoError,
  } = useQuery<EmptyingInfo | null>(['emptying-info-contracts', emptyingId], () => fetchEmptyingInfo(emptyingId));

  const {
    isLoading: contractDetailsIsLoading,
    data: contractDetails,
    error: contractDetailsError,
  } = useQuery<ContractInfo | null>(['contract-details', contractPathInfo], () =>
    fetchContractDetails(customerNumber, Number(contractPosition))
  );

  const {
    isLoading: customerEServiceSettingIsLoading,
    data: customerEServiceSettings,
    error: customerEServiceSettingsError,
  } = useQuery<CustomerEServiceSettings>(['settings', eServiceCustomerSettingKeys], () =>
    fetchEServiceCustomerSettings(eServiceCustomerSettingKeys)
  );

  const { mutate: calculatePriceMutation, isLoading: calculatePriceMutationIsLoading } = useMutation(
    (newPriceCalculationRequest: PriceCalculationRequest) => calculatePrice(newPriceCalculationRequest)
  );

  const locationInfo = `${emptyingInfo?.name} ${emptyingInfo?.address.street}, ${emptyingInfo?.address.postalCode} ${emptyingInfo?.address.postOffice}`;

  const extractAllEmptyingEvents = () => {
    return contractDetails?.allEmptyings
      ? [
          ...contractDetails.allEmptyings.Successful,
          ...contractDetails.allEmptyings.Missed,
          ...contractDetails.allEmptyings.Planned,
        ]
      : [];
  };

  // If navigate to page through url/refresh, re-add the previous breadcrumb
  useEffect(() => {
    addCustomPathName({
      [`${emptyingId}`]: {
        name: printAddress(emptyingInfo?.address) ?? '',
        pathTo: '/emptying-infos/' + emptyingId,
      },
    });
  }, [emptyingInfo]);

  // Current page breadcrumb addition
  useEffect(() => {
    addCustomPathName({
      [`${contractPathInfo}`]: {
        name: contractDetails?.name ?? '',
        pathTo: 'emptying-infos/:' + emptyingId + '/contracts/' + contractPathInfo,
      },
    });
  }, [contractDetails]);

  useEffect(() => {
    if ((contractDetails?.amount ?? 0) > 0) {
      const newPriceCalculationRequest: PriceCalculationRequest = {
        productGuid: contractDetails?.productGuid,
        customerId: emptyingId,
        amount: Number(contractDetails?.amount),
        weight: 1,
      };

      calculatePriceMutation(newPriceCalculationRequest, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (data) => {
          setPriceResponseData(data);
        },
      });
    }
  }, [calculatePriceMutation, contractDetails, emptyingId]);

  const pageContentSkeleton = largerThanPhone ? (
    <>
      <Skeleton variant='rectangular' height={200} width={'100%'}></Skeleton>
      <Skeleton variant='rectangular' sx={{ marginTop: 2 }} height={400} width={'100%'}></Skeleton>
      <Skeleton variant='rectangular' sx={{ marginTop: 2 }} height={200} width={'100%'}></Skeleton>
    </>
  ) : (
    <>
      <Skeleton variant='rectangular' height={300} width={'100%'}></Skeleton>
      <Skeleton variant='rectangular' sx={{ marginTop: 2 }} height={300} width={'100%'}></Skeleton>
    </>
  );

  function isAllowedExtraEmptying() {
    if (contractDetails?.readonlyContract) {
      return false;
    }
    return (
      authService.isExtraEmptyingEnabled() &&
      (!contractDetails?.sharedContainer || contractDetails?.correspondingPersonId === emptyingId)
    );
  }

  function isAllowedToModify() {
    if (contractDetails?.readonlyContract) {
      return false;
    }
    return (
      authService.isModifyServiceEnabled() &&
      (!contractDetails?.sharedContainer || contractDetails?.correspondingPersonId === emptyingId)
    );
  }

  const headerContent = () => {
    if (
      contractDetailsIsLoading ||
      emptyingInfoIsLoading ||
      customerEServiceSettingIsLoading ||
      calculatePriceMutationIsLoading
    ) {
      return <Skeleton variant='rectangular' height={80} width={'100%'}></Skeleton>;
    }
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {contractDetails?.name ?? ''}
          <Typography variant='body1' mt={1}>
            {emptyingInfo?.name + ' • ' + printAddress(emptyingInfo?.address)}
          </Typography>
        </Typography>

        <Stack spacing={3} direction={headerStackDirection}>
          <Typography color={palette.text.links}>
            {isAllowedExtraEmptying() && (
              <Button sx={{ p: 0, color: palette.text.links }} onClick={() => setShowOrderAdditionalCollection(true)}>
                {getText('extra-emptying-order')}
              </Button>
            )}
          </Typography>
          <Typography color={palette.text.links}>
            {isAllowedToModify() && (
              <Button
                sx={{ p: 0, color: palette.text.links, minWidth: 'auto' }}
                onClick={() => setShowEditContract(true)}
              >
                {getText('contract-details-edit')}
              </Button>
            )}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const renderAdditionalCollectionDialog = () => (
    <OverlayDialog
      headerTextKey={'order-additional-collection-dialog-header'}
      open={showOrderAdditionalCollection}
      onClose={handleCloseOrderAdditionalColletionDialog}
    >
      <OrderAdditionalCollection
        customerNumber={customerNumber}
        position={contractPosition}
        amountOfBins={contractDetails?.amount}
        extraEmptyingMinDays={
          customerEServiceSettings && isStringNumeric(customerEServiceSettings['ExtraEmptyingMinDays'])
            ? parseInt(customerEServiceSettings['ExtraEmptyingMinDays'])
            : fallbackExtraEmptyingMinDays
        }
        onClose={handleCloseOrderAdditionalColletionDialog}
      />
    </OverlayDialog>
  );

  const renderEditContractDialog = () => (
    <OverlayDialog
      headerTextKey={'contract-details-change-contract'}
      open={showEditContract}
      onClose={handleCloseEditContract}
      secondaryHeader={
        <Box>
          <Typography variant='body2' color={palette.text.primary}>
            {locationInfo}
          </Typography>
          <Typography variant='body2' color={palette.text.primary}>
            {contractDetails?.name}
          </Typography>
        </Box>
      }
    >
      <EditContract emptyingInfo={emptyingInfo} contractDetails={contractDetails} onClose={handleCloseEditContract} />
    </OverlayDialog>
  );

  return (
    <>
      {renderAdditionalCollectionDialog()}
      {renderEditContractDialog()}
      <Header content={headerContent()} />
      <ExceptionHandler error={contractDetailsError || emptyingInfoError || customerEServiceSettingsError}>
        <ContentLoadingHandler
          isLoading={
            contractDetailsIsLoading ||
            emptyingInfoIsLoading ||
            customerEServiceSettingIsLoading ||
            calculatePriceMutationIsLoading
          }
          skeleton={pageContentSkeleton}
        >
          <Stack spacing={2}>
            <ContractServiceInformationSection contractDetails={contractDetails} />
            <ContractCalendar
              events={extractAllEmptyingEvents()}
              nextEmptying={(contractDetails?.nextEmptying && new Date(contractDetails?.nextEmptying)) || undefined}
            />
            <ContractPriceSection
              priceData={priceResponseData}
              name={contractDetails?.name}
              currentNumberOfContainers={contractDetails?.amount}
            />
          </Stack>
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default Contract;
