import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  Box,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import ApiContext from '../../contexts/api-context';
import { LanguageContext } from '../../contexts/language-context';
import Header from '../../components/common-materialui/header/Header';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import { DataTable } from '../../components/common-materialui/data-table/DataTable';
import { TableHeaderProps } from '../../components/common-materialui/data-table/interfaces';
import { printddMMDate } from '../../util/calendarUtil';
import { EmptyingInfo, UiTexts } from '../../model';
import OverlayDialog from '../../components/common-materialui/dialogs/OverlayDialog';
import NewCompostingNotification from './NewCompostingNotification';
import { compareDesc } from 'date-fns';

const CompostingNotifications = (): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  const api = useContext(ApiContext);

  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'column' : 'row';

  const { data: emptyingIds } = useQuery<EmptyingInfo[]>(['emptyingInfos'], () => api.fetchEmptyingInfos());

  const emptyingIdsMapped: string[] = emptyingIds?.map((element: EmptyingInfo): string => element.id) || [];

  const [showNewCompostingNotificationDialog, setShowNewCompostingNotificationDialog] = useState<boolean>(false);

  const handleCloseNewCompostingNotificationDialog = useCallback(() => {
    setShowNewCompostingNotificationDialog(false);
  }, []);

  const newCompostingNotificationButton = () => {
    return (
      <Button onClick={() => setShowNewCompostingNotificationDialog(true)} sx={{ p: 0, color: palette.text.links }}>
        {getText('new-composting-notification')}
      </Button>
    );
  };

  const {
    isLoading: compostNotificationIsLoading,
    data: compostNotificationData,
    error,
    refetch,
  } = useQuery<any[]>(['compost-notification', emptyingIds], () => api.getCompostingNotifications(emptyingIdsMapped), {
    enabled: !!emptyingIds,
  });

  useEffect(() => {
    if (!showNewCompostingNotificationDialog) {
      refetch();
    }
  }, [refetch, showNewCompostingNotificationDialog]);

  const getCompostingNotificationEntities = () => {
    return emptyingIds
      ?.map((emptyingInfo: EmptyingInfo) => {
        const matchingNotification = compostNotificationData?.find(
          (compostNotification) => emptyingInfo.id === compostNotification?.emptyingId
        );

        if (matchingNotification) {
          return emptyingInfo;
        }
      })
      .filter(Boolean);
  };

  const entities = getCompostingNotificationEntities();

  const getCompostNotificationLocation = (compostNotificationId: string): string => {
    const notificationEntity = entities?.find((entity) => entity?.id === compostNotificationId);

    if (notificationEntity) {
      return `${notificationEntity.address.street}, ${notificationEntity.address.postOffice}`;
    }

    return '';
  };

  const getCompostNotificationNotifier = (compostNotificationId: string): string => {
    const notificationEntity = entities?.find((entity) => entity?.id === compostNotificationId);

    if (notificationEntity) {
      return `${notificationEntity.name}`;
    }
    return '';
  };

  const getCompostNotificationIn5YearsDate = (compostDate: Date): Date => {
    const mutableDate = new Date(compostDate);
    mutableDate.setFullYear(mutableDate.getFullYear() + 5);
    return mutableDate;
  };

  const subtractOneMonth = (dateParam: Date): Date => {
    const date = new Date(dateParam);
    const originalDay = date.getDate();

    date.setMonth(date.getMonth() - 1);

    while (date.getDate() !== originalDay) {
      date.setDate(date.getDate() - 1);
    }

    return date;
  };

  const getCompostNotificationStatus = (compostDate: Date): keyof UiTexts => {
    const today = new Date();
    const dateIn5Years = getCompostNotificationIn5YearsDate(compostDate);
    const dateIn5YearsMinusOneMonth = subtractOneMonth(dateIn5Years);

    if (today < dateIn5Years && today >= dateIn5YearsMinusOneMonth) {
      return 'composting-notifications-status-expiring-soon';
    } else if (today > dateIn5Years) {
      return 'composting-notifications-status-expired';
    } else return 'composting-notifications-status-ready';
  };

  const renderSkeleton = () => {
    return (
      <>
        <Skeleton variant='rectangular' height={48} width={'100%'}></Skeleton>
        <Skeleton variant='rectangular' height={48} width={'100%'} sx={{ marginTop: '1rem' }}></Skeleton>
        <Skeleton variant='rectangular' height={48} width={'100%'} sx={{ marginTop: '1rem' }}></Skeleton>
        <Skeleton variant='rectangular' height={48} width={'100%'} sx={{ marginTop: '1rem' }}></Skeleton>
        <Skeleton variant='rectangular' height={48} width={'100%'} sx={{ marginTop: '1rem' }}></Skeleton>
      </>
    );
  };

  const renderHeaderContent = (): JSX.Element => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('composting-notifications-header')}

          <Typography variant='body1' mt={1}>
            {getText('composting-notifications-header-description')}
          </Typography>

          <Box width={'16rem'}>
            <Typography variant='body1' mt={3}>
              {newCompostingNotificationButton()}
            </Typography>
          </Box>
        </Typography>
      </Stack>
    );
  };

  const invoiceTableHeaders = (getText: (key: keyof UiTexts, params?: string[] | undefined) => string) => {
    const headers: TableHeaderProps[] = [
      {
        id: 'location',
        label: getText('composting-notifications-location-column'),
        alignment: 'left',
      },
      {
        id: 'notificationSent',
        label: getText('composting-notifications-notification-sent-column'),
        alignment: 'left',
      },
      {
        id: 'notifier',
        label: getText('composting-notifications-notifier-column'),
        alignment: 'left',
      },
      {
        id: 'notificationToBeRemade',
        label: getText('composting-notifications-notification-to-be-remade-column'),
        alignment: 'left',
      },
      {
        id: 'status',
        label: getText('composting-notifications-status-column'),
        alignment: 'left',
      },
    ];

    return headers;
  };

  const successStatusStyle = {
    backgroundColor: palette.success.background,
    color: palette.success.main,
    borderRadius: '20px',
  };

  const errorStatusStyle = {
    backgroundColor: palette.error.background,
    color: palette.error.main,
    borderRadius: '20px',
  };

  const getTableStatusStyles = (status: string) => {
    if (status === 'composting-notifications-status-ready') {
      return {
        ...successStatusStyle,
        width: '5rem',
      };
    } else if (status === 'composting-notifications-status-expired') {
      return {
        ...errorStatusStyle,
        width: '6rem',
      };
    } else {
      return {
        ...errorStatusStyle,
        width: '8rem',
      };
    }
  };

  const getCardsStatusStyles = (status: string) => {
    if (status === 'composting-notifications-status-ready') {
      return {
        ...successStatusStyle,
        paddingX: '0.8rem',
      };
    } else {
      return {
        ...errorStatusStyle,
        paddingX: '0.8rem',
      };
    }
  };

  const renderRows = () => {
    const cellStyle = {
      borderBottom: `1px solid ${palette.divider}`,
    };

    return compostNotificationData
      ?.sort((a, b) => compareDesc(new Date(a.createdTime), new Date(b.createdTime)))
      .map((compostNotification, id) => {
        return (
          <TableRow key={id}>
            <TableCell
              align='left'
              sx={{
                ...cellStyle,
              }}
            >
              <Typography variant='body1'>{getCompostNotificationLocation(compostNotification.emptyingId)}</Typography>
            </TableCell>

            <TableCell align='left' sx={cellStyle}>
              <Typography variant='body1' color='textSecondary'>
                {printddMMDate(compostNotification.createdTime)}
              </Typography>
            </TableCell>

            <TableCell align='left' sx={cellStyle}>
              <Typography variant='body1' color='textSecondary'>
                {getCompostNotificationNotifier(compostNotification.emptyingId)}
              </Typography>
            </TableCell>

            <TableCell align='left' sx={cellStyle}>
              <Typography variant='body1' color='textSecondary'>
                {printddMMDate(getCompostNotificationIn5YearsDate(compostNotification.createdTime))}
              </Typography>
            </TableCell>

            <TableCell align='center' sx={cellStyle}>
              <Typography
                variant='body1'
                color='textSecondary'
                fontWeight={500}
                sx={getTableStatusStyles(getCompostNotificationStatus(compostNotification.createdTime))}
              >
                {getText(getCompostNotificationStatus(compostNotification.createdTime))}
              </Typography>
            </TableCell>
          </TableRow>
        );
      });
  };

  const renderCards = () => {
    return compostNotificationData
      ? compostNotificationData.map((compostNotification, id) => (
          <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }} key={id}>
            <CardContent
              sx={{
                paddingLeft: '12px',
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'} px={1} mb={2} mt={1}>
                <Typography
                  variant='body2'
                  textAlign={'center'}
                  fontWeight={500}
                  sx={getCardsStatusStyles(getCompostNotificationStatus(compostNotification.createdTime))}
                >
                  {getText(getCompostNotificationStatus(compostNotification.createdTime))}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {' '}
                  {printddMMDate(compostNotification.createdTime)}
                </Typography>
              </Stack>

              <Stack direction={'row'} justifyContent={'space-between'} px={1} mb={2} mt={1}>
                <Typography variant='h5'> {getCompostNotificationLocation(compostNotification.emptyingId)}</Typography>
              </Stack>

              <Stack px={1}>
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography variant='body2' color='textSecondary' display={'inline'} noWrap>
                    {getText('composting-notifications-notifier-column') + ' '}
                  </Typography>
                  <Typography noWrap variant='body2' color='textPrimary' display={'inline'}>
                    {getCompostNotificationNotifier(compostNotification.emptyingId)}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant='body2' color='textSecondary' display={'inline'}>
                    {getText('composting-notifications-notification-to-be-remade-column') + ' '}
                  </Typography>
                  <Typography variant='body2' color='textPrimary' display={'inline'}>
                    {printddMMDate(getCompostNotificationIn5YearsDate(compostNotification.createdTime))}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        ))
      : [];
  };

  return (
    <>
      <OverlayDialog
        headerTextKey={'composting-notifications'}
        open={showNewCompostingNotificationDialog}
        onClose={handleCloseNewCompostingNotificationDialog}
      >
        {emptyingIds && (
          <NewCompostingNotification emptyingInfos={emptyingIds} onClose={handleCloseNewCompostingNotificationDialog} />
        )}
      </OverlayDialog>
      <Header content={renderHeaderContent()} />
      <ExceptionHandler error={error}>
        <ContentLoadingHandler isLoading={compostNotificationIsLoading} skeleton={renderSkeleton()}>
          <DataTable headers={invoiceTableHeaders(getText)} customRows={renderRows()} cards={renderCards()} />
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default CompostingNotifications;
