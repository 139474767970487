import { STORED_COMPANY_INFO } from '../localStorageKeys';
import { ViewBreakpoints } from '../model';
import { CompanyInfo } from '../service/authService';

// Sometimes localstorage return 'NULL' string value
function getButtonColorCode() {
  const companyString = localStorage.getItem(STORED_COMPANY_INFO);
  let color = '#377A6C';

  if (typeof companyString === 'string') {
    try {
      const info: CompanyInfo = JSON.parse(companyString);
      if (info.color) {
        color = info.color;
      }
    } catch {}
  }

  if (!color || color.toUpperCase() === 'NULL') {
    color = '#377A6C';
  }

  return color;
}

export const DEFAULT_BREAKPOINTS: ViewBreakpoints = {
  xs: 0,
  sm: 320,
  md: 640,
  lg: 1007,
  xl: 1048,
};

export const FONTWEIGHT_REGULAR = 400;
export const FONTWEIGHT_MEDIUM = 500;
export const MAX_WIDTH = '1048px';
export const USER_DEFINED_PRIMARY_COLOR = getButtonColorCode();
export const USER_DEFINED_BG_COLOR = '#F7F7F7';
export const GRID_COLUMN_VALUE = {
  xs: 4,
  sm: 4,
  md: 8,
  lg: 12,
  xl: 12,
};
