import { Stack, Typography, useTheme } from '@mui/material';
import { UiTexts } from '../model';
import { useContext } from 'react';
import { LanguageContext } from '../contexts/language-context';

export function ShowSummaryItem({ labelKey, data }: { labelKey: keyof UiTexts; data: string | null | undefined }) {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);

  return (
    <Stack direction='column'>
      <Typography variant='body2' color={palette.text.secondary}>
        {getText(labelKey)}
      </Typography>
      <Typography variant='body1' color={palette.text.primary}></Typography>
      {data}
    </Stack>
  );
}
