import { Dispatch, SetStateAction, useContext } from 'react';
import { Box, Button, Container, Divider, RadioGroup, Stack, Typography } from '@mui/material';
import ExceptionHandler from '../../../components/common-materialui/status-handling/ExceptionHandler';
import { LanguageContext } from '../../../contexts/language-context';
import { LargerThanBreakpoint } from '../../../util/viewportUtils';
import { overlayDialogPadding } from '../../../components/common-materialui/dialogs/OverlayDialog';
import { CustomerType } from '../../../model';
import { RenderSelectionCustomerType } from '../../../util/registerUtils';
import AuthContext from '../../../contexts/auth-context';

interface Props {
  customerType: CustomerType;
  setCustomerType: Dispatch<SetStateAction<CustomerType>>;
  onPhaseChange: () => void;
  onCancel: () => void;
}

const RegisterType = ({ customerType, setCustomerType, onPhaseChange, onCancel }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerType(event.target.value as CustomerType);
  };

  return (
    <Stack direction='column'>
      <ExceptionHandler error={'error'}>
        <Container>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>{getText('select-register-i-am')}</Typography>
            <Divider />
          </Stack>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <RadioGroup name='customer-type' value={customerType} onChange={handleRadioChange}>
              {authService.isCreateNewCustomerEnabled() && RenderSelectionCustomerType('new-customer', 'new')}
              {authService.isCreateExistingCustomerEnabled() && RenderSelectionCustomerType('old-customer', 'old')}
              {RenderSelectionCustomerType('old-customer-ssn', 'old-ssn')}
            </RadioGroup>
          </Stack>
        </Container>
        <Container>
          <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
            <Box>
              <Button size='large' color='secondary' onClick={onCancel}>
                {getText('dialog-cancel')}
              </Button>
            </Box>
            <Box>
              <Button
                variant='contained'
                size='large'
                onClick={onPhaseChange}
                disabled={customerType === null || customerType.length === 0}
              >
                {getText('dialog-next')}
              </Button>
            </Box>
          </Stack>
        </Container>
      </ExceptionHandler>
    </Stack>
  );
};

export default RegisterType;
