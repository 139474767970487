import { useContext } from 'react';
import { LanguageContext } from '../contexts/language-context';
import { Language } from '../model';

// TODO, remove this after pages are remade
// use the other one below, useContext can cause hook inconsistencies when called like this
export function ConvertToCurrentLocale(
  inputNum: number | undefined | null,
  minimumFractionAmount?: number | undefined
) {
  const { lang } = useContext(LanguageContext);

  const formatter = new Intl.NumberFormat(lang, {
    minimumFractionDigits: minimumFractionAmount ?? undefined,
  });

  if (inputNum != undefined || inputNum != null) {
    return formatter.format(inputNum);
  }

  return inputNum;
}

export function convertToCurrentLocaleLang(
  lang: Language,
  inputNum: number | undefined | null,
  minimumFractionAmount?: number | undefined,
  maximumFractionAmount?: number | undefined
) {
  const formatter = new Intl.NumberFormat(lang, {
    minimumFractionDigits: minimumFractionAmount ?? undefined,
    maximumFractionDigits: maximumFractionAmount ?? undefined,
  });

  if (inputNum !== undefined && inputNum !== null) {
    return formatter.format(inputNum);
  }

  return inputNum;
}
