import { useContext, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { Control, FieldErrors, UseFormSetValue, useWatch } from 'react-hook-form';
import ControlCheckboxInput from '../../../../../components/common-materialui/form/ControlCheckboxInput';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import { LanguageContext } from '../../../../../contexts/language-context';
import { ClosedTankForm, LocationAccessForm, SepticTankForm, TreatmentPlantForm } from '../models';

interface Props {
  control: Control<LocationAccessForm, any>;
  errors: FieldErrors<SepticTankForm | TreatmentPlantForm>;
  setValue: UseFormSetValue<any>;
}

const LocationAccess = ({ control, errors, setValue }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const weightLimit = useWatch({
    control,
    name: 'weightLimit',
  });

  const other = useWatch({
    control,
    name: 'other',
  });

  const noLimits = useWatch({
    control,
    name: 'noLimits',
  });

  useEffect(() => {
    if (noLimits) {
      setValue('weightLimit', false);
      setValue('weightLimitDetails', '');
      setValue('other', false);
      setValue('otherDetails', '');
      setValue('noWinterEmptyings', false);
      setValue('locked', false);
    }
  }, [noLimits, setValue]);

  return (
    <Stack direction={'column'}>
      <Typography variant='h6' sx={{ paddingTop: '10px' }}>
        {getText('septic-tank-access-limitations-header')}
      </Typography>
      <Stack direction={'column'}>
        <ControlCheckboxInput
          control={control}
          error={errors.noWinterEmptyings}
          label={'septic-tank-access-limitations-option-no-limits'}
          name={'noLimits'}
          validations={[]}
        />
        <ControlCheckboxInput
          control={control}
          error={errors.noLimits}
          label={'septic-tank-access-limitations-option-no-winter'}
          name={'noWinterEmptyings'}
          validations={[]}
          disabled={noLimits}
        />
        <ControlCheckboxInput
          control={control}
          error={errors.locked}
          label={'septic-tank-access-limitations-option-locked'}
          name={'locked'}
          validations={[]}
          disabled={noLimits}
        />
        <ControlCheckboxInput
          control={control}
          error={errors.weightLimit}
          label={'septic-tank-access-limitations-option-weight-limit'}
          name={'weightLimit'}
          validations={[]}
          disabled={noLimits}
        />
        {weightLimit && (
          <ControlTextInput
            inputType='string'
            control={control}
            name='weightLimitDetails'
            validations={['required']}
            error={errors.weightLimitDetails}
            label='septic-tank-access-limitations-weight-limit-details'
            multiline
          />
        )}
        <ControlCheckboxInput
          control={control}
          error={errors.other}
          label={'septic-tank-access-limitations-option-weight-other'}
          name={'other'}
          validations={[]}
          disabled={noLimits}
        />
        {other && (
          <ControlTextInput
            inputType='string'
            control={control}
            name='otherDetails'
            validations={['required']}
            error={errors.otherDetails}
            label='septic-tank-access-limitations-take-other-steps'
            guideText={getText('septic-tank-access-limitations-other-details')}
            multiline
          />
        )}
        <ControlTextInput
          control={control}
          inputType='string'
          name='location'
          label='septic-tank-access-limitations-location'
          error={errors.location}
          validations={['required']}
        />
      </Stack>
    </Stack>
  );
};

export default LocationAccess;
