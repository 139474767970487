import React, { useEffect } from 'react';
import FormThemeProvider from '../FormThemeProvider';
import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import { getUiSchemaTitle } from './utils';

const AutoPopulateSelect = function (props: any) {
  const [value, setValue] = React.useState<any>('');
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [title, setTitle] = React.useState<string>(props.label);
  const [schema, setSchema] = React.useState<any>(props.schema);
  const [uiSchema, setUiSchema] = React.useState<any>(props.uiSchema);
  const theme = FormThemeProvider;

  const initialize = (properties: any) => {
    const tenantId = properties?.formContext?.tenantId;
    const domain = properties?.formContext?.domain;
    const language = properties?.formContext?.language;

    const uiOptions = properties.uiSchema['ui:options'];
    const orginalEndpoint = uiOptions.endpoint;
    let endpoint = orginalEndpoint;

    // Fill domain and tenantId they exist in url
    if (domain != null) {
      endpoint = endpoint.replaceAll('{domain}', domain);
    }
    if (tenantId != null) {
      endpoint = endpoint.replaceAll('{tenantId}', tenantId);
    }
    if (language != null) {
      endpoint = endpoint.replaceAll('{language}', language);
    }

    let keyField = uiOptions.keyField;
    let valueField = uiOptions.valueField;

    setTitle(getUiSchemaTitle(props));

    fetch(endpoint)
      .then((e) => e.json())
      .then((results: any) => {
        const data: Array<any> = results.map((x: any) => [x[valueField], x[keyField]]);
        setOptions(data);
      });
  };

  useEffect(() => {
    setUiSchema(props.uiSchema);
  }, [props.uiSchema]);

  useEffect(() => {
    setSchema(props.schema);
  }, [props.schema]);

  useEffect(() => {
    initialize(props);
  }, [props.formContext.formId, props.formContext.language, schema, uiSchema]);

  const onChange = (event: any) => {
    const targetValue = event.target.value;
    setValue(targetValue);
    props.onChange(targetValue);
  };

  if (options === null) {
    return <Select label={props.label} value={value} onChange={onChange} />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <FormControl required={props.required}>
          <InputLabel required={props.required} id={props.id + '-label'} error={props.rawErrors != null}>
            {title}
          </InputLabel>
          <Select
            required={props.required}
            labelId={props.id + '-label'}
            id={props.id}
            label={title}
            value={value}
            onChange={onChange}
            error={props.rawErrors != null}
          >
            {options.map((opt: any, index: any) => (
              <MenuItem value={opt[0]} key={index}>
                {opt[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
  }
};

export default AutoPopulateSelect;
