import { useContext } from 'react';
import { Container, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../../../../contexts/language-context';
import { ClosedTankForm, OtherForm, SepticTankForm, TreatmentPlantForm } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import PropertyClosedTankSummary from './PropertyClosedTankSummary';
import PropertyOtherOptionsSummary from './PropertyOtherOptionsSummary';
import PropertySepticTankSummary from './PropertySepticTankSummary';
import PropertyTreatmentPlantSummary from './PropertyTreatmentPlantSummary';

interface Props {
  wasteWaterOptions: string[];
  septicTank?: Partial<SepticTankForm>;
  septicTankOptionB?: Partial<SepticTankForm>;
  closedTank?: Partial<ClosedTankForm>;
  treatmentPlant?: Partial<TreatmentPlantForm>;
  other?: Partial<OtherForm>;
}

const PropertyWasteWaterTreatmentSummary = ({
  wasteWaterOptions,
  septicTank,
  septicTankOptionB,
  closedTank,
  treatmentPlant,
  other,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';
  const subtitleMargin = LargerThanBreakpoint('md') ? 2 : 1;

  const translateWasteWaterOptions = (input: string) => {
    switch (input) {
      case 'A':
        return getText('waste-water-treatment-option-A');
      case 'B':
        return getText('waste-water-treatment-option-B');
      case 'C':
        return getText('waste-water-treatment-option-C');
      case 'D':
        return getText('waste-water-treatment-option-D');
      case 'E':
        return getText('waste-water-treatment-option-E');
      case 'F':
        return getText('waste-water-treatment-option-F');
      case 'G':
        return getText('waste-water-treatment-option-G-secondary');
      default:
        return '';
    }
  };

  const translatedWasteWaterOptions = wasteWaterOptions
    .map((option) => translateWasteWaterOptions(option))
    .filter((option) => option !== '');

  const translatedSepticTankOption = translateWasteWaterOptions(
    wasteWaterOptions.find((option) => option === 'A') ?? ''
  );

  const translatedSepticTankOptionB = translateWasteWaterOptions(
    wasteWaterOptions.find((option) => option === 'B') ?? ''
  );

  const translatedTreatmentPlantOption = translateWasteWaterOptions(
    wasteWaterOptions.find((option) => option === 'C' || option === 'D') ?? ''
  );

  const translatedClosedTankOption = translateWasteWaterOptions(
    wasteWaterOptions.find((option) => option === 'E' || option === 'F') ?? ''
  );

  return (
    <Container>
      <Grid container direction={direction} wrap='nowrap' spacing={4} justifyContent={'space-between'} marginTop={2}>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant='h5' sx={{ paddingTop: '10px' }}>
            {getText('property-waste-water-treatment-summary-header')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction={'column'}>
            <Typography variant='h6' sx={{ fontWeight: '450', paddingTop: '10px' }} marginBottom={subtitleMargin}>
              {getText('property-waste-water-treatment-summary-fields-header')}
            </Typography>
            <Typography color={palette.text.secondary}>
              {getText('property-waste-water-treatment-summary-fields-description')}
            </Typography>
            {translatedWasteWaterOptions.map((option, index) => (
              <Stack direction={'row'} useFlexGap spacing={0.5}>
                <Typography variant='body1'>-</Typography>
                <Typography key={index}>{option}</Typography>
              </Stack>
            ))}
            {septicTank && translatedSepticTankOption && (
              <Stack direction={'column'} marginTop={subtitleMargin}>
                <Divider />
                <PropertySepticTankSummary septicTankOption={translatedSepticTankOption} septicTank={septicTank} />
              </Stack>
            )}
            {septicTankOptionB && translatedSepticTankOptionB && (
              <Stack direction={'column'} marginTop={subtitleMargin}>
                <Divider />
                <PropertySepticTankSummary
                  septicTankOption={translatedSepticTankOptionB}
                  septicTank={septicTankOptionB}
                />
              </Stack>
            )}
            {treatmentPlant && (
              <Stack direction={'column'} marginTop={subtitleMargin}>
                <Divider />
                <PropertyTreatmentPlantSummary
                  treatmentPlantOption={translatedTreatmentPlantOption}
                  treatmentPlant={treatmentPlant}
                />
              </Stack>
            )}
            {closedTank && (
              <Stack direction={'column'} marginTop={subtitleMargin}>
                <Divider />
                <PropertyClosedTankSummary closedTankOption={translatedClosedTankOption} closedTank={closedTank} />
              </Stack>
            )}
            {other && (
              <Stack direction={'column'} marginTop={subtitleMargin}>
                <Divider />
                <PropertyOtherOptionsSummary other={other} />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PropertyWasteWaterTreatmentSummary;
