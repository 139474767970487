import { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';
import ApiContext from '../../../contexts/api-context';
import { BillingInfo, ContractInfo, EmptyingInfo, Order, ServiceType } from '../../../model';
import { Button, Grid, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../../components/common-materialui/header/Header';
import ExceptionHandler from '../../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../../components/common-materialui/status-handling/ContentLoadingHandler';
import ProgressComponent from '../../../components/common-materialui/ProgressComponent';
import BreadcrumbBarContext from '../../../contexts/BreadcrumbBarContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { printComparableDate } from '../../../util/calendarUtil';
import ServiceListContainer from './ServiceListContainer';
import { getOnGoingContractData, getServiceListContainerState, printAddress } from './emptyingLocationUtils';
import ContractCard from './ContractCard';
import SharedContainerCard from './SharedContractCard';
import useToggle from '../../../hooks/toggleHook';
import OrderCard from './OrderCard';
import WaitingContractCard from './WaitingContractCard';
import EmptyingLocationContactInfo from './EmptyingLocationContactInfo';
import EmptyingLocationBillingInfo from './EmptyingLocationBillingInfo';
import OrderDateFilter from './OrderDateFilter';
import ContentSkeleton from './ContentSkeleton';
import ConfirmDialog from '../../../components/common-materialui/dialogs/ConfirmDialog';
import OverlayDialog from '../../../components/common-materialui/dialogs/OverlayDialog';
import OrderProduct from './OrderProduct';
import WellCollectionMenu from './WellCollectionMenu';
import AuthContext from '../../../contexts/auth-context';

interface EmptyingLocationParams {
  id: string;
}

interface WaitingContractData {
  customernumber: string;
  position: number;
}

const EmptyingLocation = (): JSX.Element => {
  const { id: emptyingId } = useParams<EmptyingLocationParams>();

  const authService = useContext(AuthContext);
  const { getText } = useContext(LanguageContext);
  const isWellCollectionEnabled = authService.isWellCollectionEnabled();
  const queryClient = useQueryClient();
  const {
    fetchContracts,
    fetchEmptyingInfo,
    fetchFirstBillingInfoForEmptyingData,
    fetchOrdersBetweenDates,
    approveContract,
    rejectContract,
  } = useContext(ApiContext);

  const theme = useTheme();
  const { palette } = useTheme();

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const largerThanMd = useMediaQuery(theme.breakpoints.up('lg'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';
  const infoBoxesMarginTop = largerThanMd ? 0 : 1;

  // Expanded/collapsed states for service lists.
  const [waitingExpanded, setWaitingExpanded] = useToggle(false);
  const [approvedExpanded, setApprovedExpanded] = useToggle(false);
  const [ordersExpanded, setOrdersExpanded] = useToggle(false);
  const [sharedExpanded, setSharedExpanded] = useToggle(false);

  const [showApproveDialog, setShowApproveDialog] = useState<boolean>(false);
  const [dataOfApprovedContract, setDataOfApprovedContract] = useState<WaitingContractData | undefined>(undefined);

  const [showRejectDialog, setShowRejectDialog] = useState<boolean>(false);
  const [dataOfRejectedContract, setDataOfRejectedContract] = useState<WaitingContractData | undefined>(undefined);

  const [showOrderProductDialog, setShowOrderProductDialog] = useState<boolean>(false);
  const handleCloseOrderProductDialog = useCallback(() => {
    setShowOrderProductDialog(false);
  }, []);

  // Orders filtering default date range
  // * start date is set 12 weeks to past
  // * end date is set as current date
  let ordersRangeStart = new Date();
  ordersRangeStart.setDate(ordersRangeStart.getDate() - 12 * 7);
  const [ordersStart, setOrdersStart] = useState<Date | null>(ordersRangeStart);
  const [ordersEnd, setOrdersEnd] = useState<Date | null>(new Date());

  // Fetching data with react-query
  const {
    isLoading: contractsLoading,
    data: contracts,
    error: contractsError,
  } = useQuery<ContractInfo[]>(['contracts', emptyingId], () => fetchContracts(emptyingId));

  // Filtered contract lists.
  const { contractsWaitingForApproval, continuingContracts } = getOnGoingContractData(contracts);

  const {
    isLoading: ordersLoading,
    data: orders,
    error: ordersError,
  } = useQuery<Order[]>(['orders', emptyingId, ordersStart, ordersEnd], () => {
    if (ordersStart && ordersEnd) {
      return fetchOrdersBetweenDates(emptyingId, printComparableDate(ordersStart), printComparableDate(ordersEnd));
    }
    return Promise.resolve([]);
  });

  const {
    isLoading: emptyinInfoLoading,
    data: emptyingInfo,
    error: emptyingInfoError,
  } = useQuery<EmptyingInfo>(['emptying-info', emptyingId], () => fetchEmptyingInfo(emptyingId));

  const {
    isLoading: billingInfoLoading,
    data: billingInfo,
    error: billingInfoError,
  } = useQuery<BillingInfo | undefined>(['billing-info', emptyingId], () =>
    fetchFirstBillingInfoForEmptyingData(emptyingId)
  );

  const {
    mutate: callApproveContractApi,
    isLoading: approveContractLoading,
    error: approveContractError,
  } = useMutation((data: WaitingContractData) => approveContract(data.customernumber, data.position.toString()), {
    // Contracts need to be refetched so approved contract moves to continuous services.
    onSuccess: () => queryClient.invalidateQueries(['contracts', emptyingId]),
  });

  const {
    mutate: callRejectContractApi,
    isLoading: rejectContractLoading,
    error: rejectContractError,
  } = useMutation((data: WaitingContractData) => rejectContract(data.customernumber, data.position.toString()), {
    onSuccess: () => queryClient.invalidateQueries(['contracts', emptyingId]),
  });

  // Add address as path name to breadcrumb bar
  const { addCustomPathName } = useContext(BreadcrumbBarContext);

  useEffect(() => {
    addCustomPathName({
      [`${emptyingId}`]: {
        name: printAddress(emptyingInfo?.address) ?? '',
        pathTo: '/emptying-infos/' + emptyingId,
      },
    });
  }, [emptyingInfo]);
  const isLoading = contractsLoading || ordersLoading || emptyinInfoLoading || billingInfoLoading;

  // Hide/show and item count data for service lists
  const { showList: showWaiting, itemCount: waitingCount } = getServiceListContainerState(
    contractsWaitingForApproval,
    largerThanPhone
  );
  const { showList: showApproved, itemCount: approvedCount } = getServiceListContainerState(
    continuingContracts,
    largerThanPhone
  );

  const sharedContainerContracts = useMemo(() => {
    return emptyingInfo?.sharedContainer?.flatMap((container) =>
      container?.products?.flatMap((product) => ({
        product: product,
        info: {
          sharedGroupName: container.sharedGroupName,
          isCorrespondingPerson: container.isCorrespondingPerson,
          correspondingPersonName: container.correspondingPersonName,
        },
      }))
    );
  }, [emptyingInfo]);

  const sharedContainerProducts = sharedContainerContracts?.flatMap((contract) => contract.product);

  const sharedContainerContractData = contracts?.filter(
    (c) =>
      c.sharedContainer &&
      (c.endDate === null || new Date(c.endDate) > new Date()) &&
      sharedContainerProducts?.some((p) => p.name === c.name)
  );

  const { itemCount: ordersCount } = getServiceListContainerState(orders ?? [], largerThanPhone);
  const { showList: showShared, itemCount: sharedCount } = getServiceListContainerState(
    sharedContainerContractData || [],
    largerThanPhone
  );

  const orderServiceLink = () => {
    if (authService.isAddServiceEnabled()) {
      return (
        <Button onClick={() => setShowOrderProductDialog(true)} sx={{ p: 0, color: palette.text.links }}>
          {getText('emptying-location-order-product')}
        </Button>
      );
    }
    return <></>;
  };

  const headerContent = () => {
    if (isLoading) {
      return <Skeleton variant='rectangular' height={80} width={'100%'}></Skeleton>;
    }
    return (
      <Stack direction={'row'}>
        <Stack
          spacing={3}
          justifyContent='space-between'
          direction={headerStackDirection}
          marginTop={largerThanPhone ? 1 : 0.75}
          marginBottom={largerThanPhone ? 3 : 2}
          flexGrow={1}
          gap={2}
        >
          <Typography variant='h2'>
            {printAddress(emptyingInfo?.address)}
            <Typography variant='body1' mt={1}>
              {emptyingInfo?.buildingClassification?.name}
            </Typography>
          </Typography>

          <Stack spacing={1} direction={headerStackDirection}>
            <Typography color={palette.text.links}>{orderServiceLink()}</Typography>
          </Stack>
        </Stack>
        {isWellCollectionEnabled && (
          <Stack spacing={2} sx={{ paddingLeft: largerThanPhone ? '25px' : '5px' }}>
            <WellCollectionMenu />
          </Stack>
        )}
      </Stack>
    );
  };

  // Handling functions for contract approving.
  const handleShowApproveDialog = useCallback((customerNumber: string, position: number) => {
    setDataOfApprovedContract({ customernumber: customerNumber, position: position });
    setShowApproveDialog(true);
  }, []);

  const handleContracApproval = useCallback(() => {
    if (!dataOfApprovedContract) {
      return;
    }
    callApproveContractApi(dataOfApprovedContract);
    setShowApproveDialog(false);
  }, [dataOfApprovedContract, callApproveContractApi]);

  const handleCloseApproveContractDialog = useCallback(() => {
    setShowApproveDialog(false);
  }, []);

  // Handling functions for contract rejecting.
  const handleShowRejectDialog = useCallback((customerNumber: string, position: number) => {
    setDataOfRejectedContract({ customernumber: customerNumber, position: position });
    setShowRejectDialog(true);
  }, []);

  const handleContractRejection = useCallback(() => {
    if (dataOfRejectedContract === undefined) {
      return;
    }
    callRejectContractApi(dataOfRejectedContract);
    setShowRejectDialog(false);
  }, [dataOfRejectedContract, callRejectContractApi]);

  const handleCloseRejectContractDialog = useCallback(() => {
    setShowRejectDialog(false);
  }, []);

  const contentDirection = largerThanMd ? 'row' : 'column';
  const contactAndBillingInfoDirection = largerThanPhone && !largerThanMd ? 'row' : 'column';

  return (
    <>
      <OverlayDialog
        headerTextKey={'emptying-location-order-product-header'}
        open={showOrderProductDialog}
        onClose={handleCloseOrderProductDialog}
      >
        <OrderProduct emptyingId={emptyingId} onClose={handleCloseOrderProductDialog} />
      </OverlayDialog>

      <ConfirmDialog
        open={showApproveDialog || approveContractLoading}
        isLoading={approveContractLoading}
        titleMessageKey='emptying-location-approve-contract-dialog-title'
        descriptionMessageKey='emptying-location-approve-contract-dialog-text'
        cancelationMessageKey='emptying-location-approve-contract-dialog-cancel-button'
        confirmationMessageKey='emptying-location-approve-contract-dialog-approve-button'
        handleClose={handleCloseApproveContractDialog}
        handleConfirmation={handleContracApproval}
      />

      <ConfirmDialog
        open={showRejectDialog || rejectContractLoading}
        isLoading={rejectContractLoading}
        descriptionMessageKey='emptying-location-reject-contract-dialog-text'
        cancelationMessageKey='emptying-location-reject-contract-dialog-cancel-button'
        confirmationMessageKey='emptying-location-reject-contract-dialog-approve-button'
        handleClose={handleCloseRejectContractDialog}
        handleConfirmation={handleContractRejection}
      />

      <Header content={headerContent()} />
      <ProgressComponent isLoading={isLoading} />

      <ExceptionHandler
        error={
          contractsError ||
          ordersError ||
          emptyingInfoError ||
          billingInfoError ||
          approveContractError ||
          rejectContractError
        }
      >
        <ContentLoadingHandler isLoading={isLoading} skeleton={<ContentSkeleton />}>
          <Grid container direction={contentDirection}>
            <Grid item xs={4} md={8}>
              <Stack direction='column' spacing={1}>
                {sharedContainerContracts?.every((contract) => contract.info.isCorrespondingPerson) && (
                  <ServiceListContainer
                    serviceType={ServiceType.WaitingContract}
                    show={showWaiting}
                    labelTextKey={'service-order-contracts-waiting-for-approval'}
                    itemCount={waitingCount}
                    isExpanded={waitingExpanded}
                    toggleExpanded={setWaitingExpanded}
                  >
                    <Stack direction='column' spacing={2}>
                      {contractsWaitingForApproval.map((contract) => (
                        <WaitingContractCard
                          contract={contract}
                          emptyingId={emptyingId}
                          handleShowApproveDialog={handleShowApproveDialog}
                          handleShowRejectDialog={handleShowRejectDialog}
                          key={'waiting-contract-' + contract.customerId + '_' + contract.position}
                        />
                      ))}
                    </Stack>
                  </ServiceListContainer>
                )}

                <ServiceListContainer
                  serviceType={ServiceType.Contract}
                  show={showApproved}
                  labelTextKey={'service-order-contracts'}
                  itemCount={approvedCount}
                  isExpanded={approvedExpanded}
                  toggleExpanded={setApprovedExpanded}
                >
                  {continuingContracts.map((contract) => (
                    <ContractCard
                      contract={contract}
                      emptyingId={emptyingId}
                      key={'contract-' + contract.customerId + '_' + contract.position}
                    />
                  ))}
                </ServiceListContainer>

                {/* Orders list needs to be visible always so user can input filter date fields*/}
                <ServiceListContainer
                  serviceType={ServiceType.Order}
                  show={true}
                  labelTextKey={'service-order-single-orders'}
                  itemCount={ordersCount}
                  isExpanded={ordersExpanded}
                  toggleExpanded={setOrdersExpanded}
                >
                  <Typography ml={2} variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-location-orders-date-range')}
                  </Typography>

                  <OrderDateFilter
                    start={ordersStart}
                    end={ordersEnd}
                    setStart={(d) => setOrdersStart(d)}
                    setEnd={(d) => setOrdersEnd(d)}
                  />
                  {orders?.map((order, index) => (
                    <OrderCard order={order} key={'order-' + index} />
                  ))}
                </ServiceListContainer>

                <ServiceListContainer
                  serviceType={ServiceType.SharedContainerContract}
                  show={showShared}
                  labelTextKey={'emptying-group-shared-container-contracts'}
                  itemCount={sharedCount}
                  isExpanded={sharedExpanded}
                  toggleExpanded={setSharedExpanded}
                >
                  {sharedContainerContractData?.map((contract, index) => {
                    const sharedContainerContract = sharedContainerContracts?.find(
                      (c) => c.product.name === contract.name
                    );
                    return (
                      sharedContainerContract && (
                        <SharedContainerCard
                          emptyingId={emptyingId}
                          contract={sharedContainerContract.product}
                          key={'shared-container-contract-' + contract.productGuid + index}
                          sharedContainerInfo={sharedContainerContract.info}
                          nextEmptying={contract.nextEmptying}
                          emptyingIntervals={contract.emptyingIntervals}
                        />
                      )
                    );
                  })}
                </ServiceListContainer>
              </Stack>
            </Grid>
            <Grid item xs={4} md={8} lg={4} mt={infoBoxesMarginTop}>
              <Grid container direction={contactAndBillingInfoDirection}>
                <Grid item xs={4}>
                  <EmptyingLocationContactInfo
                    contactPerson={emptyingInfo?.contactPerson}
                    emptyingId={emptyingInfo?.id}
                    isCompany={emptyingInfo?.isCompany}
                  />
                </Grid>
                <Grid item xs={4}>
                  <EmptyingLocationBillingInfo billingInfo={billingInfo} emptyingId={emptyingId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default EmptyingLocation;
