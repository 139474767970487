import { parseISO, isAfter, endOfDay, isValid } from "date-fns";

export const isContractCurrentlyValid = (contractEndDate: Date | string | null): boolean => {
    if (!contractEndDate) return true;
    
    const currentDate = new Date();
    const endDate = contractEndDate instanceof Date 
      ? contractEndDate 
      : parseISO(contractEndDate);

    if (!isValid(endDate)) {
        throw new Error('Invalid date format');
    }
      
    return isAfter(endOfDay(endDate), currentDate);
  };
  