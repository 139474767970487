import { createContext } from 'react';

import { Language, TranslationsObject, UiTexts } from '../model';

const uiTexts: UiTexts = {
  'selected-language': {
    fi: 'Kieli: Suomi',
    sv: 'Språk: Svenska',
    en: 'Language: English',
  },
  'language-finnish': {
    fi: 'Suomi',
    sv: 'Finska',
    en: 'Finnish',
  },
  'language-swedish': {
    fi: 'Ruotsi',
    sv: 'Svenska',
    en: 'Swedish',
  },
  'language-english': {
    fi: 'Englanti',
    sv: 'Engelska',
    en: 'English',
  },
  'language-chosen-finnish': {
    fi: 'Suomeksi',
    sv: 'På Finska',
    en: 'In Finnish',
  },
  'language-chosen-swedish': {
    fi: 'Ruotsiksi',
    sv: 'På Svenska',
    en: 'In Swedish',
  },
  'language-chosen-english': {
    fi: 'Englanniksi',
    sv: 'På Engelska',
    en: 'In English',
  },
  'not-yet-implemented': {
    fi: 'Ei vielä toteutettu',
    sv: 'Inte ännu implementerad',
    en: 'Not yet implemented',
  },
  // Error messages
  'error-resource-not-found-title': {
    fi: 'Hakemaasi tietoa ei löydy',
    sv: 'TODO: Hakemaasi tietoa ei löydy',
    en: 'Requested resource is not found',
  },
  'error-resource-not-found-message': {
    fi: 'Hakemaasi tietoa ei löydy.',
    sv: 'Efterfrågade resursen hittades inte',
    en: 'Requested resource is not found',
  },
  'error-fetching-file': {
    fi: 'Virhe tiedoston haussa',
    sv: 'Det gick inte att hämta filen',
    en: 'Error in retrieving the file',
  },
  'error-general-message': {
    fi: 'Pyyntöä ei pystytty suorittamaan.',
    sv: 'Begäran kunde inte utföras.',
    en: 'Request could not be executed.',
  },
  'error-general-title': {
    fi: 'Virhe',
    sv: 'Fel',
    en: 'Error',
  },
  'error-service-break-title': {
    fi: 'Palvelukatkos',
    sv: 'Tjänsteavbrott',
    en: 'Maintenance break',
  },
  'error-service-break-message': {
    fi: 'Virhe järjestelmässä. Ota yhteyttä asiakaspalveluun.',
    sv: 'Tjänsten är otillgänglig för tillfället.',
    en: 'This service is temporarily unavailable. Please contact customer service.',
  },
  'error-login-refresh': {
    fi: 'Kirjautumisen automaattinen päivittäminen ei onnistunut. Saatat joutua kirjautumaan uudestaan.',
    sv: 'Automatisk uppdatering av inloggning fungerade inte. Du kanske måste logga in igen.',
    en: 'Automatic login refresh failed. You may have to login again.',
  },
  'error-unauthorized-title': {
    fi: 'Luvaton toiminto',
    sv: 'Otillåten åtgärd',
    en: 'Unauthorized action',
  },
  'error-unauthorized-message': {
    fi: 'Istunto on vanhentunut tai oikeudet ei riitä toimintoon',
    sv: 'Sessionen har förfallet eller så har du inte tillräckligt med behörigheter.',
    en: 'The session has expired, or you do not have enough permissions.',
  },
  'error-unauthorized-login-message': {
    fi: 'Sisäänkirjautuminen epäonnistui. Tarkista käyttäjätunnus ja salasana.',
    sv: 'Inloggning misslyckades. Kontrollera ditt användarnamn och lösenord.',
    en: 'Error in logging in. Please check your username and password.',
  },
  'error-loginfailed-login-message': {
    fi: 'Sisäänkirjautuminen epäonnistui.',
    sv: 'Inloggning misslyckades.',
    en: 'Login failed.',
  },
  'error-single-logout-failed-message': {
    fi: 'Olet uloskirjautunut Asioinnista.',
    sv: 'Du har blivit utloggad från Ärendehantering.',
    en: 'You have been signed out of the E-Services.',
  },
  'error-register-saml-duplicate-message': {
    fi: 'Käyttäjä on jo rekisteröity. Kirjaudu järjestelmään tunnuksillasi.',
    sv: 'Användaren är redan registrerad. Logga in med dina referenser.',
    en: 'This username has already been registered. Log into the system with your account.',
  },
  'error-register-saml-title': {
    fi: 'Rekisteröinti epäonnistui.',
    sv: 'Registreringen misslyckades.',
    en: 'The registration failed',
  },
  'error-register-invoice-not-found-title': {
    fi: 'Rekisteröinti epäonnistui.',
    sv: 'Registreringen misslyckades.',
    en: 'The registration failed.',
  },
  'error-register-invoice-not-found-message': {
    fi: 'Laskua tai asiakasta ei löytynyt. Tarkista laskunumero ja asiakasnumero.',
    sv: 'Ingen faktura eller kund hittades. Kontrollera fakturanummer och kundnummer.',
    en: 'The invoice or customer could not be found. Check the invoice number and the customer number.',
  },
  'error-register-user-account-already-registered-title': {
    fi: 'Rekisteröinti epäonnistui.',
    sv: 'Registreringen misslyckades.',
    en: 'The registration failed.',
  },
  'error-register-user-account-already-registered-message': {
    fi: 'Asiakasnumerolla löytyi jo käyttäjätunnus. Uutta käyttäjätunnusta ei voi luoda tämän lomakkeen kautta. Mikäli olet unohtanut salasanasi käytä salasana palautuslomaketta.',
    sv: 'Kundnumret är redan kopplat till användaren. Ett nytt användar-ID kan inte skapas via detta formulär. Om du har glömt ditt lösenord, använd formuläret för återställning av lösenord.',
    en: 'The customer number is already connected to the user. A new user ID cannot be created through this form. If you have forgotten your password, use the password reset form.',
  },
  'error-identity-operation-failed': {
    fi: 'Käyttäjätunnus on jo olemassa.',
    sv: 'Användarnamn existerar redan.',
    en: 'This user account already exists.',
  },
  'error-billing-account-expired-title': {
    fi: 'Rekisteröinti epäonnistui.',
    sv: 'Registreringen misslyckades.',
    en: 'Registering failed.',
  },
  'error-billing-account-expired-message': {
    fi: 'Rekisteröinti epäonnistui, koska asiakkuus ei ole voimassa.',
    sv: 'Registreringen misslyckades eftersom medlemskapet inte är giltigt.',
    en: 'Registering failed because the customership is not valid.',
  },
  'error-incorrect-login-password': {
    fi: 'Syöttämäsi nykyinen salasana on virheellinen. Varmista, että antamasi salasana on kirjoitettu oikein.',
    sv: 'Felaktigt nuvarande lösenord. Se till att lösenordet du angav är rättstavat.',
    en: 'Incorrect current password. Make sure the password you entered is spelled correctly.',
  },
  'no-connectable-accounts-found-title': {
    fi: 'Rekisteröinti epäonnistui.',
    sv: 'Registreringen misslyckades.',
    en: 'Registration failed.',
  },
  'no-connectable-accounts-found-message': {
    fi: 'Rekisteröinti epäonnistui, koska henkilötiedoilla ei löytynyt asiakkuutta. Palaa edelliselle sivulle ja valitse toinen asiakkuustyyppi.',
    sv: 'Registreringen misslyckades eftersom inget kundkonto hittades med den personliga information som angavs. Gå tillbaka till föregående sida och välj ett annat alternativ kundrelation typ.',
    en: 'Registration failed because no customer account was found with the personal information provided. Go back to previous page and choose another customer account type.',
  },

  // Messages
  'message-success-generic-title': {
    fi: 'Tehtävä onnistui.',
    sv: 'Uppgiften lyckades.',
    en: 'Task succeeded.',
  },
  'message-data-saved': {
    fi: 'Tiedot tallennettu.',
    sv: 'Uppgifterna har sparats.',
    en: 'The data was saved.',
  },
  'message-confirmation-failed': {
    fi: 'Vahvistusviestin lähetys epäonnistui.',
    sv: 'Det gick inte att skicka bekräftelsemeddelande.',
    en: 'The confirmation message could not be sent.',
  },
  'message-forgot-password-reset-link-send-failed': {
    fi: 'Salasanan palautus linkin lähetys epäonnistui.',
    sv: 'Salasanan palautus linkin lähetys epäonnistui.',
    en: 'Salasanan palautus linkin lähetys epäonnistui.',
  },

  // Login Page
  login: { fi: 'Kirjaudu sisään', sv: 'Logga in', en: 'Sign in' },
  email: { fi: 'Sähköposti', sv: 'E-post', en: 'Email' },
  username: { fi: 'Käyttäjätunnus', sv: 'Användarnamn', en: 'Username' },
  password: { fi: 'Salasana', sv: 'Lösenord', en: 'Password' },
  'own-waste': { fi: 'Oma asiointi', sv: 'Eget ärende', en: 'My services' },
  loginDecription: {
    fi: 'Jätehuollon asiasi näppärästi uudistuneesta nettipalvelustamme.',
    sv: 'Avfallshanterings ärenden från vår förnyade nättjänst.',
    en: 'Waste management, conveniently from our new portal',
  },
  'login-info-welcome': {
    fi: 'Tervetuloa Vingoon,',
    sv: 'Välkommen till Vingo,',
    en: 'Welcome to Vingo,',
  },
  'login-info-signin': {
    fi: 'Kirjaudu sisään',
    sv: 'Logga in',
    en: 'Sign In to Continue',
  },
  'login-info-header': {
    fi: 'Ensimmäistä kertaa täällä?',
    sv: 'Första gången här?',
    en: 'Is this your first time here?',
  },
  'login-info-text': {
    fi: 'Käyttääksesi palvelua sinulla tulee olla käyttäjätunnus.',
    sv: 'Ett användarkonto krävs för att använda tjänsten.',
    en: 'Using this service requires a user account.',
  },
  'login-info-no-account': {
    fi: 'Oletko uusi asiakas?',
    sv: 'Är du en ny kund?',
    en: 'Are you a new customer?',
  },
  'login-info-existing-account': {
    fi: 'Oletko asiakas jolla ei ole tunnuksia?',
    sv: 'Välj Skapa konto om du inte använt tjänsten tidigare.',
    en: "Are you a customer who doesn't have any credentials?",
  },
  'login-info-create-account': {
    fi: 'Luo tili',
    sv: 'Skapa användarkonto',
    en: 'Create an account',
  },
  'login-info-create-account-existing': {
    fi: 'Luo tunnukset',
    sv: 'Skapa konto',
    en: 'Create account',
  },
  'login-info-less-than-minute': {
    fi: 'Siihen menee alle minuutti.',
    sv: 'Det tar bara en minut.',
    en: 'It takes less than a minute.',
  },
  'login-forgot-password': {
    fi: 'Unohditko salasanasi?',
    sv: 'Glömt lösenord?',
    en: 'Did you forget your password?',
  },
  'login-sign-in': {
    fi: 'Kirjaudu sisään',
    sv: 'Logga in',
    en: 'Sign In',
  },
  'login-selection-or': {
    fi: 'Tai',
    sv: 'Eller',
    en: 'Or',
  },
  'login-strong-authentication': {
    fi: 'Suomi.fi - tunnistautuminen',
    sv: 'Suomi.fi - identifiering',
    en: 'Suomi.fi - identification',
  },
  'login-info-box-1-title': {
    fi: 'Oma asiointi',
    sv: 'Eget ärende',
    en: 'My services',
  },
  'login-info-box-1-text': {
    fi: 'Etelä-Suomen kiertotalouden sähköisessä asioinnissa voit hoitaa kiinteistön jätehuoltoon liittyviä asioita päivään, paikkaan ja kellonaikaan katsomatta. Nämä asiat voit hoitaa: \n \n • Tehdä kompostointi-ilmoituksen \n • Tarkistaa tai päivittää laskutusosoitetta ja yhteystietoja \n • Tarkistaa laskujen summia ja eräpäiviä \n • Tarkistaa jäteastioiden tulevia tyhjennysajankohtia \n • Muuttaa jäteastioiden tyhjennysväliä tai tehdä lisätyhjennystilauksen \n • Keskeyttää tyhjennykset määräajaksi \n • Antaa palautetta tai laittaa yhteydenottopyynnön',
    sv: 'I den elektroniska ärendehanteringen för kretslopp i södra Finland kan du sköta fastighetens avfallshantering oberoende av tid och plats. Dessa ärenden kan du sköta: \n \n • Gör en komposteringsanmälan \n • Kontrollera eller uppdatera faktureringsadress och kontaktuppgifter \n • Kontrollera fakturornas belopp och förfallodagar \n • Kontrollera kommande tömningsdatum för avfallskärlen \n • Ändra tömningsintervallen för avfallskärlen eller göra en extra tömningsbeställning \n • Avbryta tömningarna för en bestämd tid \n • Ge feedback eller skicka en kontaktbegäran',
    en: 'In the electronic services of the circular economy in southern Finland, you can take care of waste management issues related to your property regardless of the time and place. You can take care of the following matters: \n \n • Make a composting notification \n • Check or update the billing address and contact information \n • Check the amounts and due dates of the invoices \n • Check the future emptying times of the waste containers \n • Change the emptying intervals of the waste containers or make an additional emptying order \n • Suspend the emptying for a certain period \n • Give feedback or send a contact request',
  },
  'login-info-box-2-title': {
    fi: 'Asiakaspalvelu',
    sv: 'Kundservice',
    en: 'Customer Service',
  },
  'login-info-box-2-text': {
    fi: 'Asiakaspalvelumme tavoitat kotisivuiltamme - löytyvän chatin kautta, puhelimitse numerosta - (arkisin klo 9-15, pvm/mpm) tai sähköpostitse - \n \n Isännöitsijöiden ja muiden taloyhtiöiden jätehuollosta vastaavien henkilöiden tunnukset annetaan asiakaspalvelustamme.',
    sv: 'Du kan nå vår kundservice via chatten som finns på vår hemsida på addressen -, per telefon på nummern - (vardagar kl. 9-15, lna/msa) eller via e-post - \n \n Inloggning för fastighetsförvaltare och andra personer som ansvarar för fastighetens avfallshantering ges av vår kundservice.',
    en: 'You can reach our customer service via the chat on our website -, by phone at - (weekdays 9-15, pvm/mpm) or by email - \n \n Login for property managers and other persons responsible for the property waste management is provided by our customer service.',
  },
  'register-now': {
    fi: 'Rekisteröidy nyt ',
    sv: 'Registrera nu ',
    en: 'Create a new account now ',
  },
  'strong-authentication': {
    fi: 'Kirjaudu sisään suomi.fi-palvelun kautta',
    sv: 'Logga in via tjänsten suomi.fi',
    en: 'Log in via the suomi.fi portal',
  },
  'forgot-pw': {
    fi: 'Unohtuiko salasana?',
    sv: 'Glömt lösenord?',
    en: 'Did you forget your password?',
  },
  'logout-header': {
    fi: 'Uloskirjautuminen',
    sv: 'Utloggning',
    en: 'Sign out',
  },
  'single-logout-success-message': {
    fi: 'Olet kirjautunut onnistuneesti ulos kaikista palveluista.',
    sv: 'Du har loggat ut från alla tjänster.',
    en: 'You have successfully logged out of all services.',
  },
  'login-header-interrupted': {
    fi: 'Kirjautuminen',
    sv: 'Inloggning',
    en: 'Log in',
  },
  'single-login-interrputed': {
    fi: 'Palveluun kirjautuminen on keskeytynyt.',
    sv: 'Inloggningen till tjänsten har avbrutits.',
    en: 'Logging into the service was interrupted.',
  },
  'login-send-feedback': {
    fi: 'Lähetä palautetta',
    sv: 'Ge återkoppling',
    en: 'Send feedback',
  },
  'login-error-dialog-title': {
    fi: 'Kirjautuminen ei onnistunut',
    sv: 'Inloggningen misslyckades',
    en: 'Login failed',
  },
  'login-error-dialog-message': {
    fi: 'Väärä salasana.',
    sv: 'Fel lösenord.',
    en: 'Incorrect password.',
  },

  // Login feedback
  'feedback-header': {
    fi: 'Uusi yhteydenotto',
    sv: 'Ny kontakt',
    en: 'Feedback',
  },
  'feedback-header-description': {
    fi: 'Autamme mielellämme sinua askarruttavissa kysymyksissä.',
    sv: 'Vi hjälper dig gärna med alla dina frågor.',
    en: 'We are happy to help you with any questions you may have.',
  },
  feedback: {
    fi: 'Yhteydenotto',
    sv: 'Kontakt',
    en: 'Contact',
  },
  'feedback-contact-method': {
    fi: 'Haluan että minuun ollaan yhteydessä',
    sv: 'Jag vill bli kontaktad',
    en: 'I would like to be contacted',
  },
  'feedback-contact-method-phone': {
    fi: 'Puhelimitse',
    sv: 'Per telefon',
    en: 'By phone',
  },
  'feedback-contact-method-email': {
    fi: 'Sähköpostitse',
    sv: 'Via e-post',
    en: 'By e-mail',
  },
  'feedback-contact-method-none': {
    fi: 'En halua yhteydenottoa',
    sv: 'Jag vill inte bli kontaktad',
    en: 'I do not want to be contacted',
  },
  'feedback-message-header': {
    fi: 'Yhteydenoton aihe',
    sv: 'Ämne',
    en: 'Subject',
  },
  'feedback-message': {
    fi: 'Viesti',
    sv: 'Meddelande',
    en: 'Message',
  },
  'feedback-message-category': {
    fi: 'Viesti kategoria',
    sv: 'Meddelandekategori',
    en: 'Message category',
  },
  'feedback-send-button': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },
  'feedback-email': {
    fi: 'Sähkoposti',
    sv: 'E-post',
    en: 'E-mail',
  },
  'feedback-phone': {
    fi: 'Puhelin',
    sv: 'Telefon',
    en: 'Phone',
  },
  'feedback-sent-message': {
    fi: 'Palaute on vastaanotettu',
    sv: 'Återkoppling har mottagits',
    en: 'We have received your feedback',
  },

  // Customer Service
  'customer-service-header': {
    fi: 'Asiakaspalvelu',
    sv: 'Kundservice',
    en: 'Customer Service',
  },
  'customer-service-header-description': {
    fi: 'Tämän ohjetekstin voi muuttaa.',
    sv: 'Denna guidetext kan ändras.',
    en: 'This guide text can be changed.',
  },
  'customer-service-contact-us-title': {
    fi: 'Ota meihin yhteyttä',
    sv: 'Kontakta oss',
    en: 'Contact us',
  },
  'customer-service-emptying-location-placeholder': {
    fi: 'Valitse kohde, jota yhteydenotto koskettaa',
    sv: 'Välj det objekt som kontakten gäller',
    en: 'Select the emptying location to which the contact applies',
  },
  'customer-service-subject-placeholder': {
    fi: 'Valitse yhteydenoton aihe',
    sv: 'Välj ämnet för kontakten',
    en: 'Select the subject of the contact',
  },
  'customer-service-message-placeholder': {
    fi: 'Viesti',
    sv: 'Meddelande',
    en: 'Message',
  },

  'customer-service-contact-option-placeholder': {
    fi: 'Haluan, että minuun ollaan yhteydessä',
    sv: 'Jag vill bli kontaktad',
    en: 'I want to be contacted',
  },

  'customer-service-contact-option-phone-value': {
    fi: 'Puhelimitse',
    sv: 'via telefon',
    en: 'by phone',
  },

  'customer-service-contact-option-phone-placeholder': {
    fi: 'Puhelin',
    en: 'Phone',
    sv: 'Telefon',
  },
  'customer-service-contact-option-email-value': {
    fi: 'Sähköpostitse',
    en: 'by email',
    sv: 'via e-post',
  },
  'customer-service-contact-option-email-placeholder': {
    fi: 'Sähköposti',
    en: 'E-mail',
    sv: 'E-post',
  },

  'cusomer-service-contact-message': {
    fi: 'Haluan, että minuun ollaan yhteydessä',
    en: 'I want to be contacted',
    sv: 'Jag vill bli kontaktad',
  },

  'customer-service-contact-options-no-contact-value': {
    fi: 'En halua yhteydenottoa',
    en: 'I don’t want to be contacted',
    sv: 'Jag vill inte bli kontaktad',
  },

  'customer-service-send-message': {
    fi: 'Lähetä',
    en: 'Send',
    sv: 'Skicka',
  },
  'customer-service-confirmation-title': {
    en: 'Task succeeded',
    fi: 'Tehtävä onnistui',
    sv: 'Uppgiften lyckades',
  },

  'customer-service-confirmation-message': {
    en: 'We have received your feedback',
    fi: 'Palaute vastaanotettu',
    sv: 'Återkoppling har mottagits',
  },

  'feedback-emptying-info': {
    fi: 'Kohde, jota palaute koskee',
    sv: 'Ärende som återkopplingen gäller',
    en: 'The topic of this feedback',
  },

  'feedback-table-header-subject': {
    fi: 'Aihe',
    sv: 'Ämnet',
    en: 'Subject',
  },

  'feedback-table-header-message': {
    fi: 'Viesti',
    sv: 'Meddelande',
    en: 'Message',
  },

  'feedback-table-header-location': {
    fi: 'Kohde',
    en: 'Location',
    sv: 'Plats',
  },

  'feedback-table-header-date': {
    fi: 'pvm',
    sv: 'Datum',
    en: 'Date',
  },

  // Register wizard
  'register-wizard-header': {
    fi: 'Asiakkuuden rekisteröinti',
    sv: 'Registrering av kundrelation',
    en: 'Registering a customer account',
  },
  'select-register-type': {
    fi: 'Valitse asiakkuus',
    sv: 'Välj kundrelation',
    en: 'Select a customer account',
  },
  'select-register-i-am': {
    fi: 'Olen...',
    sv: 'Jag är...',
    en: 'I am...',
  },
  'complete-registration': {
    fi: 'Täydennä rekisteröinti',
    sv: 'Komplettera registreringen',
    en: 'Complete registration',
  },

  // Register page
  'create-user': {
    fi: 'Luo käyttäjätunnus',
    sv: 'Skapa ett användarnamn',
    en: 'Create a new user account',
  },
  'create-account': {
    fi: 'Luo tili',
    sv: 'Skapa användarkonto',
    en: 'Create an account',
  },
  'register-description': {
    fi: 'Rekisteröityessä tunnistaudut ensimmäistä kertaa ja sinulle luodaan oma käyttäjätili.',
    sv: 'När du registrerar dig kommer du att identifieras för första gången och ett separat användarkonto skapas åt dig.',
    en: 'During the registration process, your identity will be authenticated for the first time, and an user account will be created for you.',
  },
  'register-new-account-description': {
    fi: 'Luo tili uutena asiakkaana täyttämällä alla olevat tiedot.',
    sv: 'Skapa ett konto som ny kund genom att fylla i uppgifterna nedan.',
    en: 'Create account as a new customer by filling fields below.',
  },
  'register-existing-account-description': {
    fi: 'Luo itsellesi tunnukset täyttämällä alla olevat tiedot.',
    sv: 'Skapa ett konto genom att fylla i uppgifterna nedan.',
    en: 'Create account by filling fields below.',
  },
  'register-customership-information': {
    fi: 'Asiakkuustiedot',
    sv: 'Kontouppgifter',
    en: 'Customership information',
  },
  'register-customership-information-description': {
    fi: 'Löydät lasku- ja asiakasnumeron sinulle toimitetusta laskusta.',
    sv: 'Du hittar faktura- och kundnummern på den faktura som har skickats till dig.',
    en: 'You can find the invoice and customer number on the invoice that has been sent to you.',
  },
  'customer-type-text': {
    fi: 'Olen...',
    sv: 'Jag är ...',
    en: 'I am...',
  },
  'private-customer': {
    fi: 'Yksityisasiakas',
    sv: 'Privatkund',
    en: 'A private customer',
  },
  'company-or-community': {
    fi: 'Yritys tai Yhteisö',
    sv: 'Företag eller organisation',
    en: 'A company or an organisation',
  },
  'old-customer': {
    fi: 'Jo asiakkaana',
    sv: 'Redan kund',
    en: 'Already a customer',
  },
  'old-customer-ssn': {
    fi: 'Jo asiakkaana, etsi henkilötiedoilla asiakkuutta',
    sv: 'Redan kund, sök kundrelation med personuppgifter',
    en: 'Already a customer, search for your account using personal details',
  },
  'new-customer': {
    fi: 'Uusi asiakas',
    sv: 'Ny kund',
    en: 'New customer',
  },
  'basic-info': {
    fi: 'Perustiedot',
    sv: 'Grunduppgifter',
    en: 'Key details',
  },
  'basic-info-company': {
    fi: 'Yritys',
    sv: 'Företag',
    en: 'Company',
  },
  'basic-info-modify': {
    fi: 'Tilin tiedot',
    sv: 'Kunduppgifter',
    en: 'Account information',
  },
  'basic-info-username': {
    fi: 'Käyttäjätunnus',
    sv: 'Användarnamn',
    en: 'Username',
  },
  'basic-info-name': {
    fi: 'Nimi',
    sv: 'Namn',
    en: 'Name',
  },
  'basic-info-first-name': {
    fi: 'Etunimi',
    sv: 'Förnamn',
    en: 'First name',
  },
  'basic-info-last-name': {
    fi: 'Sukunimi',
    sv: 'Efternamn',
    en: 'Last name',
  },
  'basic-info-customer-number': {
    fi: 'Asiakasnumero',
    sv: 'Kundnummer',
    en: 'Customer number',
  },
  'basic-info-invoice-number': {
    fi: 'Laskun numero',
    sv: 'Fakturanummer',
    en: 'Invoice number',
  },
  'basic-info-phone': {
    fi: 'Puhelinnumero',
    sv: 'Telefonnummer',
    en: 'Phone number',
  },
  'basic-info-email': {
    fi: 'Sähköposti',
    sv: 'E-post',
    en: 'E-mail',
  },
  'basic-info-ssn': {
    fi: 'Henkilötunnus',
    sv: 'Personbeteckning',
    en: 'Personal identity code',
  },
  'basic-info-company-id': {
    fi: 'Y-tunnus',
    sv: 'FO-nummer',
    en: 'Business ID',
  },
  'basic-info-customer-name': {
    fi: 'Yrityksen nimi',
    sv: 'Företagets namn',
    en: 'Company name',
  },
  'basic-info-language': {
    fi: 'Oletuskieli',
    sv: 'Standardspråk',
    en: 'Default language',
  },
  'username-and-pw': {
    fi: 'Käyttäjätunnus ja salasana',
    sv: 'Användarnamn och lösenord',
    en: 'Username and password',
  },
  'pw-info': {
    fi: 'Varmista, että salasanasi on vähintään 12 merkkiä.',
    sv: 'Säkerställ att ditt lösenord är minst 12 tecken långt.',
    en: 'Please ensure that your password is 12 characters at minimum.',
  },
  'password-minimum12': {
    fi: 'Salasana, jossa vähintään 12 merkkiä.',
    sv: 'Lösenord, med minst 12 tecken.',
    en: 'Password, with at least 12 characters.',
  },
  'confirm-pw': {
    fi: 'Vahvista salasana',
    sv: 'Bekräfta lösenord',
    en: 'Confirm your password',
  },
  'billing-data': {
    fi: 'Laskutustiedot',
    sv: 'Faktureringsupgifter',
    en: 'Billing information',
  },
  'billing-data-edit': {
    fi: 'Muokkaa laskutustietoja',
    sv: 'Redigera faktureringsupgifter',
    en: 'Edit billing information',
  },
  'billing-data-save': {
    fi: 'Tallenna',
    sv: 'Spara',
    en: 'Save',
  },
  'billing-data-save-cancel': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },
  'billing-data-street-address': {
    fi: 'Katuosoite',
    sv: 'Gatuadress',
    en: 'Street address',
  },
  'billing-data-post-code': {
    fi: 'Postinumero',
    sv: 'Postnummer',
    en: 'Postal code',
  },
  'billing-data-city': {
    fi: 'Postitoimipaikka',
    sv: 'Postanstalt',
    en: 'Postal address',
  },
  'billing-data-country': {
    fi: 'Maa',
    sv: 'Land',
    en: 'Country',
  },
  'billing-data-billing-contact-person': {
    fi: 'Laskutuksen yhteyshenkilö',
    sv: 'Fakturerings kontaktperson',
    en: 'Billing contact person',
  },
  'billing-data-billing-contact-person-use-same-data': {
    fi: 'Käytä samoja tietoja',
    en: ' Use same details',
    sv: 'Använd samma detaljer',
  },
  'billing-data-billing-contact-person-firstname': {
    fi: 'Etunimi',
    sv: 'Förnamn',
    en: 'First name',
  },
  'billing-data-billing-contact-person-lastname': {
    fi: 'Sukunimi',
    sv: 'Efternamn',
    en: 'Last name',
  },
  'billing-data-billing-contact-person-phonenumber': {
    fi: 'Puhelinnumero',
    sv: 'Telefonnummer',
    en: 'Phone number',
  },
  'billing-data-billing-contact-person-email': {
    fi: 'Sähköposti',
    sv: 'Email',
    en: 'Email',
  },
  'billing-data-billing-method': {
    fi: 'Laskutustapa',
    sv: 'Faktureringssätt',
    en: 'Billing method',
  },
  'billing-data-billing-method-question': {
    fi: 'Haluan saada laskut',
    sv: 'Jag vill ta emot fakturor',
    en: 'I want to receive invoices',
  },
  'billing-data-billing-method-paper': {
    fi: 'Paperilaskuna',
    sv: 'Som pappersfaktura',
    en: 'As a paper bill',
  },
  'billing-data-billing-method-email': {
    fi: 'Sähköpostilla',
    sv: 'Med e-post',
    en: 'By email',
  },
  'billing-data-billing-method-e-invoice': {
    fi: 'Verkkolaskuna',
    sv: 'Online faktura',
    en: 'Online invoice',
  },
  'billing-data-customership-info': {
    fi: 'Asiakkuustiedot',
    sv: 'Kundskap information',
    en: 'Customership information',
  },
  'paper-bill': {
    fi: 'Haluan laskut postilla',
    sv: 'Jag vill ha fakturor med posten',
    en: 'I want my bills to be delivered by post',
  },
  'email-bill': {
    fi: 'Haluan saada laskut sähköpostilla',
    sv: 'Jag vill ha fakturor till min e-post',
    en: 'I want my bills to be delivered via e-mail',
  },
  'email-bill-summary': {
    fi: 'Laskut sähköpostiin',
    sv: 'Fakturor till e-post',
    en: 'Bills to e-mail',
  },
  'e-bill': {
    fi: 'Haluan laskut verkkolaskuina',
    sv: 'Jag vill ha fakturor som e-fakturor',
    en: 'I want my bills to be delivered as e-invoices',
  },
  'e-bill-private': {
    fi: 'E-lasku (E-laskun muutokset täytyy tehdä pankissa)',
    sv: 'E-faktura (E-faktura förändringar ska göras i banken)',
    en: 'E-invoice (E-invoice changes should be made at the bank)',
  },
  'e-direct-payment': {
    fi: 'Suoramaksu (Suoramaksu muutokset täytyy tehdä pankissa)',
    sv: 'Direktbetalning (Direktbetalnings förändringar ska göras i banken)',
    en: 'Direct payment (Direct payment changes should be made at the bank)',
  },
  'i-have-read': {
    fi: 'Olen lukenut',
    sv: 'Jag har läst',
    en: 'I have read',
  },

  conditions: {
    fi: 'käyttäjäehdot',
    sv: 'användarvillkoren',
    en: 'the Terms of Use',
  },

  'contract-conditions': {
    fi: 'sopimusehdot',
    sv: 'villkoren',
    en: 'the terms and conditions',
  },

  'terms-of-service-title': {
    fi: 'Sopimusehdot',
    sv: 'Avtalsvillkoren',
    en: 'Terms and conditions',
  },

  'terms-of-service-text': {
    fi: '',
    sv: '',
    en: '',
  },

  'terms-of-service-link': {
    fi: 'sopimusehdot',
    sv: 'avtallsvillkoren',
    en: 'the terms of service',
  },

  'terms-of-use-title': {
    fi: 'Käyttäjäehdot',
    sv: 'Användarvillkoren',
    en: 'Terms of Use',
  },

  'terms-of-use-text': {
    fi: '',
    sv: '',
    en: '',
  },

  register: {
    fi: 'Rekisteröidy',
    sv: 'Registrera dig',
    en: 'Register',
  },
  'contact-person': {
    fi: 'Yhteyshenkilö',
    sv: 'Kontaktperson',
    en: 'Contact person',
  },

  'billing-email': {
    fi: 'Sähköposti',
    sv: 'E-post',
    en: 'E-mail',
  },

  'e-bill-address': {
    fi: 'Verkkolaskuosoite',
    sv: 'Nätfakturaadress',
    en: 'E-invoicing address',
  },

  broker: {
    fi: 'Välittäjä',
    sv: 'Förmedlare',
    en: 'Broker',
  },
  ovt: {
    fi: 'OVT-tunnus',
    sv: 'EDI-kod',
    en: 'EDI ID',
  },

  // Forgot username
  'forgot-username-header': {
    fi: 'Unohtuiko käyttäjätunnus?',
    sv: 'Glömde du användarnamnet?',
    en: 'Did you forget your username?',
  },
  'forgot-username-description': {
    fi: 'Syötä sähköpostiosoite, jota käytit rekisteröityessä, niin sinulle lähetetään käyttäjätiedot.',
    sv: 'Ange den e-postadress du använde vid registreringen, så kommer användaruppgifterna att skickas till dig.',
    en: 'Please enter the e-mail address that you provided during registration, and your user details will be sent to you.',
  },
  'forgot-username-form-header': {
    fi: 'Käyttäjätunnuksen muistutus',
    sv: 'Påminnelse om användarnamn',
    en: 'Username reminder',
  },
  'forgot-username-return-to-login-link': {
    fi: 'Palaa sisäänkirjautumiseen',
    sv: 'Återgå till inloggning',
    en: 'Return to logging in',
  },
  'forgot-username-send-button': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },
  'forgot-username-ok-message': {
    fi: 'Sähköpostiin lähettiin käyttäjätunnuksen tiedot, mikäli sellaiset löytyivät.',
    sv: 'Användaruppgifterna skickades till e-posten om de fanns.',
    en: 'The username details were sent to the email if they were found.',
  },

  // Forgot password
  'forgot-password-header': {
    fi: 'Unohtuiko salasana?',
    sv: 'Glömt lösenord?',
    en: 'Forgot your password?',
  },
  'forgot-password-description': {
    fi: 'Syötä sähköpostiosoite, jota käytit rekisteröityessä, niin sinulle lähetetään ohjeet kuinka voit vaihtaa salasanan.',
    sv: 'Ange din e-postadress som du har registrerat dig med så skickar vi information om hur du återställer ditt lösenord',
    en: 'Please enter the e-mail address that you provided during registration, and you will receive instructions on how to reset your password',
  },
  'forgot-password-form-header': {
    fi: 'Unohditko salasanasi?',
    sv: 'Glömt lösenord?',
    en: 'Did you forget your password?',
  },
  'forgot-password-forgot-username': {
    fi: 'Unohtuiko käyttäjätunnus?',
    sv: 'Glömde du användarnamnet?',
    en: 'Did you forget your username?',
  },
  'forgot-password-return-to-login-link': {
    fi: 'Palaa sisäänkirjautumiseen',
    sv: 'Återgå till inloggning',
    en: 'Return to login',
  },
  'forgot-password-submit-button': {
    fi: 'Tilaa uusi salasana',
    sv: 'Beställ ett nytt lösenord',
    en: 'Order a new password',
  },
  'forgot-password-ok-message': {
    fi: 'Sähköpostiisi lähetettiin ohjeet uuden salasanan asettamiseen.',
    sv: 'Instruktioner för att ställa in ett nytt lösenord har skickats till din e-post.',
    en: 'We have sent you an e-mail with instructions for resetting your password.',
  },
  'forgot-password-failed-message': {
    fi: 'Tarkista käyttäjätunnus ja sähköpostiosoite.',
    sv: 'Tarkista käyttäjätunnus ja sähköpostiosoite.',
    en: 'Tarkista käyttäjätunnus ja sähköpostiosoite.',
  },
  'forgot-password-account-not-valid-title': {
    fi: 'Käyttäjää ei löydy',
    sv: 'Användaren hittades inte',
    en: 'User not found',
  },
  'forgot-password-account-not-valid-message': {
    fi: 'Annettu sähköposti ja käyttäjätunnus ei vastaa yhtään käyttäjää.',
    sv: 'Den angivna e-postadressen och användarnamnet motsvarar inte någon användare.',
    en: 'The given email and username do not match any user.',
  },

  // Reset Password
  'reset-password-header': {
    fi: 'Uusi salasana',
    sv: 'Nytt lösenord',
    en: 'New password',
  },
  'reset-password-form-header': {
    fi: 'Vaihda salasana',
    sv: 'Byt lösenord',
    en: 'Change your password',
  },
  'reset-password-description': {
    fi: 'Syötä uusi salasana',
    sv: 'Ange ett nytt lösenord',
    en: 'Enter a new password',
  },
  'reset-password-ok-message': {
    fi: 'Tilin salasana on vaihdettu.',
    sv: 'Kontots lösenord har bytts',
    en: 'The password for this account was changed.',
  },
  'reset-password-identity-failed-message': {
    fi: 'Salanan vaihtamisen linkki on vanhentunut. Pyydä salasanan vaihtamista uudestaan.',
    sv: 'Salanan vaihtamisen linkki on vanhentunut. Pyydä salasanan vaihtamista uudestaan.',
    en: 'Salanan vaihtamisen linkki on vanhentunut. Pyydä salasanan vaihtamista uudestaan.',
  },
  'reset-password-identity-failed-header': {
    fi: 'Linkki on vanhentunut',
    sv: 'Linkki on vanhentunut',
    en: 'Linkki on vanhentunut',
  },
  'reset-password-send-button': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },

  //Account settings
  'account-settings': {
    fi: 'Tilin asetukset',
    sv: 'Kontoinställningar',
    en: 'Account settings',
  },
  'account-settings-user-info-title': {
    en: 'User information',
    fi: 'Käyttäjätiedot',
    sv: 'Användaruppgifter',
  },
  'account-settings-user-info-desc': {
    en: 'Enter your personal and contact information',
    fi: 'Anna henkilö- ja yhteystietosi',
    sv: 'Ange dina personuppgifter och kontaktuppgifter',
  },
  'account-settings-edit-user-info-header': {
    en: 'User information',
    fi: 'Käyttäjätiedot',
    sv: 'Användaruppgifter',
  },
  'account-settings-edit-user-info-guide-text': {
    en: 'Enter your personal and contact information',
    fi: 'Anna henkilö- ja yhteystietosi',
    sv: 'Ange dina personuppgifter och kontaktuppgifter',
  },
  'account-settings-edit-user-info-form-header': {
    en: 'Key details',
    fi: 'Perustiedot',
    sv: 'Grunduppgifter',
  },
  'account-settings-login-info-title': {
    en: 'Login',
    fi: 'Sisäänkirjautuminen',
    sv: 'Logga in',
  },
  'account-settings-login-info-desc': {
    en: 'Update your password and protect your account',
    fi: 'Päivitä salasanasi ja suojaa tilisi',
    sv: 'Uppdatera ditt lösenord och skydda ditt konto',
  },
  'account-settings-edit-login-info-header': {
    en: 'Login',
    fi: 'Sisäänkirjautuminen',
    sv: 'Logga in',
  },
  'account-settings-edit-login-info-guide-text': {
    en: 'Update your password and protect your account',
    fi: 'Päivitä salasanasi ja suojaa tilisi',
    sv: 'Uppdatera ditt lösenord och skydda ditt konto',
  },
  'account-settings-edit-login-info-form-header': {
    en: 'Change your password',
    fi: 'Vaihda salasana',
    sv: 'Byt lösenord',
  },
  'account-settings-billing-info-title': {
    en: 'Billing information',
    fi: 'Laskutustiedot',
    sv: 'Faktureringsinformation',
  },
  'account-settings-billing-info-desc': {
    en: 'Manage your billing information',
    fi: 'Hallinnoi laskutustietojasi',
    sv: 'Hantera din faktureringsinformation',
  },
  'account-settings-general-info-title': {
    en: 'General settings',
    fi: 'Yleisasetukset',
    sv: 'Allmänna inställningar',
  },
  'account-settings-general-info-desc': {
    en: 'Define default language',
    fi: 'Määrittele oletuskieli',
    sv: 'Definiera standardspråk',
  },
  'account-settings-edit-general-info-header': {
    en: 'General settings',
    fi: 'Yleisasetukset',
    sv: 'Allmänna inställningar',
  },
  'account-settings-edit-general-info-guide-text': {
    en: 'Define e.g. default language',
    fi: 'Määrittele mm. oletuskieli',
    sv: 'Definiera standardspråk',
  },
  'account-settings-edit-general-info-form-header': {
    en: 'Default language',
    fi: 'Oletuskieli',
    sv: 'Standardspråk',
  },
  'account-settings-edit-general-info-form-options-header': {
    en: 'Select default language',
    fi: 'Valitse oletuskieli',
    sv: 'Välj standardspråk',
  },

  // Emptying infos
  'search-bar-label': {
    fi: 'Hae kohdetta',
    sv: 'Sök efter plats',
    en: 'Search for a location',
  },
  'emptying-infos': {
    fi: 'Omat kohteet',
    sv: 'Egna platser',
    en: 'My locations',
  },
  'emptying-infos-desc': {
    fi: 'Löydät hallitsemasi omat kohteet tarpeittesi mukaan täältä.',
    sv: 'Du hittar dina egna platser efter dina behov här.',
    en: 'You can find your own managed locations here, if needed.',
  },
  'your-climate-level': {
    fi: 'Ilmastotasosi',
    sv: 'Din klimatnivå',
    en: 'Your climate level',
  },
  'your-climate-level-desc': {
    fi: 'Kierrätät jo nyt erinomaisesti. Voit kuitenkin vielä kompensoida päästösi.',
    sv: 'Du återvinner redan mycket bra. Du kan dock fortfarande kompensera för dina utsläpp.',
    en: 'You are already doing an excellent job with recycling. You could, however, still compensate for your emissions.',
  },

  'your-climate-level-more-info': {
    fi: 'Tutustu tarkemmin',
    sv: 'Läs mer',
    en: 'Learn more',
  },
  'add-emptying-info-link': {
    fi: 'Lisää kohde',
    sv: 'Lägg till plats',
    en: 'Add location',
  },
  'add-billing-info-link': {
    fi: 'Lisää laskutustiedot',
    sv: 'Lägg till faktureringsuppgifter',
    en: 'Add billing information',
  },
  'emptying-locations-no-emptyings-found-header': {
    fi: 'Ei kohteita',
    sv: 'Inga platser',
    en: 'No locations',
  },
  'emptying-locations-no-emptyings-found-description': {
    fi: 'Alla olevasta painikkeesta voi lisätä ensimmäisen kohteen.',
    sv: 'Från knappen under kan man tillägga första platsen.',
    en: 'From the button below, the first location can be added.',
  },

  // Billing info
  'billing-info-header': {
    fi: 'Lisää laskutustiedot',
    sv: 'Lägg till faktureringsuppgifter',
    en: 'Add billing information',
  },
  'billing-info-header-desc': {
    fi: '',
    sv: '',
    en: '',
  },
  'billing-info-existing-selector': {
    fi: 'Laskutustiedot',
    sv: 'Faktureringsupgifter',
    en: 'Billing information',
  },
  'billing-info-existing-selector-dropdown-label': {
    fi: 'Valitse laskutustieto',
    sv: 'Välj faktureringsuppgift',
    en: 'Choose billing information',
  },
  'billing-info-existing-exists': {
    fi: 'Käytä olemassa olevaa',
    sv: 'Använd befintlig',
    en: 'Use existing',
  },
  'billing-info-existing-new': {
    fi: 'Luo uusi laskutustieto',
    sv: 'Skapa ny faktureringsuppgift',
    en: 'Create new billing information',
  },
  'billing-info-type-selector': {
    fi: 'Asiakkuustyyppi',
    sv: 'Typ av kund',
    en: 'Customer type',
  },
  'billing-info-type-private': {
    fi: 'Yksityisasiakas',
    sv: 'Privatkund',
    en: 'Private customer',
  },
  'billing-info-type-company': {
    fi: 'Yritysasiakas',
    sv: 'Företagskund',
    en: 'Company customer',
  },
  'billing-info-send': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },
  'billing-info-invoicing-type-title': {
    fi: 'Laskutustapa',
    sv: 'Fakturering',
    en: 'Invoicing type',
  },
  'billing-info-invoicing-type-e-invoicing': {
    fi: 'E-lasku',
    sv: 'E-fakturering',
    en: 'E-invoicing',
  },
  'billing-info-invoicing-type-direct-payment': {
    fi: 'Suoramaksu',
    sv: 'Direkt Betalning',
    en: 'Direct payment',
  },
  'billing-info-invoicing-type-kivra': {
    fi: 'Kivra',
    sv: 'Kivra',
    en: 'Kivra',
  },

  // Emptying account
  'emptying-info-header': {
    fi: 'Lisää uusi tyhjennyskohde',
    sv: 'Lägg till en ny tömningsplats',
    en: 'Add a new emptying location',
  },
  'emptying-info-header-desc': {
    fi: '',
    sv: '',
    en: '',
  },
  'emptying-info-type-selector': {
    fi: 'Tyhjennyskohdetyyppi',
    sv: 'Tömningsplatstyp',
    en: 'Emptying location type',
  },
  'emptying-info-copy-billing-info': {
    fi: 'Kopioi laskustustiedot',
    sv: 'Kopiera faktureringsinformation',
    en: 'Copy billing information',
  },
  'emptying-info-send': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },
  'emptying-info-send-success-title': {
    fi: 'Onnistui',
    sv: 'Lyckades',
    en: 'Success!',
  },
  'emptying-info-send-success-message': {
    fi: 'Uuden tyhjennyskohteen luonti onnistui.',
    sv: 'En ny tömningsplats har skapats.',
    en: 'Successfully created a new emptying location.',
  },

  // Emptying address
  'emptying-address-order': {
    fi: 'Tilaa palvelu',
    sv: 'Beställa tjänst',
    en: 'Order a service',
  },
  'emptying-address-edit': {
    fi: 'Muokkaa tietoja',
    sv: 'Redigera information',
    en: 'Edit information',
  },
  'emptying-address-billing-edit': {
    fi: 'Muokkaa',
    sv: 'Redigera',
    en: 'Edit',
  },
  'emptying-address-emptying-edit': {
    fi: 'Muokkaa tyhjennyskohteen tietoja',
    sv: 'Redigera tömningsplatsens information',
    en: 'Edit emptying locations information',
  },
  'emptying-address-edit-contact-person': {
    fi: 'Muokkaa kohteen yhteyshenkilöä',
    sv: 'Redigera kontaktpersonens uppgifter',
    en: 'Edit locations contact person',
  },
  'emptying-address-contract-history': {
    fi: 'Tilaushistoria',
    sv: 'Beställningshistorik',
    en: 'Order history',
  },
  'emptying-address-billing-address': {
    fi: 'Laskutusosoite',
    sv: 'Faktureringsadress',
    en: 'Billing address',
  },

  //Phase Bar

  'phase-bar-step': {
    fi: 'Vaihe',
    sv: 'Steg',
    en: 'Step',
  },

  //Emptying location order product dialog
  'emptying-location-order-product': {
    en: 'Order product',
    fi: 'Tilaa palvelu',
    sv: 'Beställ en tjänst',
  },
  'emptying-location-order-product-header': {
    en: 'Order product',
    fi: 'Tilaa palvelu',
    sv: 'Beställ en tjänst',
  },
  'emptying-location-order-product-select-phase': {
    en: 'Choose service',
    fi: 'Valitse palvelu',
    sv: 'Välj en tjänst',
  },
  'emptying-location-order-product-select-phase-header': {
    en: 'Select service',
    fi: 'Valitse palvelu',
    sv: 'Välj en tjänst',
  },
  'emptying-location-order-product-select-phase-header-no-services': {
    en: 'No services available',
    fi: 'Ei valittavia palveluja',
    sv: 'Inga tjänster tillgängliga',
  },
  'emptying-location-order-product-finish-phase': {
    en: 'Complete service',
    fi: 'Viimeistele tilaus',
    sv: 'Slutför tjänsten',
  },
  'emptying-location-order-product-summary-phase': {
    en: 'Summary',
    fi: 'Yhteenveto',
    sv: 'Sammanfattning',
  },

  'emptying-location-order-product-category-header': {
    en: 'Select service',
    fi: 'Valitse palvelu',
    sv: 'Välj en tjänst',
  },
  'emptying-location-order-product-no-categories-header': {
    en: 'No services available',
    fi: 'Ei valittavia palveluja',
    sv: 'Inga tjänster tillgängliga',
  },
  'emptying-location-order-product-category-contract': {
    en: 'Valid for the time being',
    fi: 'Toistaiseksi voimassaoleva',
    sv: 'Gäller tills vidare',
  },
  'emptying-location-order-product-category-order': {
    en: 'One time order',
    fi: 'Kertatilaus',
    sv: 'Engångsbeställning',
  },

  // Emptying location (new layout)
  'emptying-location-order-new-service': {
    fi: 'Tilaa palvelu',
    sv: 'Beställa tjänst',
    en: 'Order a service',
  },
  'emptying-location-service-list-service': {
    fi: 'palvelu',
    sv: 'tjänst',
    en: 'service',
  },
  'emptying-location-service-list-service-plural': {
    fi: 'palvelua',
    sv: 'tjänster',
    en: 'services',
  },
  'emptying-location-service-list-order': {
    en: 'order',
    fi: 'tilaus',
    sv: 'beställning',
  },
  'emptying-location-service-list-order-plural': {
    en: 'orders',
    fi: 'tilausta',
    sv: 'beställningar',
  },
  'emptying-location-contract-next-collection': {
    fi: 'Seuraava tyhjennys',
    sv: 'Följande tömning',
    en: 'Next collection',
  },
  'emptying-location-contract-amount': {
    fi: 'Astioiden määrä',
    sv: 'Antal',
    en: 'Bin quantity',
  },
  'emptying-location-contract-amount-pcs': {
    fi: 'kpl',
    sv: 'st',
    en: 'pcs',
  },
  'emptying-location-contract-interval': {
    fi: 'Tyhjennysväli',
    sv: 'Tömningsintervall',
    en: 'Emptying interval',
  },
  'emptying-location-contract-waiting-emptying-rhythm': {
    fi: 'Tyhjennysrytmi',
    sv: 'Tömningsintervall',
    en: 'Emptying rhythm',
  },
  'emptying-location-contract-price-pcs': {
    fi: 'kpl',
    sv: 'st',
    en: 'pcs',
  },
  'emptying-location-contract-price-ton': {
    fi: 'tonni',
    sv: 'ton',
    en: 'ton',
  },
  'emptying-location-contract-price-bin-emptying': {
    fi: 'Astioiden tyhjennys',
    sv: 'Kärl tömning',
    en: 'Bin emptying',
  },
  'emptying-location-contract-price-waste-disposal': {
    fi: 'Jätteenkäsittely',
    sv: 'Avfallshantering',
    en: 'Waste disposal',
  },
  'emptying-location-contract-price-total': {
    fi: 'Hinta yhteensä',
    sv: 'Totalt pris',
    en: 'Total price',
  },
  'emptying-location-contract-information': {
    fi: 'Palvelun tiedot',
    sv: 'Tjänstens information',
    en: 'Service information',
  },
  'emptying-location-contract-waiting-approve': {
    fi: 'Hyväksy',
    sv: 'Acceptera',
    en: 'Approve',
  },

  'emptying-location-orders-date-range': {
    fi: 'Haetaan ajalta',
    sv: 'Intervall',
    en: 'Interval',
  },
  'emptying-location-order-date': {
    fi: 'Tilattu',
    sv: 'Orderdatum',
    en: 'Order date',
  },
  'emptying-location-order-requested-date': {
    fi: 'Toivottu aika',
    sv: 'Begärd tid',
    en: 'Preferred time',
  },
  'emptying-location-order-info': {
    fi: 'Tilaus',
    sv: 'Beställning',
    en: 'Order',
  },
  'emptying-location-order-status-received': {
    fi: 'Vastaanotettu',
    sv: 'Mottagits',
    en: 'Received',
  },
  'emptying-location-order-status-processed': {
    fi: 'Käsittelyssä',
    sv: 'På behandling',
    en: 'To be processed',
  },
  'emptying-location-order-status-done': {
    fi: 'Valmis',
    sv: 'Färdig',
    en: 'Done',
  },
  'emptying-location-billing-data': {
    fi: 'Laskutustiedot',
    sv: 'Faktureringsupgifter',
    en: 'Billing information',
  },
  'emptying-location-billing-contact-person': {
    fi: 'Laskutuksen yhteyshenkilö',
    sv: 'Fakturerings kontaktperson',
    en: 'Billing contact person',
  },
  'emptying-location-contact-person': {
    fi: 'Kohteen yhteyshenkilö',
    sv: 'Tömningsplatsens kontaktperson',
    en: 'Location contact person',
  },
  'emptying-location-approve-contract-dialog-title': {
    fi: 'Hyväksy palvelu',
    sv: 'Acceptera kontrakt',
    en: 'Approve contract',
  },
  'emptying-location-approve-contract-dialog-text': {
    fi: 'Voit muuttaa tietoja hyväksynnän jälkeen. Löydät hyväksytyn palvelun Jatkuvat palvelut listalta.',
    sv: 'Du kan ändra information efter godkännande. Du kan se godkännande kontraktet i listan Löpande tjänster.',
    en: 'You can change contract information after approval. You can see approved contract in Continuous contracts list.',
  },
  'emptying-location-approve-contract-dialog-approve-button': {
    fi: 'Hyväksy',
    sv: 'Acceptera',
    en: 'Approve',
  },
  'emptying-location-approve-contract-dialog-cancel-button': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },
  'emptying-location-reject-contract-dialog-title': {
    fi: 'Hylkää',
    sv: 'Avvisa',
    en: 'Reject',
  },
  'emptying-location-reject-contract-dialog-text': {
    fi: 'Haluatko hylätä sopimuksen?',
    sv: 'Vill du avvisa kontrakten?',
    en: 'Do you want to reject the contract?',
  },
  'emptying-location-reject-contract-dialog-approve-button': {
    fi: 'Kyllä',
    sv: 'Ja',
    en: 'Yes',
  },
  'emptying-location-reject-contract-dialog-cancel-button': {
    fi: 'En',
    sv: 'Nej',
    en: 'No',
  },

  // Modify emptying location info
  'modify-emptying-location-header': {
    fi: 'Päivitä kohteen tiedot',
    sv: 'Redigera tömningsplatsens information',
    en: 'Update location info',
  },

  'modify-emptying-info-bin-location-contact': {
    fi: 'Astian sijainnin yhteyshenkilö',
    sv: 'Kontaktperson för kärlplats',
    en: 'Bin location contact',
  },

  'modify-emptying-location-save': {
    fi: 'Tallenna',
    sv: 'Spara',
    en: 'Save',
  },
  'modify-emptying-location-cancel': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },

  // Modify billing info
  'modify-billing-info-header': {
    fi: 'Päivitä laskutustiedot',
    sv: 'Updatera fakturerings information',
    en: 'Update billing information',
  },

  // Add emptying location (New Layout)
  'add-emptying-location-header': {
    fi: 'Lisää kohde',
    sv: 'Lägg till plats',
    en: 'Add location',
  },
  'add-emptying-location-billing-data': {
    fi: 'Laskutustiedot',
    sv: 'Faktureringsupgifter',
    en: 'Billing information',
  },
  'add-emptying-location-data': {
    fi: 'Tyhjennyskohteen tiedot',
    sv: 'Tömningsplats information',
    en: 'Emptying location information',
  },
  'add-emptying-location-summary': {
    fi: 'Yhteenveto',
    sv: 'Sammanfattning',
    en: 'Summary',
  },
  'add-emptying-location-phase': {
    fi: 'Vaihe',
    sv: 'Steg',
    en: 'Step',
  },

  'add-emptying-location-cancel': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },
  'add-emptying-location-save': {
    fi: 'Tallenna',
    sv: 'Spara',
    en: 'Save',
  },
  'add-emptying-location-close': {
    fi: 'Sulje',
    sv: 'Stänga',
    en: 'Close',
  },
  'add-emptying-location-next': {
    fi: 'Seuraava',
    sv: 'Nästa',
    en: 'Next',
  },
  'add-emptying-location-previous': {
    fi: 'Edellinen',
    sv: 'Föregående',
    en: 'Previous',
  },

  'add-emptying-location-basic-info': {
    fi: 'Perustiedot',
    sv: 'Grunduppgifter',
    en: 'Key details',
  },
  'add-emptying-location-basic-info-use-same-name-data': {
    fi: 'Samat tiedot kuin laskutustiedoissa',
    en: 'Use same details as billing details',
    sv: 'Samma uppgifter som i faktureringsinformationen',
  },
  'add-emptying-location-basic-info-first-name': {
    fi: 'Etunimi',
    sv: 'Förnamn',
    en: 'First name',
  },
  'add-emptying-location-basic-info-last-name': {
    fi: 'Sukunimi',
    sv: 'Efternamn',
    en: 'Last name',
  },

  'add-emptying-location-basic-info-street-address': {
    fi: 'Katuosoite',
    sv: 'Gatuadress',
    en: 'Street address',
  },
  'add-emptying-location-basic-info-post-code': {
    fi: 'Postinumero',
    sv: 'Postnummer',
    en: 'Postal code',
  },
  'add-emptying-location-basic-info-city': {
    fi: 'Postitoimipaikka',
    sv: 'Postanstalt',
    en: 'Postal address',
  },

  'add-emptying-location-contact': {
    fi: 'Kohteen yhteyshenkilö',
    sv: 'Tömningsplatsens kontaktperson',
    en: 'Location contact person',
  },
  'add-emptying-location-use-same-contact-data': {
    fi: 'Käytä samoja yhteystietoja',
    en: 'Use same contact details',
    sv: 'Använd samma kontaktperson namndetaljer',
  },
  'add-emptying-location-contact-phone': {
    fi: 'Puhelin',
    sv: 'Telefon',
    en: 'Phone',
  },
  'add-emptying-location-contact-email': {
    fi: 'Sähkoposti',
    sv: 'E-post',
    en: 'E-mail',
  },

  'add-emptying-location-building-info': {
    fi: 'Rakennuksen tiedot',
    sv: 'Byggnadsinformation',
    en: 'Building information',
  },

  // Dropdown data fetching error
  'add-emptying-location-building-info-area-error': {
    fi: 'Alueiden haku epäonnistui',
    sv: 'Det gick inte att söka efter regioner',
    en: 'Failed to search for regions',
  },
  'add-emptying-location-building-info-area': {
    fi: 'Alue',
    sv: 'Område',
    en: 'Area',
  },
  'add-emptying-location-building-info-conurbation': {
    en: 'Conurbation',
    sv: 'Tätort',
    fi: 'Taajama',
  },
  'add-emptying-location-building-info-conurbation-error': {
    fi: 'Alueiden haku epäonnistui',
    sv: 'Det gick inte att söka efter tätorter',
    en: 'Failed to search for conurbation',
  },
  'add-emptying-location-building-info-permanent-id': {
    fi: 'Pysyvä rakennustunnus',
    sv: 'Permanent byggnadsidentifierare',
    en: 'Permanent building identifier',
  },
  'add-emptying-location-building-info-use': {
    fi: 'Rakennuksen käytössäolo',
    sv: 'Användning av byggnaden',
    en: 'Use of the building',
  },
  'add-emptying-location-building-info-type': {
    fi: 'Rakennustyyppi',
    sv: 'Typ av byggnad',
    en: 'Type of the building',
  },
  'add-emptying-location-building-info-apartment-count': {
    fi: 'Huoneistojen lukumäärä',
    sv: 'Antal lägenheter',
    en: 'Apartment count',
  },
  'add-emptying-location-building-info-population': {
    fi: 'Asukkaiden lukumäärä',
    sv: 'Befolkning',
    en: 'Population',
  },
  'add-emptying-location-building-info-property-id': {
    fi: 'Kiinteistötunnus',
    sv: 'Fastighetsbeteckning',
    en: 'Property identifier',
  },
  // Dropdown data fetching error
  'add-emptying-location-building-info-usage-data-error': {
    fi: 'Rakennuksen tyyppitietojen haku epäonnistui',
    sv: 'Det gick inte att hämta data av byggtyp',
    en: 'Building usage type data fetching failed',
  },

  'add-emptying-location-contract-info': {
    fi: 'Sopimuksen tiedot',
    sv: 'Information om kontraktet',
    en: 'Contract information',
  },
  'add-emptying-location-contract-info-start-date': {
    fi: 'Aloituspäivämäärä',
    sv: 'Startdatum',
    en: 'Start date',
  },
  'add-emptying-location-contract-info-reference-number': {
    fi: 'Viitenumero',
    sv: 'Referensnummer',
    en: 'Reference number',
  },

  // Emptying group
  'emptying-group-continuing-contracts': {
    fi: 'Voimassa olevat palvelut',
    sv: 'Befintliga tjänster',
    en: 'Current services',
  },
  'emptying-group-shared-container-contracts': {
    fi: 'Kimppapalvelut',
    sv: 'Gemensamma tjänster',
    en: 'Shared services',
  },
  'emptying-group-ended-contracts': {
    fi: 'Lopetetut palvelut',
    sv: 'Avslutade tjänster',
    en: 'Concluded services',
  },
  'emptying-group-orders-date-range': {
    fi: 'Haetaan ajalta',
    sv: 'Haetaan ajalta',
    en: 'Haetaan ajalta',
  },
  'emptying-group-desc': {
    fi: '',
    sv: '',
    en: '',
  },
  'emptying-group-shared-container-responsible-person': {
    fi: 'Vastuuhenkilö',
    sv: 'Ansvarig person',
    en: 'Responsible person',
  },
  'emptying-group-shared-container-shareholders-table-header': {
    fi: 'Osakkaan nimi',
    sv: 'Delägarens namn',
    en: 'Shareholders name',
  },
  'emptying-group-shared-container-address-table-header': {
    fi: 'Osoite',
    sv: 'Adress',
    en: 'Address',
  },
  'emptying-group-shared-container-share-table-header': {
    fi: 'Osuus',
    sv: 'Andel',
    en: 'Share',
  },
  'emptying-group-shared-container-weighing-table-header': {
    fi: 'Painotus',
    sv: 'Viktning',
    en: 'Weighing',
  },
  'emptying-group-shared-container-role': {
    fi: 'Rooli',
    sv: 'Roll',
    en: 'Role',
  },
  'emptying-group-shared-container-role-corresponding': {
    fi: 'Vastuuhenkilö',
    sv: 'Ansvarig person',
    en: 'Corresponding',
  },
  'emptying-group-shared-container-role-participant': {
    fi: 'Osakas',
    sv: 'Deltagare',
    en: 'Participant',
  },
  'emptying-group-shared-container-product': {
    fi: 'Tuote',
    sv: 'Produkt',
    en: 'Product',
  },
  // Emptying customer
  'emptying-customer-type': {
    fi: 'Valitse asiakkuuden tyyppi',
    sv: 'Välj kundtyp',
    en: 'Choose customer type',
  },
  'emptying-customer-type-select': {
    fi: 'Käytä',
    sv: 'Använd',
    en: 'Use',
  },

  // Order item
  'order-ordered-label': {
    fi: 'Tilattu',
    sv: 'Beställd',
    en: 'Ordered',
  },
  'order-waiting': {
    fi: 'Tilaus odottaa käsittelyä',
    sv: 'Beställning väntar på behandling',
    en: 'The order is waiting to be processed',
  },
  'order-being-transported': {
    fi: 'Kuljetuksessa',
    sv: 'Transporteras',
    en: 'In transit',
  },
  'order-requested-date': {
    fi: 'Toivottu aika',
    sv: 'Begärd tid',
    en: 'Preferred time',
  },
  'order-order-date': {
    fi: 'Tilauspävä',
    sv: 'Orderdatum',
    en: 'Order date',
  },
  'order-approved': {
    fi: 'Hyväksytty',
    sv: 'Godkänd',
    en: 'Approved',
  },
  'order-done': {
    fi: 'Valmis',
    sv: 'Färdig',
    en: 'Done',
  },
  'order-saved': {
    fi: 'Tallennettu',
    sv: 'Sparad',
    en: 'Saved',
  },
  'order-number': {
    fi: 'Tilausnumero',
    sv: 'Ordernummer',
    en: 'Order number',
  },

  // Contract item
  'contract-collect-time': {
    fi: 'Seuraava tyhjennys',
    sv: 'Följande tömning',
    en: 'Next collection',
  },
  'contract-amount-of-bins': {
    fi: 'Määrä',
    sv: 'Antal',
    en: 'Quantity',
  },
  'contract-interval': {
    fi: 'Tyhjennys',
    sv: 'Tömning',
    en: 'Emptying',
  },
  'contract-interval-on-demand': {
    fi: 'Tilauksesta',
    sv: 'På beställning',
    en: 'On demand',
  },
  'contract-interval-once-a-week': {
    fi: 'Joka viikko',
    sv: 'Varje vecka',
    en: 'Every week',
  },
  'contract-interval-every-second-week': {
    fi: 'Joka toinen viikko',
    sv: 'Varannan vecka',
    en: 'Every second week',
  },
  'contract-interval-times-per-week': {
    fi: 'kertaa viikossa',
    sv: 'gånger i veckan',
    en: 'times a week',
  },
  'contract-interval-multiple-weeks': {
    fi: '$1 viikon välein',
    sv: 'Var $1 vecka',
    en: 'Every $1 weeks',
  },
  'contract-times-per-week': {
    fi: 'kertaa viikossa',
    sv: 'gånger i veckan',
    en: 'times a week',
  },
  'contract-interval-multiple-times-per-week': {
    fi: '$1 kertaa viikossa, $2 viikon välein',
    sv: '$1 gånger var $2 veckor',
    en: '$1 times every $2 weeks',
  },
  'contract-interval-twice-a-week': {
    fi: 'Viikon välein',
    sv: 'Två gånger i veckan',
    en: 'Twice a week',
  },
  'contract-interval-three-times-a-week': {
    fi: '3 kertaa viikossa',
    sv: 'Tre gånger i veckan',
    en: 'Three times a week',
  },
  'contract-interval-four-times-a-week': {
    fi: '4 kertaa viikossa',
    sv: 'Fyra gånger i veckan',
    en: 'Four times a week',
  },
  'contract-interval-five-times-a-week': {
    fi: '5 kertaa viikossa',
    sv: 'Fem gånger i veckan',
    en: 'Five times a week',
  },
  'contract-interval-every-x-weeks': {
    fi: 'Joka $1. viikko ',
    sv: '$1 veckor',
    en: 'Every $1 weeks',
  },

  // Contract details
  'contract-details-change-contract': {
    fi: 'Muokkaa sopimusta',
    sv: 'Redigera avtal',
    en: 'Edit contract',
  },
  'contract-details-emptying-intervals': {
    fi: 'Tyhjennysvälit',
    sv: 'Tömningsintervaller',
    en: 'Emptying intervals',
  },
  'contract-details-emptying-single-interval': {
    fi: 'Tyhjennysväli',
    sv: 'Tömningsintervall',
    en: 'Emptying interval',
  },
  'contract-details-emptying-dates': {
    fi: 'Tyhjennyspäivämäärät',
    sv: 'Tömningsdatum',
    en: 'Emptying dates',
  },
  'contract-details-num-of-bins': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Number of containers',
  },
  'contract-details-emptying-price': {
    fi: 'Astian tyhjennys',
    sv: 'Tömning av kärl',
    en: 'Emptying the container',
  },
  'contract-details-waste-disposal-fee': {
    fi: 'Jätteenkäsittely',
    sv: 'Avfallshantering',
    en: 'Waste disposal',
  },
  'contract-details-total-price': {
    fi: 'Hinta yhteensä',
    sv: 'Totalt pris',
    en: 'Total price',
  },
  'contract-details-approve': {
    fi: 'Hyväksy',
    sv: 'Acceptera',
    en: 'Approve',
  },
  'contract-details-approved-message-title': {
    fi: 'Sopimus hyväksytty',
    sv: 'Kontrakt accepterade',
    en: 'Contract approved',
  },
  'contract-details-approved-message': {
    fi: 'Sopimus hyväksytty',
    sv: 'Kontrakt accepterade',
    en: 'Contract approved',
  },
  'contract-details-modification-message': {
    fi: 'Voit muokata palvelun sisältöä hyväksymisen jälkeen',
    sv: 'Du kan modifiera service innehåll efter att du har godkännt den',
    en: 'You can modify the service content after approving it',
  },
  'contract-details-reject': {
    fi: 'Hylkää',
    sv: 'Avvisa',
    en: 'Reject',
  },
  'contract-confirm-reject-title': {
    fi: 'Hylkää sopimus',
    sv: 'Avvisa kontrakt',
    en: 'Reject contract',
  },
  'contract-confirm-reject-message': {
    fi: 'Haluatko hylätä sopimuksen?',
    sv: 'Vill du avvisa kontrakten?',
    en: 'Do you want to reject the contract?',
  },
  'contract-confirm-reject-yes': {
    fi: 'Kyllä',
    sv: 'Ja',
    en: 'Yes',
  },
  'contract-confirm-reject-no': {
    fi: 'En',
    sv: 'Nej',
    en: 'No',
  },
  'contract-details-reject-message-title': {
    fi: 'Sopimus hylätty',
    sv: 'Kontrakt avvisad',
    en: 'Contract rejected',
  },
  'contract-details-reject-message': {
    fi: 'Sopimus hylätty',
    sv: 'Kontrakt avvisad',
    en: 'Contract rejected',
  },
  'contract-details-edit': {
    sv: 'Redigera',
    fi: 'Muokkaa',
    en: 'Edit',
  },
  'contract-details-confirm-changes': {
    sv: 'Bekräfta ändring',
    fi: 'Vahvista muutos',
    en: 'Confirm change',
  },
  'contract-details-cancel-changes': {
    sv: 'Avbryt',
    fi: 'Peruuta',
    en: 'Cancel',
  },
  'contract-details-change-applies-to': {
    sv: 'Ändringen gäller',
    fi: 'Muutos koskettaa',
    en: 'The change applies to',
  },
  'contract-details-number-of-bins': {
    sv: 'Antal kärl',
    fi: 'Astioiden lukumäärää',
    en: 'The number of bins',
  },
  'contract-details-number-of-bins-title': {
    fi: 'Astioiden uusi määrä',
    sv: 'Nytt antal kärl',
    en: 'New number of bins',
  },
  'contract-details-number-of-interval': {
    sv: 'Tömningsintervall',
    fi: 'Tyhjennysrytmiä',
    en: 'Emptying interval',
  },
  'contract-details-pause': {
    sv: 'Tillfälligt avbrot',
    fi: 'Palvelun tilapäinen keskeytys',
    en: 'Temporary contract pause',
  },
  'contract-details-terminating': {
    sv: 'Uppsägning av tjänst',
    fi: 'Palvelun lopetus',
    en: 'Terminating the service',
  },
  'contract-details-terminating-title': {
    fi: 'Palvelun lopettaminen',
    sv: 'Avslutande av tjänst',
    en: 'Termination of service',
  },
  'contract-details-terminating-date-of-termination': {
    fi: 'Lopetuspäivä',
    sv: 'Avslutningsdatum',
    en: 'Date of termination',
  },
  'contract-details-terminating-reason-for-termination': {
    fi: 'Lopettamisen syy',
    sv: 'Orsak för avslutande',
    en: 'Reason for termination',
  },
  'contract-details-terminating-additional-information': {
    fi: 'Lisätiedot',
    sv: 'Tilläggsuppgifter',
    en: 'Additional information',
  },
  'contract-details-pause-form-header': {
    sv: 'Avbrott',
    fi: 'Keskeytys',
    en: 'Pause',
  },
  'contract-details-pause-form-guide-text': {
    sv: 'Tömning av avfallskärl kan avbrytas om fastigheten är tillfälligt tom.',
    fi: 'Jäteastian tyhjennyksen voi keskeyttää, jos kiinteistö on väliaikaisesti tyhjillään.',
    en: 'Waste collection can be put on pause if the property is temporarily not in use.',
  },
  'contract-details-pause-form-start-date': {
    sv: 'Avbrottet börjar',
    fi: 'Keskeytys alkaa',
    en: 'Pause starts',
  },
  'contract-details-pause-form-end-date': {
    sv: 'Avbrottet slutar',
    fi: 'Keskeytys päättyy',
    en: 'Pause ends',
  },
  'contract-details-pause-form-reason': {
    sv: 'Anledningen till att tjänsten upphörde',
    fi: 'Keskeytyksen syy',
    en: 'Reason for cancellation',
  },
  'contract-details-problems-emptying-interval-not-valid': {
    sv: 'Tömningsintervallet är inte giltigt för produkten',
    fi: 'Tyhjennysväli ei ole sallittu',
    en: 'Emptying interval is not valid for product',
  },
  'contract-details-problems-emptying-interval-not-defined': {
    sv: 'Tömningsintervall ej definierat',
    fi: 'Tyhjennysväliä ei ole määritetty',
    en: 'Emptying interval not defined',
  },
  'contract-details-driver-instructions': {
    sv: 'Till chauffören',
    fi: 'Kuljettajalle',
    en: 'For the driver',
  },
  'contract-details-driver-instructions-gate-key': {
    sv: 'Nyckelkod till porten',
    fi: 'Portin avainkoodi',
    en: 'Key code for the gate',
  },

  // Additional collection
  'order-additional-collection-dialog-header': {
    fi: 'Tilaa lisätyhjennys',
    sv: 'Beställ ytterligare tömning',
    en: 'Order additional collection',
  },
  'order-additional-collection-form-header': {
    fi: 'Lisätyhjennyksen tiedot',
    sv: 'Ytterligare tömningsinformation',
    en: 'Additional collection information',
  },
  'order-additional-collection-cancel-button': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },
  'order-additional-collection-submit-button': {
    fi: 'Tilaa lisätyhjennys',
    sv: 'Beställ ytterligare tömning',
    en: 'Order additional collection',
  },
  'order-additional-collection-date': {
    fi: 'Toivottu lisätyhjennyspäivä',
    sv: 'Önskat tömningsdatum',
    en: 'Preferred collection date',
  },
  'order-additional-collection-amount': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Amount of bins',
  },
  'order-additional-collection-info': {
    fi: 'Lisätiedot',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  // Contract Calendar
  'contract-calendar-header': {
    fi: 'Tyhjennys',
    sv: 'Tömning',
    en: 'Collection',
  },
  'contract-calendar-popover-date-and-time': {
    fi: 'Aika',
    sv: 'Tid',
    en: 'Time',
  },
  'contract-calendar-popover-at': {
    fi: 'klo',
    sv: 'kl.',
    en: 'at',
  },
  'contract-calendar-popover-status': {
    fi: 'Tila',
    sv: 'Situation',
    en: 'Status',
  },
  'contract-calendar-popover-next-status': {
    fi: 'Tyhjennys tulossa',
    sv: 'Tömning på väg',
    en: 'Collection on the way',
  },
  'contract-calendar-popover-successful-status': {
    fi: 'Tyhjennys suoritettu',
    sv: 'Tömning gjord',
    en: 'Collection completed',
  },
  'contract-calendar-popover-missed-status': {
    fi: 'Tyhjennys epäonnistui',
    sv: 'Tömning misslyckades',
    en: 'Collection failed',
  },
  'contract-calendar-popover-planned-status': {
    fi: 'Tuleva tyhjennys',
    sv: 'Framtida tömning',
    en: 'Planned collection',
  },

  'contract-calendar-popover-feedback': {
    fi: 'Palaute',
    sv: 'Återkoppling',
    en: 'Feedback',
  },
  'contract-shared-corresponding-person-approval-waiting': {
    fi: 'Sopimus odottaa palvelun vastuuhenkilön hyväksyntää',
    sv: 'Kontraktet väntar på godkännande av den person som ansvarar för tjänsten',
    en: 'The contract is awaiting the approval of the person in charge of the service',
  },

  // Extra emptying
  'extra-emptying-order': {
    fi: 'Tilaa lisätyhjennys',
    sv: 'Beställ ytterligare tömning',
    en: 'Order an additional collection',
  },
  'extra-emptying-description': {
    fi: 'Sekajäte astian tyhjennyspalvelu',
    sv: 'Tömningstjänst för blandavfallskärl',
    en: 'Mixed waste collection service',
  },
  'extra-emptying-form-title': {
    fi: 'Lisätyhjennys',
    sv: 'Ytterligare tömning',
    en: 'Extra collection',
  },
  'extra-emptying-date': {
    fi: 'Toivottu tyhjennyspäivä',
    sv: 'Önskat tömningsdatum',
    en: 'Preferred collection date',
  },
  'extra-emptying-date-description': {
    fi: 'Tyhjennys suoritetaan mahdollisimman lähellä toivomaasi ajankohtaa.',
    sv: 'Tömning utförs så nära önskad tidpunkt som möjligt.',
    en: 'The collection will take place as close to your preferred date as possible.',
  },
  'extra-emptying-num-of-bins': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Number of bins',
  },
  'extra-emptying-additional-info': {
    fi: 'Lisätietoja',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  'extra-emptying-additional-info-placeholder': {
    fi: 'Kirjoita lisätietoja',
    sv: 'Ange tilläggsinformation',
    en: 'Please add further information here',
  },
  'extra-emptying-order-button': {
    fi: 'Tilaa lisätyhjennys',
    sv: 'Beställ ytterligare tömning',
    en: 'Order an extra collection',
  },
  'extra-emptying-invalid-date': {
    fi: 'Tyhjennyspäivä voi olla aikasintaan huomenna.',
    sv: 'Tömningsdatum kan vara tidigast i morgon.',
    en: 'The collection date can be tomorrow at the soonest.',
  },

  // Change contract
  'change-contract-header-desc': {
    fi: 'Sekajäte astian tyhjennyspalvelu',
    sv: 'Tömningstjänst för blandavfallskärl',
    en: 'Mixed waste collection service',
  },

  'change-contract-change-what': {
    fi: 'Muutos koskee',
    sv: 'Ändringen gäller',
    en: 'The change applies to',
  },
  'change-contract-change-bins': {
    fi: 'Astioiden lukumäärää',
    sv: 'Antal kärl',
    en: 'The number of bins',
  },
  'change-contract-change-interval': {
    fi: 'Tyhjennysväliä',
    sv: 'Tömningsintervaller',
    en: 'The collection interval',
  },
  'change-contract-change-pause': {
    fi: 'Keskeytystä',
    sv: 'Avbrott',
    en: 'A pause in the service',
  },
  'change-contract-change-ending': {
    fi: 'Palvelun lopettamista',
    sv: 'Uppsägning av tjänst',
    en: 'Terminating the service',
  },
  'change-contract-pause-title': {
    fi: 'Keskeytys',
    sv: 'Avbrott',
    en: 'Pause',
  },
  'change-contract-pause-desc': {
    fi: 'Jäteastian tyhjennyksen voi keskeyttää, jos kiinteistö on väliaikaisesti tyhjillään.',
    sv: 'Tömning av avfallskärl kan avbrytas om fastigheten är tillfälligt tom',
    en: 'Waste collection can be put on pause if the property is temporarily not in use.',
  },
  'change-contract-pause-start': {
    fi: 'Keskeytys alkaa',
    sv: 'Avbrottet börjar',
    en: 'The pause is to start',
  },
  'change-contract-pause-end': {
    fi: 'Keskeytys päättyy',
    sv: 'Avbrottet slutar',
    en: 'The pause is to end',
  },

  'change-contract-effective': {
    fi: 'Muutos astuu voimaan',
    sv: 'Ändringen träder i kraft',
    en: 'This change is to become valid on',
  },
  'change-contract-additional-info': {
    fi: 'Lisätietoja',
    sv: 'Tilläggsinformation',
    en: 'Further information',
  },
  'change-contract-end-title': {
    fi: 'Palvelun lopetus',
    sv: 'Avslutande av tjänst',
    en: 'Terminating the service',
  },
  'change-contract-end-date': {
    fi: 'Lopetuspäivämäärä',
    sv: 'Avslutningsdatum',
    en: 'Date of termination',
  },
  'change-contract-end-button': {
    fi: 'Päätä palvelu',
    sv: 'Avsluta tjänst',
    en: 'Terminate the service',
  },

  'change-contract-interval': {
    fi: 'Tyhjennysväli',
    sv: 'Tömningsintervall',
    en: 'Collection interval',
  },
  'change-contract-interval-succeed': {
    fi: 'Tyhjennysväli päivitetty',
    sv: 'Tömningsintervall har uppdaterats',
    en: 'Collection interval successfully updated',
  },
  'change-contract-new-interval': {
    fi: 'Uusi tyhjennysväli',
    sv: 'Ny tömningsintervall',
    en: 'New collection interval',
  },

  'change-contract-interval-start': {
    fi: 'Vk alkaen',
    sv: 'Från vecka',
    en: 'Starting on week',
  },
  'change-contract-interval-end': {
    fi: 'Vk asti',
    sv: 'Till vecka',
    en: 'Until week',
  },
  'whole-year-interval': {
    fi: 'Koko vuosi (1.1.-31.12)',
    sv: 'Hela året (1.1.-31.12)',
    en: 'Whole year (1.1.-31.12)',
  },
  'change-contract-interval-another-interval': {
    fi: 'Haluan toisen tyhjennysvälin',
    sv: 'Jag vill ha ett annat tömningsintervall',
    en: 'I want a different collection interval',
  },
  'change-contract-interval-1': {
    fi: 'Tyhjennys kerran viikossa',
    sv: 'Tömning en gång i veckan',
    en: 'Collection once a week',
  },
  'change-contract-interval-2': {
    fi: 'Tyhjennys kahden viikon välein',
    sv: 'Tömning varannan vecka',
    en: 'Collection once a fortnight',
  },
  'change-contract-weeks': {
    fi: 'Viikot',
    sv: 'Veckor',
    en: 'Weeks',
  },
  'change-contract-allowed-intervals': {
    fi: 'Tyhjennysvälit',
    sv: 'Tömningsintervaller',
    en: 'Collection intervals',
  },

  'change-contract-interval-overlap-error': {
    fi: 'Tyhjennysvälit menevät päällekkäin',
    sv: 'Tömningsintervallerna överlappar varandra',
    en: 'Overlapping collection intervals',
  },

  'change-contract-message-title': {
    fi: 'Kiitos.',
    sv: 'Tack.',
    en: 'Thank you.',
  },
  'change-contract-message-ending': {
    fi: 'Pyyntö sopimuksen lopettamisesta vastaanotettu.',
    sv: 'Begäran om att säga upp avtalet har mottagits.',
    en: 'We have received your request to terminate the contract.',
  },
  'change-contract-message-pause': {
    fi: 'Pyyntö sopimuksen keskeyttämisestä vastaanotettu.',
    sv: 'Begäran om att tillfälligt upphäva avtalet har mottagits.',
    en: 'We have received your request to pause the contract.',
  },
  'change-contract-amount-title': {
    fi: 'Astioiden lukumäärä.',
    sv: 'Antal kärl.',
    en: 'Number of bins.',
  },
  'change-contract-amount-amount': {
    fi: 'Astioiden uusi määrä.',
    sv: 'Nytt antal kärl.',
    en: 'New number of bins.',
  },
  'change-contract-message-amount': {
    fi: 'Astioiden määrän muutos vastaanotettu.',
    sv: 'Ändring av antal kärl har mottagits.',
    en: 'We have received your change in the number of bins.',
  },

  // Service Order (Tilaa palvelu)
  'service-order-label': {
    fi: 'Tilaa palvelu',
    sv: 'Beställa tjänst',
    en: 'Order a service',
  },

  'service-order-desc': {
    fi: 'Aloita valitsemalla minkä tuotteen haluat',
    sv: 'Börja med att välja produkt',
    en: 'Start by selecting the product',
  },

  'service-order-no-products-title': {
    fi: 'Ei valittavia tuotteita',
    sv: 'Det finns inga produkter att välja',
    en: 'No products to select',
  },

  'service-order-no-products-text': {
    fi: 'Kyseiseen osoitteeseen ei ole mahdollista tilata tuotteita.',
    sv: 'Det är inte möjligt att beställa produkter till denna adress.',
    en: 'No products can be ordered for this address.',
  },

  'service-order-single-orders': {
    fi: 'Tilaukset',
    sv: 'Beställningar',
    en: 'Orders',
  },
  'service-order-contracts': {
    fi: 'Jatkuvat palvelut',
    sv: 'Löpande tjänster',
    en: 'Continuous services',
  },
  'service-order-contracts-waiting-for-approval': {
    fi: 'Hyväksyntää odottavat palvelut',
    sv: 'Löpande tjänster som väntar på godkännande',
    en: 'Continuous services waiting for approval',
  },
  'service-order-button': {
    fi: 'Tilaa',
    sv: 'Beställ',
    en: 'Order',
  },
  'service-order-confirm-button': {
    fi: 'Vahvista muutos',
    sv: 'Bekräfta ändring',
    en: 'Confirm change',
  },

  'service-order-alv-included': {
    fi: 'sis. alv',
    sv: 'moms ingår',
    en: 'incl. VAT',
  },
  'service-order-goto-basket': {
    fi: 'Siirry ostoskoriin',
    sv: 'Till varukorgen',
    en: 'To the shopping cart',
  },
  'service-order-products-in-basket': {
    fi: 'Sinulla on $1 tuotetta ostoskorissa',
    sv: 'Du har $1 produkt(er) i varukorgen',
    en: 'You have $1 products in your cart',
  },
  'service-order-products-stickers': {
    fi: 'Valitse haluamasi tarrat ja opastekyltit',
    sv: 'Välj de klistermärken och skyltar du vill ha',
    en: 'Choose the stickers and signs you want',
  },
  'service-order-basket': {
    fi: 'Ostoskori',
    sv: 'Varukorg',
    en: 'Shopping cart',
  },

  'service-order-basket-description': {
    fi: 'Tarkasta tilauksesi sisältö ja tee tilaus',
    sv: 'Kontrollera innehållet i din beställning och gör din beställning',
    en: 'Check the contents of your order and complete the order process',
  },

  'service-order-continue-shopping': {
    fi: 'Jatka ostoksia',
    sv: 'Fortsätt handla',
    en: 'Continue shopping',
  },

  'service-order-alv': {
    fi: 'Arvonlisävero',
    sv: 'Moms',
    en: 'Value added tax',
  },
  'service-order-total-sum': {
    fi: 'Loppusumma (sis. alv)',
    sv: 'Slutsumma (moms ingår)',
    en: 'Total (incl. VAT)',
  },
  'service-order-thanks': {
    fi: 'Kiitos tilauksestasi!',
    sv: 'Tack för din beställning!',
    en: 'Thank you for your order!',
  },
  'service-order-back-to-shopping': {
    fi: 'Takaisin nettikauppaan',
    sv: 'Tillbaka till nätbutiken',
    en: 'Back to the online store',
  },
  'service-order-summary-title': {
    fi: 'Tilauksen yhteenveto',
    sv: 'Beställningssammanfattning',
    en: 'Order overview',
  },
  'service-order-pickup-title': {
    fi: 'Viimeistele tilaus',
    sv: 'Slutför beställning',
    en: 'Complete your order',
  },
  'service-order-pickup-description': {
    fi: 'Viimeistele syöttämällä tilauksen tiedot',
    sv: 'Ange din beställningsinformation för att slutföra',
    en: 'Complete your order by adding the order details',
  },
  'service-order-pickup': {
    fi: 'Noutopalvelu',
    sv: 'Hämtservice',
    en: 'Pickup service',
  },
  'service-order-pickup-date': {
    fi: 'Toivottu noutoaika',
    sv: 'Önskad hämtningstid',
    en: 'Preferred pickup time',
  },
  'service-order-product-group': {
    fi: 'Tuoteryhmä',
    sv: 'Produktgrupp',
    en: 'Product group',
  },
  'service-order-product-entity': {
    fi: 'Tuote',
    sv: 'Produkt',
    en: 'Product',
  },
  'service-order-product-amount': {
    fi: 'Määrä',
    sv: 'Antal',
    en: 'Amount',
  },
  'service-order-pickup-waste-type-title': {
    fi: 'Palvelu',
    sv: 'Tjänst',
    en: 'Service',
  },
  'service-order-pickup-additional-info': {
    fi: 'Lisätietoja',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  'service-order-pickup-price': {
    fi: 'Hinta',
    sv: 'Pris',
    en: 'Price',
  },
  'service-order-pickup-price-pickup': {
    fi: 'Astian nouto',
    sv: 'Hämtning av kärl',
    en: 'Bin pickup',
  },
  'service-order-pickup-price-waste-handling': {
    fi: 'Jätteen käsittely',
    sv: 'Avfallshantering',
    en: 'Waste handling',
  },
  'service-order-pickup-price-total': {
    fi: 'Hinta yhteensä',
    sv: 'Totalt pris',
    en: 'Total price',
  },
  'service-order-pickup-add-order': {
    fi: 'Lisää',
    sv: 'Lägg till',
    en: 'Add',
  },
  'service-order-pickup-remove-order': {
    fi: 'Poista',
    sv: 'Ta bort',
    en: 'Remove',
  },
  'service-order-dumpster': {
    fi: 'Vaihtolava',
    sv: 'Växelflak',
    en: 'Interchangeable container',
  },
  'service-order-importation-date': {
    fi: 'Toivottu tuontiaika',
    sv: 'Önskad hämttid',
    en: 'Preferred delivery time',
  },
  'service-order-dumpster-unknown-pickup-date': {
    fi: 'En tiedä noutoaikaa, ilmoitan tämän tiedon myöhemmin',
    sv: 'Jag känner inte till hämtningstiden, jag meddelar denna information senare',
    en: 'I don’t have a preferred delivery time yet; I’ll let you know later',
  },

  'service-order-dumpster-location': {
    fi: 'Lavan paikka kohteessa',
    sv: 'Flakets placering på platsen',
    en: 'Container site at location',
  },
  'service-order-dumpster-size': {
    fi: 'Lavan koko ja määrä',
    sv: 'Flakets storlek och antal',
    en: 'Container size and quantity',
  },
  'service-order-dumpster-size-small': {
    fi: 'Pieni',
    sv: 'Små',
    en: 'Small',
  },
  'service-order-dumpster-size-medium': {
    fi: 'Normaali',
    sv: 'Vanligt',
    en: 'Normal',
  },
  'service-order-dumpster-size-big': {
    fi: 'Iso',
    sv: 'Stor',
    en: 'Big',
  },

  'service-order-dumpster-waste-type': {
    fi: 'Jätelaji',
    sv: 'Avfallstyp',
    en: 'Waste type',
  },
  'service-order-dumpster-waste-type-bio': {
    fi: 'Biojäte',
    sv: 'Bioavfall',
    en: 'Bio waste',
  },
  'service-order-dumpster-waste-type-mixed': {
    fi: 'Sekajäte',
    sv: 'Blandat avfall',
    en: 'Mixed waste',
  },
  'service-order-dumpster-waste-type-energy': {
    fi: 'Energiajäte',
    sv: 'Energislöseri',
    en: 'Energy waste',
  },

  // Add contract (Tilaa palvelu -> Astian tyhjennyspalvelu)

  'add-contract-wastetype-header': {
    fi: 'Jätelaji',
    sv: 'Avfallstyp',
    en: 'Waste type',
  },
  'add-contract-wastetype-desc': {
    fi: 'Valitse haluamasi jätelaji.',
    sv: 'Välj önskad avfallstyp.',
    en: 'Choose the waste type.',
  },

  'add-contract-finish-order': {
    fi: 'Viimeistele tilaus',
    sv: 'Slutföra order',
    en: 'Complete order',
  },
  'add-contract-finish-order-desc': {
    fi: 'Viimeistele syöttämällä tilauksen tiedot.',
    sv: 'Komplettera genom att ange beställningsuppgifterna.',
    en: 'Complete by entering the order details.',
  },
  'add-contract-start-date': {
    fi: 'Sopimuksen alkamispäivä',
    sv: 'Avtalets startdatum',
    en: 'Contract start date',
  },
  'add-contract-amount-per-week': {
    fi: 'Määrä viikossa',
    sv: 'Mängden per vecka',
    en: 'Amount per week',
  },
  'add-contract-service-title': {
    fi: 'Palvelu',
    sv: 'Tjänst',
    en: 'Service',
  },
  'add-contract-bin-amount': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Number of containers',
  },
  'add-contract-interval': {
    fi: 'Vuotuinen tyhjennysrytmi',
    sv: 'Årlig tömningsintervall',
    en: 'Annual collection interval',
  },
  'add-contract-bin-ownership-label': {
    fi: 'Jäteastia',
    sv: 'Avfallskärl',
    en: 'Waste container',
  },
  'add-contract-bin-ownership-rent': {
    fi: 'Vuokraan jäteastian',
    sv: 'Jag hyr ett avfallskärl',
    en: 'I’d like to rent the bin',
  },
  'add-contract-bin-ownership-buy': {
    fi: 'Ostan jäteastian',
    sv: 'Jag köper ett avfallskärl',
    en: 'I’d like to buy the bin',
  },
  'add-contract-bin-ownership-owned': {
    fi: 'Kiinteistöllä on jo jäteastia',
    sv: 'Fastigheten har redan ett avfallskärl',
    en: 'The property already has a bin',
  },
  'add-contract-bin-ownership-delivery': {
    fi: 'Haluan jäteastian toimitettuna',
    sv: 'Jag vill ha avfallsbehållaren levererad',
    en: 'I want the waste container delivered',
  },
  'add-contract-for-driver': {
    fi: 'Kuljettajalle',
    sv: 'Till chauffören',
    en: 'For the driver',
  },
  'add-contract-for-driver-key-code': {
    fi: 'Avainkoodi porttiin',
    sv: 'Nyckelkod till porten',
    en: 'Key code for the gate',
  },
  'add-contract-for-driver-info': {
    fi: 'Lisätietoja',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  'add-contract-price': {
    fi: 'Hinta',
    sv: 'Pris',
    en: 'Price',
  },
  'add-contract-price-emptying': {
    fi: 'Astian tyhjennys (sisältää alvin)',
    sv: 'Tömning av kärl (moms ingår)',
    en: 'Emptying the bin (VAT included)',
  },
  'add-contract-price-waste-management': {
    fi: 'Jätteenkäsittely',
    sv: 'Avfallshantering',
    en: 'Waste management',
  },
  'add-contract-price-bin': {
    fi: 'Jäteastia (sisältää alvin)',
    sv: 'Avfallskärl (moms ingår)',
    en: 'Waste bin (VAT included)',
  },
  'add-contract-price-total': {
    fi: 'Hinta yhteensä (sisältää alvin)',
    sv: 'Totalt pris (moms ingår)',
    en: 'Total price (VAT included)',
  },
  'add-new-contract-success-message': {
    fi: 'Tilaus vastaanotettu.',
    sv: 'Beställning har mottagits.',
    en: 'We have received your order.',
  },
  'add-new-contract-failed-message': {
    fi: 'Tilauksen luonti epäonnistui.',
    sv: 'Det gick inte att skapa beställning.',
    en: 'Creating the order failed.',
  },

  // Contracts (Tilaushistoria)
  'contracts-label': {
    fi: 'Tilaushistoria',
    sv: 'Beställningshistorik',
    en: 'Order history',
  },

  'contracts-desc': {
    fi: '',
    sv: '',
    en: '',
  },

  // User account data (Omat tiedot)
  'user-account': {
    en: 'Account settings',
    fi: 'Tilin asetukset',
    sv: 'Kontoinställningar',
  },
  'user-account-private-customer-desc': {
    fi: 'Yksityisasiakas',
    sv: 'Privatkund',
    en: 'Private customer',
  },

  'user-account-company-desc': {
    fi: 'Yritys tai yhteisö',
    sv: 'Företag eller organisation',
    en: 'Company or organisation',
  },

  'user-account-change-pw': {
    fi: 'Vaihda salasana',
    sv: 'Byt lösenord',
    en: 'Change password',
  },
  'user-account-current-pw': {
    fi: 'Nykyinen salasana',
    sv: 'Nuvarande lösenord',
    en: 'Current password',
  },
  'user-account-new-pw': {
    fi: 'Uusi salasana',
    sv: 'Nytt lösenord',
    en: 'New password',
  },
  'user-account-confirm-new-pw': {
    fi: 'Vahvista uusi salasana',
    sv: 'Bekräfta nytt lösenord',
    en: 'Confirm the new password',
  },
  'user-account-save': {
    fi: 'Tallenna',
    sv: 'Spara',
    en: 'Save',
  },

  // Customer Service (Asiakaspalvelu)
  'customer-service': {
    fi: 'Asiakaspalvelu',
    sv: 'Kundservice',
    en: 'Customer Service',
  },

  // Invoices (Laskut)
  invoices: {
    fi: 'Laskut',
    sv: 'Fakturor',
    en: 'Invoices',
  },
  'invoices-header-description': {
    fi: 'Löydät omat laskusi helposti aina yhdestä paikasta.',
    sv: 'Du kan enkelt hitta dina egna fakturor på ett ställe.',
    en: 'You can always find all your invoices in one convenient place.',
  },
  'invoice-item-invoice-date': {
    fi: 'Laskun päiväys',
    sv: 'Fakturadatum',
    en: 'Invoice date',
  },
  'invoice-item-open-title': {
    fi: 'Laskun summa',
    sv: 'Fakturasumma',
    en: 'Invoice total',
  },
  'invoice-item-paid-title': {
    fi: 'Maksettu',
    sv: 'Betald',
    en: 'Paid',
  },
  'invoice-item-due-date': {
    fi: 'Eräpäivä',
    sv: 'Förfallodatum',
    en: 'Due date',
  },
  'invoice-item-invoice-number': {
    fi: 'Laskunumero',
    sv: 'Fakturanummer',
    en: 'Invoice number',
  },
  'invoice-item-invoice-amount-completed': {
    fi: 'Suoritus',
    sv: 'Betalning',
    en: 'Payment',
  },
  'invoice-item-invoice-pdf': {
    fi: 'Lasku',
    sv: 'Faktura',
    en: 'Invoice',
  },
  'invoice-page-no-invoices-found-header': {
    fi: 'Ei laskuja',
    sv: 'Inga fakturor',
    en: 'No invoices',
  },
  'invoice-page-no-invoices-found-description': {
    fi: 'Laskut tulevat näkyviin tälle sivulle.',
    sv: 'Fakturor kommer att synnas på den här sidan.',
    en: 'Invoices will be shown on this page.',
  },
  'invoice-table-header-sum': {
    fi: 'Summa',
    sv: 'Summa',
    en: 'Sum',
  },
  'invoice-table-header-date': {
    fi: 'Päiväys',
    sv: 'Datum',
    en: 'Date',
  },
  'invoice-table-header-due-date': {
    fi: 'Eräpäivä',
    sv: 'Förfallodatum',
    en: 'Due date',
  },
  'invoice-table-header-invoice-number': {
    fi: 'Lasku nro',
    sv: 'Fakturerings nr',
    en: 'Invoice no',
  },
  'invoice-table-header-payment': {
    fi: 'Suoritus',
    sv: 'Betalning',
    en: 'Payment',
  },
  'invoice-table-header-payment-date': {
    fi: 'Suorituspäivä',
    sv: 'Betalningsdatum',
    en: 'Payment date',
  },
  'invoice-table-header-invoice': {
    fi: 'Lasku',
    sv: 'Faktura',
    en: 'Invoice',
  },
  'invoice-item-payment-date': {
    fi: 'Suorituspäivä',
    sv: 'Betalad datum',
    en: 'Payment date',
  },

  // Logout in menu
  logout: {
    fi: 'Kirjaudu ulos',
    sv: 'Logga ut',
    en: 'Log out',
  },

  menu: {
    fi: 'Valikko',
    sv: 'Meny',
    en: 'Menu',
  },

  // Validation
  'validation-required': {
    fi: 'Tieto on pakollinen',
    sv: 'Obligatorisk uppgift',
    en: 'Required',
  },
  'validation-invalid-ssn': {
    fi: 'Henkilötunnus on virheellinen',
    sv: 'Fel personbeteckning',
    en: 'Incorrect personal identity code',
  },
  'validation-invalid-company-id': {
    fi: 'Yritystunnus on virheellinen',
    sv: 'Fel FO-nummer',
    en: 'Incorrect Business ID',
  },

  'validation-invalid-email': {
    fi: 'Virheellinen sähköposti',
    sv: 'Fel e-post',
    en: 'Incorrect e-mail',
  },
  'validation-invalid-username': {
    fi: 'Käyttäjätunnus saa sisältää vain kirjaimia (poislukien å ä ö), numeroita tai näitä merkkejä: - . _ @',
    sv: 'Användarnamnet får endast innehålla bokstäver (förutom å ä ö), siffror eller dessa tecken: - . _ @',
    en: 'The username may only contain letters, numbers, and the following special characters: - . _ @',
  },
  'validation-invalid-password': {
    fi: 'Salasanan pitää olla vähintään 12 merkkiä.',
    sv: 'Lösenordet måste vara minst 12 tecken långt.',
    en: 'The password must be at least 12 characters in length.',
  },
  'validation-invalid-integer': {
    fi: 'Arvon pitää olla kokonaisluku',
    sv: 'Värdet måste vara ett heltal',
    en: 'Value must be an integer',
  },
  'validation-invalid-positive-integer': {
    fi: 'Arvon pitää olla positiivinen kokonaisluku',
    sv: 'Värdet måste vara ett positiv heltal',
    en: 'Value must be a positive integer',
  },
  'validation-invalid-date': {
    fi: 'Virheellinen päivämäärä',
    sv: 'Ogiltigt datum',
    en: 'Invalid date',
  },
  'validation-not-matching': {
    fi: 'Arvo ei täsmää',
    sv: 'Värdet stämmer inte överens',
    en: 'Value mismatch',
  },
  'validation-matching': {
    fi: 'Nykyinen ja uusi arvo eivät voi täsmää',
    sv: 'Aktuellt och nytt värde kan inte matcha',
    en: 'Current and new value cannot match',
  },
  'validation-too-short': {
    fi: 'Arvo on liian lyhyt',
    sv: 'Värdet är för kort',
    en: 'The input value is too short',
  },
  'validation-greater-than-zero': {
    fi: 'Arvon on oltava suurempi kuin 0',
    sv: 'Värdet måste vara större än 0',
    en: 'The value must exceed 0',
  },
  'validation-invalid-phone': {
    fi: 'Virheellinen puhelinumero',
    sv: 'Ogiltigt telefonnummer',
    en: 'Invalid phone number',
  },
  'validation-invalid-post-code': {
    fi: 'Virheellinen postinumero',
    sv: 'Ogiltigt postnummer',
    en: 'Invalid post code',
  },
  'validation-integer-not-start-zero': {
    fi: 'Arvo ei saa alkaa 0:lla',
    sv: 'Värdet får inte börja med 0',
    en: 'The value must not start with 0',
  },
  'validation-equal-start-end-dates': {
    fi: 'Aloitus- ja päättymispäivämäärät eivät voi olla samat',
    sv: 'Start- och slutdatum kan inte vara samma',
    en: 'The start and end dates cannot be the same',
  },
  'validation-startdate-greater-than-enddate': {
    fi: 'Päättymispäivämäärä ei voi olla ennen aloituspäivämäärää',
    sv: 'Slutdatumet kan inte infalla före startdatumet',
    en: 'The end date cannot be before the start date',
  },
  'validation-startdate-before-now': {
    fi: 'Aloituspäivämäärä ei voi olla menneisyydessä',
    sv: 'Startdatumet kan inte ligga i det förflutna',
    en: 'The start date cannot be in the past',
  },
  'validation-enddate-before-now': {
    fi: 'Päättymispäivämäärä ei voi olla menneisyydessä',
    sv: 'Slutdatumet kan inte ligga i det förflutna',
    en: 'The end date cannot be in the past',
  },
  'validation-enddate-too-far': {
    fi: 'Päättymispäivämäärä on liian kaukana tulevaisuudessa',
    sv: 'Slutdatumet ligger för långt fram i tiden',
    en: 'The end date is too far in the future',
  },

  // Calendar
  'calendar-jan': {
    fi: 'Tammikuu',
    sv: 'Januari',
    en: 'January',
  },

  'calendar-feb': {
    fi: 'Helmikuu',
    sv: 'Februari',
    en: 'February',
  },

  'calendar-mar': {
    fi: 'Maaliskuu',
    sv: 'Mars',
    en: 'March',
  },

  'calendar-apr': {
    fi: 'Huhtikuu',
    sv: 'April',
    en: 'April',
  },

  'calendar-may': {
    fi: 'Toukokuu',
    sv: 'Maj',
    en: 'May',
  },

  'calendar-jun': {
    fi: 'Kesäkuu',
    sv: 'Juni',
    en: 'June',
  },

  'calendar-jul': {
    fi: 'Heinäkuu',
    sv: 'Juli',
    en: 'July',
  },

  'calendar-aug': {
    fi: 'Elokuu',
    sv: 'Augusti',
    en: 'August',
  },

  'calendar-sep': {
    fi: 'Syyskuu',
    sv: 'September',
    en: 'September',
  },

  'calendar-oct': {
    fi: 'Lokakuu',
    sv: 'Oktober',
    en: 'October',
  },

  'calendar-nov': {
    fi: 'Marraskuu',
    sv: 'November',
    en: 'November',
  },

  'calendar-dec': {
    fi: 'Joulukuu',
    sv: 'December',
    en: 'December',
  },
  'calendar-mon': {
    fi: 'Ma',
    sv: 'Må',
    en: 'Mon',
  },
  'calendar-tue': {
    fi: 'Ti',
    sv: 'Ti',
    en: 'Tue',
  },
  'calendar-wed': {
    fi: 'Ke',
    sv: 'On',
    en: 'Wed',
  },
  'calendar-thu': {
    fi: 'To',
    sv: 'To',
    en: 'Thu',
  },
  'calendar-fri': {
    fi: 'Pe',
    sv: 'Fr',
    en: 'Fri',
  },
  'calendar-sat': {
    fi: 'La',
    sv: 'Lö',
    en: 'Sat',
  },
  'calendar-sun': {
    fi: 'Su',
    sv: 'Sö',
    en: 'Sun',
  },

  // Dialog window
  'dialog-ok': {
    fi: 'Ok',
    sv: 'Ok',
    en: 'OK',
  },
  'dialog-cancel': {
    fi: 'Peruuta',
    sv: 'Avbryt',
    en: 'Cancel',
  },
  'dialog-save': {
    fi: 'Tallenna',
    sv: 'Spara',
    en: 'Save',
  },
  'dialog-next': {
    en: 'Next',
    fi: 'Seuraava',
    sv: 'Nästa',
  },
  'dialog-back': {
    fi: 'Edellinen',
    sv: 'Tillbaka',
    en: 'Back',
  },

  // Generic
  'generic-show-more': {
    fi: 'Näytä enemmän',
    sv: 'Visa mer',
    en: 'Show more',
  },
  'generic-show-less': {
    fi: 'Näytä vähemmän',
    sv: 'Visa mindre',
    en: 'Show less',
  },

  // Price
  'includes-vat': {
    fi: '(sis. alv)',
    sv: '(inkl. moms)',
    en: '(incl. VAT)',
  },

  // For unit tests
  'test-languages': {
    fi: 'FI',
    sv: 'SV',
    en: 'EN',
  },
  'test-one-param': {
    fi: 'Yksi parametri $1 tässä.',
    sv: 'En parameter $ 1 här.',
    en: 'One parameter $1 here',
  },
  'test-two-params': {
    fi: 'Kaksi parametriä $1 tässä ja $2 tässä.',
    sv: 'Två parametrar $1 här och $2 här.',
    en: 'Two parameters $1 here and $2 here.',
  },

  //Template
  'template-link': {
    fi: 'Malli testi',
    sv: 'Modelltest',
    en: 'Template test',
  },
  'template-header': {
    fi: 'Malli',
    sv: 'Modell',
    en: 'Template',
  },
  'template-header-desc': {
    fi: 'Malli ...',
    sv: 'Modell ...',
    en: 'Template ...',
  },
  'template-account-creation-success': {
    fi: 'Malli ...',
    sv: 'Modell ...',
    en: 'Template ...',
  },
  'array-add-button': {
    fi: 'Lisää kohde',
    sv: 'Lägg till plats',
    en: 'Add location',
  },
  'array-remove-button': {
    fi: 'Poista kohde',
    sv: 'Ta bort plats',
    en: 'Remove location',
  },

  // Template errors
  'template-error-required': {
    fi: 'Pakollinen kenttä',
    sv: 'Obligatoriskt fält',
    en: 'Mandatory field',
  },
  'template-error-min-length': {
    fi: 'Kentän pituuden täytyy vähintään olla $1',
    sv: 'Fältets längd måste vara minst $1',
    en: 'The field length must be $1 at minimum',
  },
  'template-error-min-items': {
    fi: 'Vähintään $1 valinnoista pitää valita',
    sv: 'Åtminstone $1 av alternativen måste väljas',
    en: 'At least $1 of the options has to be selected',
  },
  'template-error-max-length': {
    fi: 'Kentän pituus saa olla korkeintaan $1',
    sv: 'Fältets längd får vara högst $1',
    en: 'The field length may be $1 at most',
  },
  'template-error-pattern': {
    fi: 'Kentän pitää vastata patternia $1',
    sv: 'Fältet måste matcha mönstret $1',
    en: 'The field must correspond to pattern $1',
  },
  'template-error-numbers-only': {
    fi: 'Vain numerot ovat sallittuja',
    sv: 'Endast siffror är tillåtna',
    en: 'Only numbers are permitted',
  },
  'template-error-format-date': {
    fi: 'Kentän sisällön pitää olla oikeassa muodossa oleva päiväys',
    sv: 'Fältinnehållet måste vara ett datum i rätt format',
    en: 'The field content must be a date in the correct format',
  },
  'template-error-date-format-invalid': {
    fi: 'Arvo ei ole oikea päivä.',
    sv: 'Värdet är inte rätt dag.',
    en: 'The value is not a valid date.',
  },
  'template-error-date-format-too-far-past': {
    fi: 'Päivä on liian kaukana menneisyydessä.',
    sv: 'Dagen ligger för långt tillbaka i tiden.',
    en: 'The date is too far in the past.',
  },
  'template-error-date-format-too-far-future': {
    fi: 'Päivä on liian kaukana tulevaisuudessa.',
    sv: 'Dagen ligger för långt in i framtiden.',
    en: 'The date is too far in the future.',
  },
  'template-error-type-string': {
    fi: 'Kentän arvo pitää olla merkkijono',
    sv: 'Fältets värde måste vara en teckensträng',
    en: 'The field value must be a string of characters',
  },
  'template-error-type-array': {
    fi: 'Arvon pitäisi olla taulukko',
    sv: 'Värdet ska vara en array',
    en: 'The value should be an array',
  },
  'template-error-format-email': {
    fi: 'Kentän arvo pitää olla email',
    sv: 'Fältet måste vara en e-post',
    en: 'The field value must be an e-mail address',
  },
  'template-error-number-value-too-small': {
    fi: 'Kentän arvo pitää olla vähintään $1',
    sv: 'Fältets värde måste vara minst $1',
    en: 'The field value must be $1 at minimum',
  },
  'template-error-number-value-too-big': {
    fi: 'Kentän arvo saa olla korkeintaan $1',
    sv: 'Fältets värde får vara högst $1',
    en: 'The field value may be $1 at most',
  },
  'template-error-format-phone': {
    fi: 'Kentän arvo pitää olla puhelinnumero',
    sv: 'Fältvärdet måste vara ett telefonnummer',
    en: 'The field value must be a phone number',
  },
  'template-error-enum-value': {
    fi: 'Arvo ei ole sallittu',
    sv: 'Värdet är inte tillåtet',
    en: 'The value is not allowed',
  },
  'template-error-additional-properties': {
    fi: 'Ylimääräisen datan syöttäminen ei ole sallittua',
    sv: 'Det är inte tillåtet att ange ytterligare data',
    en: 'Entering additional data is not allowed',
  },
  'template-error-checkbox-not-checked': {
    fi: 'Valinta täytyy hyväksyä',
    sv: 'Alternativet måste accepteras',
    en: 'The option must be accepted',
  },

  // Order product
  'order-product-waste-type-header': {
    fi: 'Valitse jätelaji',
    sv: 'Välj avfallstyp',
    en: 'Choose waste type',
  },
  'order-product-show-more': {
    fi: 'Näytä lisää vaihtoehtoja',
    sv: 'Visa fler alternativ',
    en: 'Show more options',
  },
  'order-product-show-less': {
    fi: 'Näytä vähemmän vaihtoehtoja',
    sv: 'Visa färre alternativ',
    en: 'Show less options',
  },
  'order-product-container-size-header': {
    fi: 'Valitse astian koko',
    sv: 'Välj kärlstorlek',
    en: 'Choose container size',
  },
  'order-product-contract-information-header': {
    fi: 'Sopimuksen tiedot',
    sv: 'Information om kontraktet',
    en: 'Contract information',
  },
  'order-product-contract-information-containers-label': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Number of containers',
  },
  'order-product-contract-information-date-label': {
    fi: 'Sopimuksen alkamispäivä',
    sv: 'Avtalets startdatum',
    en: 'Contract start date',
  },
  'order-product-container-ownership-header': {
    fi: 'Jäteastian omistus',
    sv: 'Kärltjänst',
    en: 'Waste container ownership',
  },
  'order-product-container-ownership-rent': {
    fi: 'Vuokraan jäteastian',
    sv: 'Jag hyr ett avfallskärl',
    en: 'I’d like to rent the container',
  },
  'order-product-container-ownership-buy': {
    fi: 'Ostan jäteastian',
    sv: 'Jag köper ett avfallskärl',
    en: 'I’d like to buy the container',
  },
  'order-product-container-ownership-owned': {
    fi: 'Kiinteistöllä on jo jäteastia',
    sv: 'Fastigheten har redan ett avfallskärl',
    en: 'The property already has a container',
  },
  'order-product-container-ownership-delivered': {
    fi: 'Haluan jäteastian toimitettuna',
    sv: 'Jag vill ha avfallsbehållaren levererad',
    en: 'I want the waste container delivered',
  },
  'order-product-emptying-rhythm-header': {
    fi: 'Tyhjennysrytmi',
    sv: 'Tömningsintervall',
    en: 'Emptying rhythm',
  },
  'order-product-emptying-rhythm-input-label': {
    fi: 'Astian tyhjennystiheys',
    sv: 'Kärl Tömningsintervall',
    en: 'Container emptying rhythm',
  },
  'order-product-emptying-rhythm-input-label-for-whole-year': {
    fi: 'Astian tyhjennystiheys koko vuodelle (1.1.-31.12.)',
    sv: 'Kärl Tömningsintervall för hela året (1.1.-31.12.)',
    en: 'Container emptying frequency for the whole year (1.1.-31.12.)',
  },
  'order-product-emptying-rhythm-input-label-for-summer': {
    fi: 'Astian tyhjennystiheys kesällä',
    sv: 'Kärl Tömningsintervall på sommaren',
    en: 'Container emptying rhythm for summer',
  },
  'order-product-emptying-rhythm-input-label-for-winter': {
    fi: 'Astian tyhjennystiheys talvella',
    sv: 'Kärl Tömningsintervall på vintern',
    en: 'Container emptying rhythm for winter',
  },
  'order-product-emptying-rhythm-times-a-week-label': {
    fi: 'Kertaa viikossa',
    sv: 'Gånger i veckan',
    en: 'Times a week',
  },
  'order-product-emptying-rhythm-validity': {
    fi: 'Voimassaolo',
    sv: 'Giltighet',
    en: 'Validity',
  },
  'order-product-emptying-rhythm-validity-start-week': {
    fi: 'Alkaen viikosta',
    sv: 'Från och med veckan',
    en: 'Start week',
  },
  'order-product-emptying-rhythm-weeks-cannot-overlap': {
    fi: 'Eri tyhjennysvälien viikot eivät saa olla päällekkäisiä',
    sv: 'Veckorna för olika tömningsintervall får inte överlappa',
    en: 'The weeks of different emptying intervals must not overlap',
  },
  'order-product-emptying-rhythm-validity-end-week': {
    fi: 'Päättyen viikkoon',
    sv: 'Till veckan',
    en: 'End week',
  },
  'order-product-emptying-rhythm-validity-add-interval': {
    fi: 'Lisää uusi tyhjennysväli',
    sv: 'Lägg till en ny tömningsintervall',
    en: 'Add new emptying interval',
  },
  'order-product-emptying-rhythm-validity-add-interval-disabled': {
    fi: 'Tyhjennysväliä ei voi lisätä, koska ensimmäinen tyhjennysväli kattaa jo koko vuoden',
    sv: 'Tömningintervall kan inte läggas till eftersom det första tömningsintervallet redan täcker hela året',
    en: 'Emptying interval cannot be added because the first emptying interval already covers the whole year',
  },
  'order-product-emptying-rhythm-validity-remove-interval': {
    fi: 'Poista tyhjennysväli',
    sv: 'Ta bort tömningsintervall',
    en: 'Remove emptying interval',
  },
  'order-product-emptying-rhythm-validity-whole-year': {
    fi: 'Sama tyhjennysväli koko vuoden',
    sv: 'Samma tömningsintervall under hela året',
    en: 'The same emptying interval throughout the year',
  },
  'order-product-emptying-rhythm-validity-winter-summer': {
    fi: 'Eri tyhjennysväli kesällä ja talvella',
    sv: 'Olika tömningsintervall på sommaren och vintern',
    en: 'Different emptying intervals for summer and winter',
  },
  'order-product-emptying-rhythm-validity-winter-summer-disabled': {
    fi: 'Tälle tuotteelle ei ole mahdollista valita kesä- ja talvirytmiä erikseen.',
    sv: 'Det är inte möjligt att välja sommar- och vinterrytm separat för den här produkten.',
    en: 'It is not possible to choose summer and winter rhythm separately for this product.',
  },
  'order-product-emptying-rhythm-week-text': {
    fi: 'Viikko',
    sv: 'Vecka',
    en: 'Week',
  },
  'order-product-emptying-rhythm-not-available-due-to-form-settings': {
    fi: 'Tyhjennysrytmiä ei löytynyt. Ole hyvä ja tarkista lomakkeen asetukset',
    sv: 'Ingen Tömningsintervall hittades. Vänligen kontrollera formulärets inställningar',
    en: 'No emptying rhythm found. Please check the form settings',
  },
  'order-product-emptying-rhythm-not-available-due-to-admin-settings': {
    fi: 'Sopivaa tyhjennysrytmiä ei löytynyt. Ole hyvä ja tarkista sähköisen asioinnin asetukset',
    sv: 'Ingen lämplig Tömningsintervall hittades.  Vänligen kontrollera inställningarna för elektroniska tjänster',
    en: 'No suitable emptying rhythm found. Please check the settings for electronic services',
  },
  'order-product-emptying-rhythm-overlapping-weeks-due-to-admin-settings': {
    fi: 'Tyhjennysväleissä on päällekkäisiä viikkoja asetuksissa',
    sv: 'Det finns överlappande veckor i tömningsintervallen i inställningarna',
    en: 'There are overlapping weeks in the emptying intervals in the settings',
  },
  'order-product-driver-info-header': {
    fi: 'Kuljettajalle',
    sv: 'Till chauffören',
    en: 'For the driver',
  },
  'order-product-driver-info-gate-key': {
    fi: 'Portin avainkoodi',
    sv: 'Nyckelkod till porten',
    en: 'Key code for the gate',
  },
  'order-product-driver-info-additional-information': {
    fi: 'Lisätiedot',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  'order-product-price-header': {
    fi: 'Hinta',
    sv: 'Pris',
    en: 'Price',
  },
  'order-product-service-header': {
    fi: 'Palvelu',
    sv: 'Tjänst',
    en: 'Service',
  },
  'order-product-unit-price-header': {
    fi: 'Yksikköhinta',
    sv: 'Styckpris',
    en: 'Unit price',
  },
  'order-product-price-container-collection': {
    fi: 'Astioiden tyhjennys (sis. alv)',
    sv: 'Tömning av kärl (moms ingår)',
    en: 'Emptying the container (VAT included)',
  },
  'order-product-amount-header': {
    fi: 'Määrä',
    sv: 'Mängd',
    en: 'Quantity',
  },
  'order-product-total-header': {
    fi: 'Yhteensä',
    sv: 'Totalt',
    en: 'Total',
  },
  'order-product-row-waste-management': {
    fi: 'Jätteenkäsittely',
    sv: 'Avfallshantering',
    en: 'Waste management',
  },
  'order-product-price-waste-management': {
    sv: 'Avfallshantering',
    fi: 'Jätteen käsittely',
    en: 'Waste management',
  },
  'order-product-price-total-price': {
    fi: 'Yhteensä (sis. alv)',
    sv: 'Totalt pris (moms ingår)',
    en: 'Total price (VAT included)',
  },
  'order-product-not-available': {
    fi: 'Ei valittavia tuotteita',
    sv: 'Inga produkter tillgängliga',
    en: 'Not available',
  },
  'order-product-summary-product-information-header': {
    fi: 'Palvelun tiedot',
    sv: 'Tjänstinformation',
    en: 'Product information',
  },
  'order-product-summary-waste-type-and-container-header': {
    fi: 'Jätelaji ja astia',
    sv: 'Avfallstyp och kärl',
    en: 'Waste type and container',
  },
  'order-product-summary-waste-type': {
    fi: 'Jätelaji',
    sv: 'Avfallstyp',
    en: 'Waste type',
  },

  'order-product-summary-container-size': {
    fi: 'Astian koko',
    sv: 'Kärl storlek',
    en: 'Container size',
  },

  'order-product-summary-container-ownership': {
    fi: 'Jäteastian omistus',
    sv: 'Ägande av avfallsbehållare',
    en: 'Waste container ownership',
  },
  'order-product-summary-contract-information-header': {
    fi: 'Sopimuksen tiedot',
    sv: 'Information om kontraktet',
    en: 'Contract information',
  },
  'order-product-summary-number-of-containers': {
    fi: 'Astioiden määrä',
    sv: 'Antal kärl',
    en: 'Number of containers',
  },
  'order-product-summary-contract-start-date': {
    fi: 'Sopimuksen alkamispäivä',
    sv: 'Avtalets startdatum',
    en: 'Contract start date',
  },
  'order-product-summary-emptying-intervals-header': {
    fi: 'Tyhjennysrytmi',
    sv: 'Tömningsintervall',
    en: 'Emptying rhythm',
  },

  'order-product-summary-contract-validity-weeks': {
    fi: 'Viikot',
    sv: 'Veckor',
    en: 'Weeks',
  },
  'order-product-summary-contract-times-a-week': {
    fi: 'Kertaa viikossa',
    sv: 'Gånger i veckan',
    en: 'Times a week',
  },
  'order-product-summary-contract-once-a-week': {
    fi: 'Viikon välein',
    sv: 'En gång i veckan',
    en: 'Once a week',
  },
  'order-product-summary-contract-every-n-weeks': {
    fi: '$1 viikon välein',
    sv: 'Varannan $1 vecka',
    en: 'Every $1 weeks',
  },
  'order-product-summary-info-for-driver-header': {
    fi: 'Kuljettajalle',
    sv: 'Till chauffören',
    en: 'For the driver',
  },
  'order-product-summary-gate-key-code': {
    fi: 'Portin avainkoodi',
    sv: 'Nyckelkod till porten',
    en: 'Key code for the gate',
  },
  'order-product-summary-additional-information': {
    fi: 'Lisätiedot',
    sv: 'Tilläggsinformation',
    en: 'Additional information',
  },
  'order-product-summary-price-header': {
    fi: 'Hinta',
    sv: 'Pris',
    en: 'Price',
  },

  //Permit Application
  'permit-application': {
    fi: 'Lupahakemukset',
    sv: 'Tillståndsansökningar',
    en: 'Permit applications',
  },
  'permit-application-desc': {
    fi: 'Kaikki tekemäsi lupahakemukset löytyvät tältä sivulta',
    sv: 'Alla tillståndansköningar som ni har gjort hittas på den här sidan',
    en: 'All permit applications you’ve made are found on this site',
  },
  'permit-application-new': {
    fi: 'Tee lupahakemus',
    sv: 'Gör tillståndsansökning',
    en: 'New permit application ',
  },
  'permit-application-previous': {
    fi: 'Aiemmat hakemukset',
    sv: 'Tidigare ansökningar',
    en: 'Sent applications',
  },
  'add-new-permit-application-success-message': {
    fi: 'Lupahakemus vastaanotettu',
    sv: 'Tillståndsansökning har mottagits',
    en: 'Permit application received',
  },
  'permit-application-extended-emptying-interval': {
    fi: 'Hakemus pidennetty tyhjennysväli',
    sv: 'Ansökan om förlängt tömningsintervall',
    en: 'Application for an extended collection interval',
  },
  'permit-application-shared-container': {
    fi: 'Hakemus yhteinen jäteastia',
    sv: 'Ansökan om gemensamt avfallskärl',
    en: 'Application for a shared container',
  },
  'permit-application-pdf': {
    fi: 'Avaa päätöksen kuva',
    sv: 'Öppna beslutsbilden',
    en: 'Open permit decision',
  },
  'permit-application-waste-type': {
    fi: 'Jätelaji',
    sv: 'Avfallstyp',
    en: 'Waste type',
  },
  'permit-application-status': {
    fi: 'Tilanne',
    sv: 'Situation',
    en: 'Status',
  },
  'permit-application-status-sent': {
    fi: 'Lähetetty käsittelyyn',
    sv: 'Skickad för behandling',
    en: 'Sent for processing',
  },
  'permit-application-status-inprocess': {
    fi: 'Odottaa käsittelyä',
    sv: 'Väntar på behandling',
    en: 'Waiting to be processed',
  },
  'permit-application-status-ready': {
    fi: 'Käsitelty',
    sv: 'Behandlad',
    en: 'Ready',
  },
  'permit-application-no-forms-title': {
    fi: 'Ei valittavia lomakkeita',
    sv: 'Inga formulär att välja mellan',
    en: 'No forms to choose from',
  },
  'permit-application-no-forms-text': {
    fi: 'Valitulla kielellä ei ole aktiivisia lomakkeita.',
    sv: 'Det finns inga aktiva formulär på det valda språket.',
    en: 'There are no active forms in the selected language.',
  },
  'permit-application-submit': {
    fi: 'Lähetä',
    sv: 'Skicka',
    en: 'Send',
  },
  'permit-application-table-header-subject': {
    fi: 'Aihe',
    sv: 'Ämne',
    en: 'Subject',
  },

  'permit-application-table-header-application-sent': {
    fi: 'Hakemus jätetty',
    sv: 'Ansökan lämnad',
    en: 'Application sent',
  },

  'permit-application-table-header-status': {
    fi: 'Tilanne',
    sv: 'Status',
    en: 'Status',
  },

  'permit-application-table-header-permit': {
    fi: 'Lupa',
    sv: 'Tillstånd',
    en: 'Permit',
  },

  //Composting notifications
  'composting-notifications': {
    fi: 'Kompostointi-ilmoitukset',
    sv: 'Komposteringsanmälan',
    en: 'Composting notifications',
  },
  'composting-notifications-desc': {
    fi: 'Ilmoitus kompostoinnista tulee uusia viiden vuoden välein',
    sv: 'Anmälan om kompostering ska förnyas vart femte år',
    en: 'The notification on composting must be renewed every five years',
  },
  'add-new-composting-notification-success-message': {
    fi: 'Kompostointi-ilmoitus lähetetty',
    sv: 'Komposteringsanmälan skickat',
    en: 'Composting notice sent',
  },
  'new-composting-notification': {
    fi: 'Tee kompostointi-ilmoitus',
    sv: 'Gör Komposteringsanmälan',
    en: 'Create a composting notice',
  },

  'composting-notifications-header': {
    fi: 'Kompostointi',
    sv: 'Kompostering',
    en: 'Composting',
  },

  'composting-notifications-header-description': {
    fi: 'Ilmoitus kompostoinnista tulee uusia viiden vuoden välein',
    sv: 'Anmälan om kompostering ska förnyas vart femte år',
    en: 'The notification on composting must be renewed every five years',
  },

  'composting-notifications-location-column': {
    fi: 'Kohde',
    sv: 'Plats',
    en: 'Location',
  },

  'composting-notifications-notification-sent-column': {
    fi: 'Ilmoitus jätetty',
    sv: 'Meddelande lämnad',
    en: 'Notification left',
  },

  'composting-notifications-notifier-column': {
    fi: 'Ilmoittaja',
    sv: 'Anmälare',
    en: 'Notifier',
  },

  'composting-notifications-notification-to-be-remade-column': {
    fi: 'Ilmoitus uusittava',
    sv: 'Anmälan ska förnyas',
    en: 'Notification to be renewed',
  },

  'composting-notifications-status-column': {
    fi: 'Tilanne',
    sv: 'Status',
    en: 'Status',
  },

  'composting-notifications-status-ready': {
    fi: 'Käsitelty',
    sv: 'Behandlad',
    en: 'Ready',
  },

  'composting-notifications-status-expired': {
    fi: 'Vanhentunut',
    sv: 'Förfallen',
    en: 'Expired',
  },

  'composting-notifications-status-expiring-soon': {
    fi: 'Menossa umpeen',
    sv: 'Håller på att föralla',
    en: 'Expiring soon',
  },

  'consumer-einvoice-type-not-in-use': {
    fi: 'Paperilaskuna',
    sv: 'Som pappersfaktura',
    en: 'As a paper bill',
  },
  'consumer-einvoice-type-email-invoicing': {
    fi: 'Sähköpostiini',
    sv: 'till min e-post',
    en: 'to my e-mail',
  },
  'consume-einvoice-type-consumer-channel': {
    fi: 'Verkkolaskuna',
    sv: 'Som online faktura',
    en: 'As online invoice',
  },

  // Reporting
  reporting: {
    fi: 'Raportointi',
    sv: 'Rapporteringen',
    en: 'Reporting',
  },
  'reporting-header': {
    fi: 'Raportointi',
    sv: 'Rapporteringen',
    en: 'Reports',
  },
  'reporting-description': {
    fi: 'Yhteenvedot ja raportit kohteistasi',
    sv: 'Sammanfattning och rapporter om dina platser',
    en: 'Summary and reports about your properties',
  },

  // Waste unit types
  'waste-unit-type-Unknown': {
    fi: '',
    sv: '',
    en: '',
  },
  'waste-unit-type-Piece': {
    fi: 'kpl',
    sv: 'st',
    en: 'pcs',
  },
  'waste-unit-type-Kilogram': {
    fi: 'kg',
    sv: 'kg',
    en: 'kg',
  },
  'waste-unit-type-Ton': {
    fi: 'tn',
    sv: 'tn',
    en: 'tn',
  },
  'waste-unit-type-Liter': {
    fi: 'l',
    sv: 'l',
    en: 'l',
  },
  'waste-unit-type-CubicMeter': {
    fi: 'm3',
    sv: 'm3',
    en: 'm3',
  },
  'waste-unit-type-SquareMeter': {
    fi: 'm2',
    sv: 'm2',
    en: 'm2',
  },
  'waste-unit-type-Meter': {
    fi: 'm',
    sv: 'm',
    en: 'm',
  },
  'waste-unit-type-Kilometer': {
    fi: 'km',
    sv: 'km',
    en: 'km',
  },
  'waste-unit-type-MegaWattHour': {
    fi: 'Mwh',
    sv: 'Mwh',
    en: 'Mwh',
  },
  'waste-unit-type-Hour': {
    fi: 'h',
    sv: 'h',
    en: 'h',
  },
  'waste-unit-type-Day': {
    fi: 'vrk',
    sv: 'dag',
    en: 'day',
  },
  'waste-unit-type-Month': {
    fi: 'kk',
    sv: 'mån',
    en: 'mo',
  },
  'waste-unit-type-Year': {
    fi: 'v',
    sv: 'år',
    en: 'yr',
  },

  //Well Collection
  'well-collection-menu-create-notification-item': {
    fi: 'Tee kaivoilmoitus',
    sv: 'Gör en avloppsvattenanmälan',
    en: 'Make a wastewater report',
  },
  'emptying-location-waste-water-header': {
    fi: 'Jätevesiselvitys',
    sv: 'Avloppsvattenutredning',
    en: 'Waste water investigation',
  },
  'well-collection-property-water-supply': {
    fi: 'Kiinteistön käyttövedet',
    sv: 'Fastighetens vattenförsörjning',
    en: `The property's water supply`,
  },
  'well-collection-property-water-supply-title': {
    fi: 'Kiinteistön käyttövesien tiedot',
    en: `Information about the property's water supply`,
    sv: 'Information om fastighetens vattenförsörjning',
  },
  'well-collection-property-water-supply-wc-type': {
    fi: 'Käymälätyyppi',
    en: 'Toilet type',
    sv: 'Typ av toalett',
  },
  'well-collection-property-water-supply-water-wc': {
    fi: 'Vesi WC',
    en: 'Water toilet',
    sv: 'Vattentoalett',
  },
  'well-collection-property-water-supply-non-water-wc': {
    fi: 'Kuiva, kompostoiva, jäädyttävä tai polttava käymälä',
    en: 'Dry, compost, freezing or burning toilet',
    sv: 'Torr-, komposterande-, frys- eller förbrännande toalett',
  },
  'well-collection-property-water-supply-household-water-source': {
    fi: 'Talousveden saanti',
    en: 'Access to household water',
    sv: 'Tillgång till hushållsvatten',
  },
  'well-collection-property-water-supply-household-water-pressure': {
    fi: 'Painevesi',
    en: 'Pressurized water',
    sv: 'Tryckvatten',
  },
  'well-collection-property-water-supply-household-water-carried': {
    fi: 'Kantovesi',
    en: 'Carried water',
    sv: 'Bärvatten',
  },
  'well-collection-property-water-supply-equipment-level': {
    fi: 'Varustelutaso',
    en: 'Equipment level',
    sv: 'Utrustningsnivå',
  },
  'well-collection-property-water-supply-equipment-shower': {
    fi: 'Suihku',
    en: 'Shower',
    sv: 'Dusch',
  },
  'well-collection-property-water-supply-equipment-dishwasher': {
    fi: 'Astianpesukone',
    en: 'Dishwasher',
    sv: 'Diskmaskin',
  },
  'well-collection-property-water-supply-equipment-washing-machine': {
    fi: 'Pesukone',
    en: 'Washing machine',
    sv: 'Tvättmaskin',
  },
  'well-collection-waste-water-treatment': {
    fi: 'Kiinteistön jätevesien käsittely',
    sv: 'Rening av avloppsvattnet från fastigheten',
    en: 'Property wastewater treatment',
  },
  'well-collection-waste-water-report-summary': {
    fi: 'Yhteenveto',
    sv: 'Sammanfattning',
    en: 'Summary',
  },

  //Waste Water
  'waste-water-investigation-header': {
    fi: 'Jätevesiselvitys',
    sv: 'Avloppsvattenutredning',
    en: 'Waste water investigation',
  },
  'waste-water-connection-sewer-network': {
    fi: 'Liitos kunnalliseen viemäriverkostoon',
    sv: 'Anslutning till kommunalt avloppsnät',
    en: 'Connection to the municipal sewer network',
  },
  'waste-water-sewer-network-connected': {
    fi: 'Kiinteistö on liitetty viemäriverkostoon',
    sv: 'Fastigheten är ansluten till avloppsnätet',
    en: 'The property is connected to the sewer network',
  },
  'waste-water-sewer-network-not-connected': {
    fi: 'Kiinteistöä ei ole liitetty viemäriverkostoon',
    sv: 'Fastigheten är inte ansluten till avloppsnätet',
    en: 'The property is not connected to the sewer network',
  },
  'waste-water-sewer-network-connected-confirmation-message': {
    fi: 'Tehtävä onnistui. Kiitos ilmoituksesta',
    sv: 'Uppdraget var framgångsrikt. Tack för meddelandet.',
    en: 'The task was successful. Thanks for the notice.',
  },

  'waste-water-treatment-header': {
    fi: 'Kiinteistön jätevesien käsittelytiedot',
    sv: 'Rening information av avloppsvattnet från fastigheten',
    en: 'Property wastewater treatment information',
  },
  'waste-water-treatment-options-header': {
    fi: 'Jätevedet johdetaan',
    sv: 'Avloppsvattnet leds bort',
    en: 'Wastewater is diverted',
  },

  'waste-water-treatment-option-A': {
    fi: 'Saostussäiliö, joka sisältää vain pesuvettä (harmaa vesi)',
    sv: 'Slambehållare som innehåller endast tvättvatten (gråvatten)',
    en: 'Septic tank that contains only washing water (gray water)',
  },
  'waste-water-treatment-option-B': {
    fi: 'Saostussäiliö, joka sisältää käymälävettä ja pesuvettä (musta- ja harmaa vesi)',
    sv: 'Slambehållare som innehåller toalettvatten och tvättvatten (svartvatten och gråvatten)',
    en: 'Septic tank that contains toilet water and washing water (black and gray water)',
  },
  'waste-water-treatment-option-C': {
    fi: 'Pienpuhdistamo, joka sisältää vain pesuvettä',
    sv: 'Minireningsverk för enbart tvättvatten',
    en: 'Small sewage treatment plant that only contains washing water',
  },
  'waste-water-treatment-option-D': {
    fi: 'Pienpuhdistamo, joka sisältää käymäläjätevettä ja pesuvettä',
    sv: 'Minireningsverk som innehåller toalettavloppsvatten och tvättvatten (svartvatten och gråvatten)',
    en: 'Small sewage treatment plant that contains toilet waste water and washing water',
  },
  'waste-water-treatment-option-E': {
    fi: 'Umpisäiliö, joka sisältää käymäjätevettä ja pesuvettä',
    sv: 'Sluten tank som innehåller toalettavloppsvatten och tvättvatten (svartvatten och gråvatten)',
    en: 'Closed tank that contains toilet waste water and washing water',
  },
  'waste-water-treatment-option-F': {
    fi: 'Umpisäiliö, joka sisältää käymäläjätevettä',
    sv: 'Sluten tank som innehåller toalettavloppsvatten (svartvatten)',
    en: 'Closed tank that contains toilet waste water',
  },
  'waste-water-treatment-option-G': {
    fi: 'Muu, mikä?',
    sv: 'Annat, vad?',
    en: 'Other, what?',
  },
  'waste-water-treatment-option-G-secondary': {
    fi: 'Muu',
    sv: 'Annat',
    en: 'Other',
  },
  'waste-water-treatment-validation-message-wc-water': {
    fi: 'Valitse vaihtoehto sekä harmaalle että mustalle vedelle',
    sv: 'Välj ett alternativ för både grå- och svartvatten',
    en: 'Choose an option for both gray and black water',
  },
  'waste-water-treatment-validation-message-wc-water-gray': {
    fi: 'Valitse vaihtoehto harmaan veden käsittelylle',
    sv: 'Välj ett alternativ för gråvatten',
    en: 'Choose an option for gray water',
  },
  'waste-water-popover-content': {
    fi: `Lietesäiliöiden tyhjennykset säännöllisesti tai tilauksesta: \n\n \u2022 Kunnan jätehuoltomääräykset määrittelevät lietekaivojen tyhjennysvälit ja ne ovat kaikille samat riippumatta siitä, onko lietteenkuljetus kiinteistön haltijan tai kunnan järjestämä.\n\nLietesäiliöt, joissa käsitellään vesikäymäläjätevesiä:\n\n \u2022 Useamman kuin yhden asukkaan vakituisten asuinkiinteistöjen saostussäiliöistä, pienpuhdistamojen lietetiloista ja muista vastaavista lietesäiliöistä on jätevesiliete poistettava vähintään kerran vuodessa.\n\n \u2022 Yhden asukkaan vakituisten asuinkiinteistöjen ja vapaa-ajan asuinkiinteistöjen saostussäiliöistä, pienpuhdistamojen lietetiloista ja muista vastaavista lietesäiliöistä on jätevesiliete poistettava vähintään kahden vuoden välein.\n\nLietesäiliöt, joissa käsitellään vain pesuvesiä:\n\n \u2022 Saostussäiliöistä, pienpuhdistamojen lietetiloista ja muista vastaavista lietesäiliöistä on jätevesiliete poistettava vähintään kolmen vuoden välein.\n\nUmpisäiliöt:\n\n \u2022 Umpisäiliöt tyhjennetään tarvittaessa mutta kuitenkin vähintään viiden vuoden välein. Umpisäiliöt voidaan tyhjentää joko säännöllisesti sovitun rytmin mukaisesti tai tarvittaessa tilauksesta. Seuraathan umpisäiliön täyttymistä esimerkiksi hälyttimen avulla, jotta voit tilata tyhjennyksen ajoissa.`,
    sv: `Slambehållare där avloppsvatten från vattentoaletter behandlas slambehållare där endast tvättvatten behandlas slutna tankar\n\n \u2022 Avloppsslam ska avlägsnas minst en gång om året från slamavskiljare och minireningsverk i bostadsfastigheter med fler än en stadigvarande invånare.\n \u2022 Avloppsslam från slamavskiljare och minireningsverk ska avlägsnas minst vartannat år i stadigvarande bostäder med en invånare samt i fritidsbostäder.\n\nSlambehållare där endast tvättvatten behandlas\n\n \u2022 Från slamavskiljare och minireningsverk ska avloppsslam avlägsnas minst vart tredje år.\n\nSlutna tankar\n \u2022 Slutna tankar ska tömmas vid behov och senast när de fylls.\n \u2022 Om fastigheten används ska den slutna tanken dock tömmas och inspekteras minst vart femte år.`,
    en: 'Emptying sludge tanks regularly or on request: \n\n \u2022The municipal waste management regulations define the emptying intervals of sludge tanks and they are the same for everyone, regardless of whether the sludge transport is organized by the property owner or the municipality.\n\nSludge tanks for the treatment of toilet wastewater:\n\n \u2022 Sewage sludge shall be emptied from septic tanks, sludge rooms of small treatment plants and other similar sludge tanks of permanent residential properties with more than one resident at least once a year.\n\n \u2022 Sewage sludge must be removed from septic tanks, sludge rooms of small treatment plants and other similar sludge tanks in permanent residential and leisure properties of one resident at least every two years.\n\nSludge tanks treating only washing water:\n\n \u2022 Sewage sludge shall be removed from closed tanks, sludge rooms of small treatment plants and other similar sludge tanks at least once every three years.\n\nSealed tanks:\n\n \u2022 Sealed tanks are emptied when necessary, but at least every five years. Sealed tanks can be emptied either regularly according to an agreed rhythm or, if necessary, on request. Please monitor the filling of the sealed tank with an alarm, for example, so that you can order emptying in time.',
  },

  //septic tank form
  'septic-tank-form-head': {
    fi: 'Jätevedet johdetaan',
    sv: 'Avloppsvattnet leds bort',
    en: 'Wastewater is diverted',
  },
  'septic-tank-covers-number': {
    fi: 'Kansien lukumäärä',
    sv: 'Antal lock',
    en: 'Number of covers',
  },
  'septic-tank-volume': {
    fi: 'Tilavuus, m³',
    sv: 'Volym, m³',
    en: 'Volume, m³',
  },
  'septic-tank-access-limitations-header': {
    fi: 'Lietekaivolle pääsy',
    sv: 'Tillgång till slambrunnen',
    en: 'Access to the sludge well',
  },
  'septic-tank-access-limitations-option-no-limits': {
    fi: 'Ei rajoitteita',
    sv: 'Inga begränsningar',
    en: 'No constraints',
  },
  'septic-tank-access-limitations-option-no-winter': {
    fi: 'Ei voi tyhjentää talvikaudella (marraskuu-maaliskuu, viikot 44-13)',
    sv: 'Kan inte tömmas under vintersäsongen (november-mars, veckor 44-13)',
    en: 'Cannot be emptied during the winter season (November-March, weeks 44-13)',
  },
  'septic-tank-access-limitations-option-locked': {
    fi: 'Lukittu portti/puomi/kansi tms.',
    sv: 'Låst grind/bom/lock mm.',
    en: 'Locked gate/boom/cover etc.',
  },
  'septic-tank-access-limitations-option-weight-limit': {
    fi: 'Kiinteistölle johtaa painorajoitettu tie tai silta, ilmoita rajoitus',
    sv: 'Till fastigheten leder tyngdbegränsad väg eller bro, meddela om begränsningen ',
    en: 'To the property leads a road or bridge with restrictions, inform about the restrictions',
  },
  'septic-tank-access-limitations-weight-limit-details': {
    fi: 'Painorajoitus',
    sv: 'Viktbegränsning',
    en: 'Weight restriction',
  },
  'septic-tank-access-limitations-option-weight-expections': {
    fi: 'Muuta huomioitavaa” -kohtaan sulkeisiin esimerkkeinä (esim. Säiliö on yli 5 m alempana kuin tyhjennysauto, letkuntarve yli ….m    Kiinteistölle johtaa painorajoitettu tie tai silta, ilmoita rajoitus',
    sv: 'Andra saker att uppmärksamma" som exempel (t.ex. tanken placerad mer än 5 m lägre än tömningsbilen, behovet av en slang är över …… m',
    en: 'Other things to consider" for example (e.g. The tank is more than 5 m lower than the sludge car, the need for a hose is more than …. m',
  },
  'septic-tank-access-limitations-option-weight-other': {
    fi: 'Muuta huomioitavaa',
    sv: 'Andra saker',
    en: 'Other concern',
  },
  'septic-tank-access-limitations-other-details': {
    fi: 'Lisätiedot (Esim. Säiliö on yli 5 m alempana kuin tyhjennysauto, letkuntarve yli 45 m)',
    sv: 'Andra saker att uppmärksamma" som exempel (t.ex. tanken placerad mer än 5 m lägre än tömningsbilen, behovet av en slang är över 45 m',
    en: 'Other things to consider" for example (e.g. The tank is more than 5 m lower than the sludge car, the need for a hose is more than 45 m',
  },
  'septic-tank-access-limitations-location': {
    fi: 'Tarkka sijainti tontilla',
    sv: 'Exakt läge på tomten',
    en: 'Exact location on the plot',
  },

  //other-form
  'other-grey-water-filter-option': {
    fi: 'Harmaavesisuodatin',
    sv: 'Gråvattenfilter',
    en: 'Gray water filter',
  },
  'other-ground-filter-option': {
    fi: 'Maasuodatin',
    sv: 'Markfilter',
    en: 'Ground filter',
  },
  'other-no-waste-water-treatment-system-option': {
    fi: 'Ei jätteenkäsittelyjärjestelmää',
    sv: 'Inget behandlingssystem av avloppsvatten',
    en: 'No waster water treatment system',
  },
  'other-no-waste-water-treatment-system-input': {
    fi: 'Miten jätevedet käsitellään kiinteistöllä?',
    sv: 'Så hanteras avloppsvatten i fastigheten',
    en: 'How waste water is handled in the property?',
  },

  //treatment plant
  'treatment-plant-manufacturer': {
    fi: 'Valmistaja',
    sv: 'Tillverkare',
    en: 'Manufacturer',
  },
  'treatment-plant-model': {
    fi: 'Malli',
    sv: 'Modell',
    en: 'Model',
  },
  'treatment-plant-emptying-guide': {
    fi: 'Tyhjennysohje',
    sv: 'Tömningsanvisning',
    en: 'Emptying guide',
  },
  'treatment-plant-volume': {
    fi: 'Tilavuus, m³ ',
    sv: 'Volym, m³',
    en: 'Volume, m³',
  },
  'treatment-plant-vacuum-truck': {
    fi: 'Tyhjennetään imuautolla',
    sv: 'Töms med Vakuumbil',
    en: 'Emptying with a vacuum truck',
  },
  'treatment-plant-vacuum-yes-options': {
    fi: 'Kyllä',
    sv: 'Ja',
    en: 'Yes',
  },
  'treatment-plant-vacuum-no-options': {
    fi: 'Ei',
    sv: 'Nej',
    en: 'No',
  },
  'treatment-plant-vacuum-info-notice': {
    fi: 'Muistathan ilmoittaa omatoimisesta käsittelystä jätelautakunnalle',
    sv: 'Kom ihåg att informera avfallsnämnden om egenhantering',
    en: 'Please remember to inform the waste board about self-handling',
  },

  //closed tank form
  'closed-tank-form-head': {
    fi: 'Jätevedet johdetaan',
    sv: 'Jätevedet johdetaan',
    en: 'Jätevedet johdetaan',
  },
  'closed-tank-volume': {
    fi: 'Tilavuus, m³',
    sv: 'Volym, m³',
    en: 'Volume, m³',
  },

  //need for emptying form
  'need-for-emptying-closed-tank-weekly-frequency-on-demand': {
    fi: 'Tilauksesta',
    sv: 'På beställning',
    en: 'On demand',
  },
  'need-for-emptying-weekly-frequency-other': {
    fi: 'Muu',
    sv: 'Annat',
    en: 'Other',
  },
  'need-for-emptying-closed-tank-other-input-label': {
    fi: 'Lisätietoja',
    sv: 'Tillägsinformation',
    en: 'Additional information',
  },
  'need-for-emptying-black-water-resident-count': {
    fi: 'Asukkaiden lukumäärä',
    sv: 'Befolkning',
    en: 'Resident count',
  },
  'need-for-emptying-weekly-frequency-half-year': {
    fi: 'Puolen vuoden välein',
    sv: 'Var sjätte månad',
    en: 'Every half a year',
  },
  'need-for-emptying-weekly-frequency-year': {
    fi: 'Vuoden välein',
    sv: 'En gång om året',
    en: 'Once a year',
  },
  'need-for-emptying-weekly-frequency-two-year': {
    fi: '2 vuoden välien',
    sv: 'Vartannat år',
    en: 'Every second year',
  },
  'need-for-emptying-weekly-frequency-three-year': {
    fi: '3 vuoden välein',
    sv: 'Vart tredje år',
    en: 'Every three years',
  },
  'need-for-emptying-weekly-frequency-dropdown-label': {
    fi: 'Tyhjennystarve',
    sv: 'Veckofrekvens',
    en: 'Weekly frequency',
  },
  'need-for-emptying-other-input-label': {
    fi: 'Mikä?',
    sv: 'Vad?',
    en: 'What?',
  },
  'septic-tank-access-limitations-take-other-steps': {
    fi: 'Muita huomioitavia asioita',
    sv: 'Andra saker att tänka på',
    en: 'Other things to consider',
  },
  //well-collection-summary
  'property-water-supply-summary-title': {
    fi: 'Kiinteistön käyttövedet',
    sv: 'Fastighetens vattenförsörjning',
    en: `The property's water supply`
  },
  'property-water-supply-summary-information-title': {
    fi: 'Kiinteistön käyttövesien tiedot',
    sv: 'Information om fastighetens vattenförsörjning',
    en: `Information about the property's water supply`
  },
  'property-water-supply-summary-wc-type': {
    fi: 'Käymälätyyppi',
    sv: 'Typ av toalett',
    en: 'Toilet type'
  },
  'property-water-supply-summary-household-water-source': {
    fi: 'Talousveden saanti',
    sv: 'Tillgång till hushållsvatten',
    en: 'Access to household water'
  },
  'property-water-supply-summary-household-equipment-level': {
    fi: 'Varustelutaso',
    sv: 'Utrustningsnivå',
    en: 'Equipment level'
  },
  'property-waste-water-treatment-summary-header': {
    fi: 'Kiinteistön jätevesien käsittely',
    en: 'Property wastewater treatment information',
    sv: 'Rening information av avloppsvattnet från fastigheten'
  },
  'property-waste-water-treatment-summary-fields-header': {
    fi: 'Kiinteistön jätevesien käsittelytiedot',
    en: 'Property wastewater treatment information',
    sv: 'Rening information av avloppsvattnet från fastigheten'
  },
  'property-waste-water-treatment-summary-fields-description': {
    fi: 'Jätevedet johdetaan',
    en: 'Wastewater is diverted',
    sv: 'Avloppsvattnet leds bort'
  },
};

export function checkIfUiKeyExists(value: string): boolean {
  const found = Object.keys(uiTexts).find((x) => x === value);
  return found ? true : false;
}

const getText = (lang: Language) => {
  return (overrideTexts?: TranslationsObject) => {
    return (key: keyof UiTexts, params?: string[]) => {
      let text = '';
      if (
        overrideTexts &&
        Object.prototype.hasOwnProperty.call(overrideTexts, key) &&
        Object.prototype.hasOwnProperty.call(overrideTexts[key], lang)
      ) {
        text = overrideTexts[key][lang];
      } else {
        text = uiTexts[key][lang];
      }
      if (params) {
        return replacePlaceholders(text, params);
      }
      return text;
    };
  };
};

function replacePlaceholders(text: string, params: string[]): string {
  let textWithParams = text;
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    textWithParams = textWithParams.replace(`$${i + 1}`, param);
  }
  return textWithParams;
}

type LangContextValue = {
  lang: Language;
  getText: (key: keyof UiTexts, params?: string[]) => string;
  publicHolidays: Date[];
  overrideTexts?: TranslationsObject;
};

const LanguageContext = createContext<LangContextValue>({
  lang: 'fi',
  getText: getText('fi')(),
  publicHolidays: [],
  overrideTexts: undefined,
});

export { getText, LanguageContext };
