import { FC, useContext } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormHandleSubmit } from 'react-hook-form';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import { EditBillingInfoFormData } from './EditBillingInfo';
import { LanguageContext } from '../../../../contexts/language-context';
import { AllInvoicingMethodTypes, ConsumerEInvoiceType, ExtendedInvoiceMethodType } from '../../../../model';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import AuthContext from '../../../../contexts/auth-context';

interface Props {
  control: Control<EditBillingInfoFormData, any>;
  getFormValues: UseFormGetValues<EditBillingInfoFormData>;
  handleSubmit: UseFormHandleSubmit<EditBillingInfoFormData>;
  setInvoicingMethod: React.Dispatch<React.SetStateAction<AllInvoicingMethodTypes>>;
  errors: FieldErrors<EditBillingInfoFormData>;
}

const EditBillingInfoPrivateForm: FC<Props> = ({ control, getFormValues, setInvoicingMethod, errors }) => {
  const { getText } = useContext(LanguageContext);
  const stackWidth = SmallerThanBreakpoint('md') ? '100%' : '50%';
  const authService = useContext(AuthContext);
  const readOnly = authService.isReadOnlyBillingAddressEnabled();
  const invoicingMethod = getFormValues('invoicingMethod');

  const getInvoiceTypeText = (consumerEInvoiceType?: AllInvoicingMethodTypes) => {
    switch (consumerEInvoiceType) {
      case ConsumerEInvoiceType.ConsumerChannel:
        return getText('billing-info-invoicing-type-e-invoicing');
      case ConsumerEInvoiceType.DirectInvoicing:
        return getText('billing-info-invoicing-type-direct-payment');
      case ConsumerEInvoiceType.KivraInvoicing:
        return getText('billing-info-invoicing-type-kivra');
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ width: stackWidth }}>
      <ControlTextInput
        control={control}
        name='firstName'
        label='basic-info-first-name'
        error={errors.firstName}
        validations={['required']}
        disabled
      />
      <ControlTextInput
        control={control}
        name='lastName'
        label='basic-info-last-name'
        error={errors.lastName}
        validations={['required']}
        disabled
      />
      <ControlTextInput
        control={control}
        name='street'
        label='billing-data-street-address'
        error={errors.street}
        validations={['required']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='postalCode'
        label='billing-data-post-code'
        error={errors.postalCode}
        validations={['required', 'post-code']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='postOffice'
        label='billing-data-city'
        error={errors.postOffice}
        validations={['required']}
        disabled={readOnly}
      />

      {invoicingMethod === ConsumerEInvoiceType.EmailInvoicing ||
      invoicingMethod === ExtendedInvoiceMethodType.PaperBillInvoicing ? (
        <Controller
          control={control}
          name='emailInvoicing'
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                      setInvoicingMethod(
                        value
                          ? ConsumerEInvoiceType.NotInUse
                          : (ConsumerEInvoiceType.EmailInvoicing as ConsumerEInvoiceType)
                      );
                    }}
                  />
                }
                label={getText('email-bill')}
              />
            );
          }}
        />
      ) : (
        <ControlTextInput
          control={control}
          name='invoicingMethod'
          label='billing-info-invoicing-type-title'
          error={errors.invoicingMethod}
          validations={[]}
          disabled={true}
          valueTransformer={(value: AllInvoicingMethodTypes) => getInvoiceTypeText(value)}
        />
      )}
    </Stack>
  );
};

export default EditBillingInfoPrivateForm;
