import { useContext, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { addDays } from 'date-fns';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Box,
} from '@mui/material';
import { LanguageContext } from '../../../../../contexts/language-context';
import ApiContext from '../../../../../contexts/api-context';
import { SmallerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ContractInfo, EmptyingIntervalUpdate, EmptyingInfo, UiTexts } from '../../../../../model';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import ControlDateInput from '../../../../../components/common-materialui/form/ControlDateInput';
import ControlCheckboxInput from '../../../../../components/common-materialui/form/ControlCheckboxInput';
import TermsAndConditions from '../../../../../components/common-materialui/TermsAndConditions';
import ProgressComponent from '../../../../../components/common-materialui/ProgressComponent';
import ExtendedAlert from '../../../../../components/common-materialui/status-handling/ExtendedAlert';
import ApiMessageDialog from '../../../../../components/common-materialui/dialogs/ApiMessageDialog';
import WeekIntervalSelector from '../../order-product-wizard/WeekIntervalSelector/WeekIntervalSelector';
import { WeekInterval } from '../../OrderProduct';
import HttpError from '../../../../../util/HttpError';

type BaseMutationArgs = {
  customerNumber: string;
  position: string;
};

type ChangeAmountContractMutationArgs = BaseMutationArgs & {
  amount: number;
};

type ChangeEmptyinIntervalMutationArgs = BaseMutationArgs & {
  emptyingIntervals: EmptyingIntervalUpdate[];
};

type ChangeEndContractMutationArgs = BaseMutationArgs & {
  endTime: Date;
  reason: string;
  additionalInformation: string;
};

type ChangePauseContractMutationArgs = BaseMutationArgs & {
  endTime: Date;
  startDate: Date;
  reason: string;
  additionalInformation: string;
};

type ChangeDriverInstructionsMutationArgs = BaseMutationArgs & {
  gateKeyCode: string;
};

interface EditContractProps {
  emptyingInfo: EmptyingInfo | null | undefined;
  contractDetails: ContractInfo | null | undefined;
  onClose: () => void;
}

export interface ContractPauseServiceFormData {
  contractEdit: string;
  startDate: Date;
  endDate: Date | undefined;
  reason: string;
  additionalInfo?: string;
  termsAndConditions: boolean;
}

interface ContractTerminationInfo {
  endDate: Date;
  reason: string;
  additionalInformation: string;
}

interface EditContractFormData {
  contractEdit: string;
  numberOfBins?: number;
  termination?: ContractTerminationInfo;
  termsAndConditions: boolean;
  startDate: Date;
  endDate: Date | string;
  reason: string;
  additionalInfo: string;
  gateKeyCode: string;
}

const EditContract = ({ emptyingInfo, contractDetails, onClose }: EditContractProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const [weekInterval, setWeekInterval] = useState<WeekInterval[]>([]);
  const [alertErrorMsg, setAlertErrorMsg] = useState<keyof UiTexts | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    changeAmountContract,
    endContract,
    fetchProductRules,
    pauseContract,
    updateEmptyingIntervals,
    changeContractDriverInstructions,
  } = useContext(ApiContext);

  // For week interval selector : this has to be here at the top level, so it won't be reset when the form is submitted
  const [singleInterval, setSingleInterval] = useState<boolean>(
    contractDetails?.emptyingIntervals?.length === 1 ?? true
  );

  const largerThanPhone = SmallerThanBreakpoint('md');

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<EditContractFormData>({
    defaultValues: {
      contractEdit: '',
      numberOfBins: 0,
      termination: undefined,
      termsAndConditions: false,
      startDate: undefined,
      endDate: undefined,
      reason: '',
      additionalInfo: '',
      gateKeyCode: contractDetails?.gateKeyCode || '',
    },
  });

  const { mutate: patchChangeAmountContract, isLoading: isLoadingChangeAmountContract } = useMutation(
    ({ customerNumber, position, amount }: ChangeAmountContractMutationArgs) =>
      changeAmountContract(customerNumber, position, amount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-details']);
        setConfirmationDialog(true);
      },
      onError: () => {
        setAlertErrorMsg('error-general-title');
      },
    }
  );

  const { mutate: patchUpdateEmptyinIntervals, isLoading: isLoadingUpdateEmptyingInterval } = useMutation(
    ({ customerNumber, position, emptyingIntervals }: ChangeEmptyinIntervalMutationArgs) =>
      updateEmptyingIntervals(customerNumber, position, emptyingIntervals),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-details']);
        setConfirmationDialog(true);
      },
      onError: (error: HttpError) => {
        if (error.response.type === '/problems/emptying-interval/not-valid') {
          setAlertErrorMsg('contract-details-problems-emptying-interval-not-valid');
        } else if (error.response.type === '/problems/emptying-interval/not-defined') {
          setAlertErrorMsg('contract-details-problems-emptying-interval-not-defined');
        } else {
          setAlertErrorMsg('error-general-title');
        }
      },
    }
  );

  const { mutate: pauseEditContract, isLoading: isLoadingPauseEditContract } = useMutation(
    ({
      customerNumber,
      position,
      endTime,
      startDate,
      reason,
      additionalInformation,
    }: ChangePauseContractMutationArgs) =>
      pauseContract(customerNumber, position, startDate, endTime, reason, additionalInformation),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-details']);
        onClose();
      },
      onError: () => setAlertErrorMsg('error-general-message'),
    }
  );

  const { mutate: patchEndContract, isLoading: isLoadingEndContract } = useMutation(
    ({ customerNumber, position, endTime, reason, additionalInformation }: ChangeEndContractMutationArgs) =>
      endContract(customerNumber, position, endTime, reason, additionalInformation),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-details']);
        onClose();
      },
      onError: () => setAlertErrorMsg('error-general-message'),
    }
  );

  const { mutate: patchChangeDriverInstructions, isLoading: isLoadingDriverInstructions } = useMutation(
    ({ customerNumber, position, gateKeyCode }: ChangeDriverInstructionsMutationArgs) =>
      changeContractDriverInstructions(customerNumber, position, gateKeyCode),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-details']);
        onClose();
      },
      onError: () => setAlertErrorMsg('error-general-message'),
    }
  );

  const { isLoading: maxEndDateIsLoading, data: maxEndDate } = useQuery<any>(
    ['max-end-date', emptyingInfo?.id, contractDetails?.productGuid],
    () => {
      if (emptyingInfo?.id && contractDetails?.productGuid) {
        return fetchProductRules(emptyingInfo?.id, contractDetails?.productGuid);
      }
    }
  );

  // Disabling submit button if there are errors in the form, so putting the error messages in the state here
  const [alertErrorMsgFirstInterval, setAlertErrorMsgFirstInterval] = useState<keyof UiTexts | null>(null);
  const [alertErrorMsgSecondInterval, setAlertErrorMsgSecondInterval] = useState<keyof UiTexts | null>(null);

  const contractEditWatch = useWatch({ control, name: 'contractEdit' });

  const startDateWatch = useWatch({ control, name: 'startDate' });
  const endDateWatch = useWatch({ control, name: 'endDate' });

  const renderContentBasedOnContract = () => {
    switch (contractEditWatch) {
      case 'numberOfBins':
        return renderNumberOfBins();
      case 'terminating':
        return renderTerminationOfService();
      case 'interval':
        return renderEmptyingInterval();
      case 'pause':
        return renderPauseService();
      case 'driverInstructions':
        return renderDriverInstructions();
      default:
        <></>;
    }
  };

  const isPauseFormDataAvailable = (data: EditContractFormData): boolean => {
    return Boolean(data.startDate && data.endDate && data.reason);
  };

  const getMaxDateBasedOnProductMaxPause = (): Date | undefined => {
    const date = new Date(watch('startDate'));
    if (maxEndDate?.maxPauseLength) {
      date.setMonth(date.getMonth() + maxEndDate?.maxPauseLength);
      return date;
    }
    return undefined;
  };

  const handleStartDateChange = (selectedDate: Date): void => {
    setValue('startDate', selectedDate);
    if (endDateWatch && typeof endDateWatch !== 'string') {
      if (selectedDate.getTime() >= endDateWatch.getTime()) {
        setValue('endDate', addDays(selectedDate, 1));
      }
    }
    clearErrors('startDate');
    clearErrors('endDate');
  };

  const handleSubmitBasedOnContract = (formData: EditContractFormData) => {
    if (!contractDetails) return;
    const commonPayload = {
      customerNumber: contractDetails.customerId,
      position: contractDetails.position?.toString(),
    };

    switch (contractEditWatch) {
      case 'numberOfBins':
        handleNumberOfBinsPatch(formData, commonPayload);
        break;

      case 'terminating':
        handleTerminatingPatch(formData, commonPayload);
        break;
      case 'interval':
        handleIntervalPatch(commonPayload);
        break;
      case 'pause':
        handlePauseContractPatch(formData, commonPayload);
        break;
      case 'driverInstructions':
        handleDriverInstructionPatch(formData, commonPayload);
        break;
      default:
        break;
    }
  };

  const handleNumberOfBinsPatch = (formData: any, commonPayload: BaseMutationArgs) => {
    if (formData.numberOfBins) {
      const numberOfBinsPayload: ChangeAmountContractMutationArgs = {
        ...commonPayload,
        amount: formData.numberOfBins,
      };
      patchChangeAmountContract(numberOfBinsPayload);
    }
  };

  const convertToEmptyingIntervalUpdate = (weekInterval: WeekInterval[]): EmptyingIntervalUpdate[] => {
    return weekInterval.map((week) => ({
      startWeek: parseInt(week.startWeek, 10),
      endWeek: parseInt(week.endWeek, 10),
      interval: parseInt(week.interval, 10),
      amountPerWeek: parseInt(week.timesPerWeek, 10),
    }));
  };

  const handleIntervalPatch = (commonPayload: BaseMutationArgs) => {
    const convertedEmptyingInterval = convertToEmptyingIntervalUpdate(weekInterval);
    const emptyingIntervalPayload: ChangeEmptyinIntervalMutationArgs = {
      ...commonPayload,
      emptyingIntervals: convertedEmptyingInterval,
    };
    patchUpdateEmptyinIntervals(emptyingIntervalPayload);
  };

  const handleTerminatingPatch = (formData: any, commonPayload: BaseMutationArgs) => {
    if (formData.termination) {
      const endContractPayload: ChangeEndContractMutationArgs = {
        ...commonPayload,
        endTime: formData.termination.endDate,
        reason: formData.termination.reason,
        additionalInformation: formData.termination.additionalInformation,
      };
      patchEndContract(endContractPayload);
    }
  };

  const handlePauseContractPatch = (formData: any, commonPayload: BaseMutationArgs) => {
    if (isPauseFormDataAvailable(formData)) {
      const pauseContractPayload = {
        ...commonPayload,
        endTime: new Date(formData.endDate),
        startDate: formData.startDate,
        reason: formData.reason,
        additionalInformation: formData.additionalInfo,
      };
      pauseEditContract(pauseContractPayload);
    }
  };

  const handleDriverInstructionPatch = (formData: any, commonPayload: BaseMutationArgs) => {
    const driverInstructionPayload: ChangeDriverInstructionsMutationArgs = {
      ...commonPayload,
      gateKeyCode: formData.gateKeyCode,
    };
    patchChangeDriverInstructions(driverInstructionPayload);
  };

  const renderNumberOfBins = () => (
    <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap' marginTop={2}>
      <Grid item xs={12} sm={5}>
        <Typography marginBottom={3} variant='h5'>
          {getText('contract-details-number-of-bins-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Stack maxWidth={largerThanPhone ? '100%' : '25rem'}>
          <ControlTextInput
            control={control}
            name='numberOfBins'
            label='contract-details-number-of-bins'
            error={errors.numberOfBins}
            maxValue={50}
            inputType='number'
            validations={['required', 'positive-integer', 'integer-not-start-zero']}
          />
          <Box marginTop={1}>
            <ControlCheckboxInput
              label={<TermsAndConditions type='contract' />}
              validations={['required']}
              control={control}
              name='termsAndConditions'
              error={errors.termsAndConditions}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderTerminationOfService = () => (
    <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap' marginTop={2}>
      <Grid item xs={12} sm={5}>
        <Typography marginBottom={3} variant='h5'>
          {getText('contract-details-terminating-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Stack maxWidth={largerThanPhone ? '100%' : '25rem'}>
          <ControlDateInput
            control={control}
            placeholder='contract-details-terminating-date-of-termination'
            name='termination.endDate'
            error={errors.termination?.endDate}
            validations={['required']}
            minDate={addDays(new Date(), 7)}
          />
          <ControlTextInput
            control={control}
            name='termination.reason'
            label='contract-details-terminating-reason-for-termination'
            error={errors.termination?.reason}
            validations={['required']}
          />
          <ControlTextInput
            control={control}
            name='termination.additionalInformation'
            label='contract-details-terminating-additional-information'
            error={errors.termination?.additionalInformation}
            multiline={true}
            maxLength={500}
            validations={['required']}
          />
          <Box marginTop={1}>
            <ControlCheckboxInput
              label={<TermsAndConditions type='contract' />}
              validations={['required']}
              control={control}
              name='termsAndConditions'
              error={errors.termsAndConditions}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderEmptyingInterval = () => (
    <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap' marginTop={1}>
      <Grid item xs={12} sm={5}>
        <Typography marginBottom={3} variant='h5'>
          {getText('change-contract-change-interval')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <WeekIntervalSelector
          allowedIntervals={maxEndDate.allowedEmptyingIntervals}
          alertErrorMsgFirstInterval={alertErrorMsgFirstInterval}
          alertErrorMsgSecondInterval={alertErrorMsgSecondInterval}
          getText={getText}
          initialIntervals={contractDetails?.emptyingIntervals}
          setAlertErrorMsgFirstInterval={setAlertErrorMsgFirstInterval}
          setAlertErrorMsgSecondInterval={setAlertErrorMsgSecondInterval}
          setSingleInterval={setSingleInterval}
          setWeekIntervalFinishOrder={setWeekInterval}
          singleInterval={singleInterval}
          weekIntervalFinishOrder={weekInterval}
        />
        <Box marginTop={1}>
          <ControlCheckboxInput
            label={<TermsAndConditions type='contract' />}
            validations={['required']}
            control={control}
            name='termsAndConditions'
            error={errors.termsAndConditions}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const renderPauseService = () => (
    <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap' marginTop={2}>
      <Grid item xs={12} sm={5}>
        <Typography marginBottom={3} variant='h5'>
          {getText('contract-details-pause-form-header')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={7}>
        <label>
          <Typography marginBottom={3} variant='body1'>
            {getText('contract-details-pause-form-guide-text')}
          </Typography>
        </label>
        <ControlDateInput
          control={control}
          name='startDate'
          error={errors.startDate}
          validations={['required']}
          minDate={new Date()}
          onDateChange={(event) => {
            handleStartDateChange(event);
          }}
          placeholder={'contract-details-pause-form-start-date'}
        />
        {maxEndDateIsLoading ? (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <ProgressComponent variant='circle' isLoading />
          </Box>
        ) : (
          <ControlDateInput
            control={control}
            name='endDate'
            error={errors.endDate}
            validations={['required']}
            minDate={addDays(startDateWatch, 1)}
            maxDate={getMaxDateBasedOnProductMaxPause()}
            placeholder={'contract-details-pause-form-end-date'}
          />
        )}

        <ControlTextInput
          control={control}
          name='reason'
          label='contract-details-pause-form-reason'
          error={errors.reason}
          validations={['required']}
        />
        <ControlTextInput
          control={control}
          name='additionalInfo'
          label='order-additional-collection-info'
          error={errors.additionalInfo}
          multiline={true}
          maxLength={500}
          validations={[]}
        />
        <ControlCheckboxInput
          label={<TermsAndConditions type='contract' />}
          validations={['required']}
          control={control}
          name='termsAndConditions'
          error={errors.termsAndConditions}
        />
      </Grid>
    </Grid>
  );

  const renderDriverInstructions = () => (
    <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap' marginTop={1}>
      <Grid item xs={12} sm={5}>
        <Typography marginBottom={3} variant='h5'>
          {getText('contract-details-driver-instructions')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Stack maxWidth={largerThanPhone ? '100%' : '25rem'}>
          <ControlTextInput
            control={control}
            name='gateKeyCode'
            label='contract-details-driver-instructions-gate-key'
            error={errors.gateKeyCode}
            validations={['required']}
          />
          <Box marginTop={1}>
            <ControlCheckboxInput
              label={<TermsAndConditions type='contract' />}
              validations={['required']}
              control={control}
              name='termsAndConditions'
              error={errors.termsAndConditions}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );

  const submitButtonDisabled: boolean = useMemo(() => {
    return alertErrorMsgFirstInterval !== null || alertErrorMsgSecondInterval !== null;
  }, [alertErrorMsgFirstInterval, alertErrorMsgSecondInterval]);

  if (
    isLoadingChangeAmountContract ||
    isLoadingUpdateEmptyingInterval ||
    isLoadingEndContract ||
    isLoadingPauseEditContract ||
    isLoadingDriverInstructions
  ) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' marginTop={10}>
        <ProgressComponent variant='circle' isLoading />
      </Box>
    );
  }

  return (
    <>
      <Container>
        <ExtendedAlert
          open={!!alertErrorMsg}
          onClose={() => setAlertErrorMsg(null)}
          severity='error'
          sx={{ marginBottom: 2 }}
        >
          {alertErrorMsg ? getText(alertErrorMsg) : null}
        </ExtendedAlert>
        <ApiMessageDialog
          open={confirmationDialog}
          buttonLabelKey='dialog-ok'
          descriptionMessageKey='message-success-generic-title'
          onClose={onClose}
        />
        <Grid container direction={largerThanPhone ? 'column' : 'row'} wrap='nowrap'>
          <Grid item xs={12} sm={5}>
            <Typography marginBottom={3} variant='h5'>
              {getText('contract-details-change-applies-to')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Controller
              control={control}
              name='contractEdit'
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='radio-group-for-selecting-contract-edit'
                      value={value}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value='numberOfBins'
                        control={<Radio />}
                        label={getText('contract-details-number-of-bins')}
                      />
                      {maxEndDate && maxEndDate.allowedEmptyingIntervals && (
                        <FormControlLabel
                          value='interval'
                          control={<Radio />}
                          label={getText('contract-details-number-of-interval')}
                        />
                      )}
                      <FormControlLabel value='pause' control={<Radio />} label={getText('contract-details-pause')} />
                      <FormControlLabel
                        value='terminating'
                        control={<Radio />}
                        label={getText('contract-details-terminating')}
                      />
                      <FormControlLabel
                        value='driverInstructions'
                        control={<Radio />}
                        label={getText('contract-details-driver-instructions-gate-key')}
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {contractEditWatch &&
        (contractEditWatch === 'numberOfBins' ||
          contractEditWatch === 'terminating' ||
          contractEditWatch === 'pause' ||
          contractEditWatch === 'interval' ||
          contractEditWatch === 'driverInstructions') && <Divider />}
      <Container>{renderContentBasedOnContract()}</Container>
      <Divider />
      <Container>
        <Stack spacing={1} direction='row' justifyContent={'end'}>
          <Button color='secondary' onClick={onClose}>
            {getText('contract-details-cancel-changes')}
          </Button>
          <Button
            variant='contained'
            disabled={submitButtonDisabled}
            sx={{ paddingY: 1.75, marginTop: 2 }}
            onClick={handleSubmit((data) => {
              handleSubmitBasedOnContract(data);
            })}
          >
            <Typography py={1} variant='button'>
              {getText('contract-details-confirm-changes')}
            </Typography>
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default EditContract;
