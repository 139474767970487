import React, { useEffect } from 'react';
import FormThemeProvider from '../FormThemeProvider';
import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import { getUiSchemaTitle } from './utils';

const CustomEnumWidget = function (props: any) {
  const [value, setValue] = React.useState<any>(props.value);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [label, setLabel] = React.useState<string>(props.label);
  const theme = FormThemeProvider;

  const initialize = (properties: any) => {
    let enumValues = properties.schema['enum'];
    let enumNames = properties.uiSchema['ui:options']?.enumNames ?? properties.schema.enumNames ?? enumValues;

    setLabel(getUiSchemaTitle(props));

    let data: Array<any> = enumValues.map((x: any) => [x, x]);
    for (let i = 0; i < enumValues.length; i++) {
      let text = enumNames[i] ?? enumValues[i];
      data[i] = [enumValues[i], text];
    }

    setOptions(data);
  };

  useEffect(() => {
    initialize(props);
  }, [props.formContext.formId, props.formContext.language]);

  const onChange = (event: any) => {
    const targetValue = event.target.value;
    setValue(targetValue);
    props.onChange(targetValue);
  };

  if (options === null) {
    return <Select label={props.label} value={props.value} onChange={onChange} />;
  }

  const requiredCheck = label.length > 0 ? props?.required ?? false : false;

  return (
    <ThemeProvider theme={theme}>
      <FormControl required={requiredCheck}>
        <InputLabel required={requiredCheck} id={props.id + '-label'} error={props.rawErrors != null}>
          {label}
        </InputLabel>
        <Select
          required={requiredCheck}
          labelId={props.id + '-label'}
          id={props.id + '-select'}
          value={value ?? ''}
          label={label}
          displayEmpty={true}
          onChange={onChange}
        >
          {options.map((opt: any, index: any) => (
            <MenuItem value={opt[0]} key={props.id + '-menu-' + index}>
              {opt[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default CustomEnumWidget;
