import { useContext } from 'react';
import { Radio, FormControlLabel } from '@mui/material';
import { LanguageContext } from '../contexts/language-context';
import { UiTexts, CustomerType } from '../model';

export const RenderSelectionCustomerType = (name: keyof UiTexts, custType: CustomerType) => {
  const { getText } = useContext(LanguageContext);

  return <FormControlLabel key={custType} value={custType} control={<Radio color='primary' />} label={getText(name)} />;
};

export const splitName = (name: string): [firstName: string, lastName: string] => {
  const items = name.split(' ');
  let firstName = items.slice(0, -1).join(' ');
  let lastName = items[items.length - 1];
  return [firstName, lastName];
};
