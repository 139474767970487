import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

/**
 * Returns boolean value if screen is larger than breakpoint provided.
 * @param viewportSize Breakpoint value, ex. "md"
 * @returns true or false
 */
export function LargerThanBreakpoint(viewportSize: Breakpoint) {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up(viewportSize));
}

/**
 * Returns boolean value if screen is smaller than breakpoint provided.
 * @param viewportSize Breakpoint value, ex. "md"
 * @returns true or false
 */
export function SmallerThanBreakpoint(viewportSize: Breakpoint) {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(viewportSize));
}
