import { useContext, useEffect, useRef, useState } from 'react';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import { PermitApplicationInput, PermitForm, UiTexts } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import { ArrayFieldTemplateAddAndRemove } from '../../util/templateUtils';
import { templateFormErrors } from '../../util/util';
import { TemplatesType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import CustomDateWidget from '../../util/custom-widgets/CustomDateWidget';
import AutoPopulateSelect from '../../util/custom-widgets/AutoPopulateSelect';
import CustomEnumWidget from '../../util/custom-widgets/CustomEnumWidget';
import CustomCheckboxesWidget from '../../util/custom-widgets/CustomCheckboxesWidget';
import CustomRadioWidget from '../../util/custom-widgets/CustomRadioWidget';
import CustomNullField from '../../util/custom-widgets/CustomNullField';
import { Box, Button, Container, SelectChangeEvent, Stack, ThemeProvider, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import ApiMessageDialog from '../../components/common-materialui/dialogs/ApiMessageDialog';
import Form from '@rjsf/mui';
import DropdownSelect from '../../components/common-materialui/DropdownSelect';
import { overlayDialogPadding } from '../../components/common-materialui/dialogs/OverlayDialog';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import ExtendedAlert from '../../components/common-materialui/status-handling/ExtendedAlert';
import FormThemeProvider from '../../util/FormThemeProvider';
import { LargerThanBreakpoint } from '../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from '../account-settings/models';
import CustomCheckboxWidget from '../../util/custom-widgets/CustomCheckboxWidget';
import CustomDescriptionField from '../../util/custom-widgets/CustomDescriptionField';

interface NewPermitApplicationProps {
  onClose: () => void;
}

const NewPermitApplication = ({ onClose }: NewPermitApplicationProps): JSX.Element => {
  const theme = FormThemeProvider;
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const { getText, lang } = useContext(LanguageContext);
  const [permitForm, setPermitForm] = useState<PermitForm>();
  const [permitSelection, setPermitSelection] = useState<string>('');
  const [permitFormOptions, setPermitFormOptions] = useState<PermitForm[]>([]);
  const [permitFormData, setPermitFormData] = useState<object>({});
  const submitFormRef = useRef<HTMLButtonElement | null>();
  const [alertErrorMsg, setAlertErrorMsg] = useState<keyof UiTexts | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  const fetchPermitForms = async (): Promise<PermitForm[]> => {
    const permits = await api.fetchPermitForms(lang);
    if (permits) {
      switch (permits.length) {
        case 0:
          setAlertErrorMsg('permit-application-no-forms-text');
          break;
        default:
          setPermitFormOptions(permits);
          setPermitSelection(permits[0].id);
          break;
      }
    }
    return permits;
  };

  useQuery<PermitForm[]>(['permit-form'], fetchPermitForms, {
    onError: () => setAlertErrorMsg('error-service-break-message'),
  });

  useEffect(() => {
    setPermitForm(permitFormOptions.find((x) => x.id === permitSelection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitSelection]);

  const isUiSchemaEmpty = (uiSchema: string) => {
    if (uiSchema === '') {
      return {};
    } else {
      return JSON.parse(uiSchema);
    }
  };

  const sendPermit = async (data: PermitApplicationInput) => {
    await api.sendPermitApplication(data);
    setConfirmationDialog(true);
  };

  const { mutate: sendPermitApplication, isLoading: isLoadingSendPermitApplication } = useMutation(
    (data: PermitApplicationInput) => sendPermit(data),
    {
      onError: () => setAlertErrorMsg('error-service-break-message'),
    }
  );

  const onSubmit = (data: any) => {
    if (permitForm?.id) {
      const newPermitApplication: PermitApplicationInput = {
        formId: permitForm.id,
        formData: data.formData,
      };
      sendPermitApplication(newPermitApplication);
    }
  };

  const selectPermitForm = (e: SelectChangeEvent<string | number>): void => {
    setPermitSelection(e.target.value as string);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    submitFormRef.current?.click();
  };

  const handleChange = (data: any) => {
    setPermitFormData(data.formData);
  };

  const templates: Partial<TemplatesType> = {
    ArrayFieldTemplate: ArrayFieldTemplateAddAndRemove,
    DescriptionFieldTemplate: CustomDescriptionField,
  };

  const fields = { NullField: CustomNullField };
  const context = {
    domain: authService.getDomain(),
    tenantId: authService.getTenantId(),
    language: lang,
    formId: permitSelection ?? null,
  };
  const customWidgets = {
    date: CustomDateWidget,
    auto: AutoPopulateSelect,
    select: CustomEnumWidget,
    checkboxes: CustomCheckboxesWidget,
    radio: CustomRadioWidget,
    checkbox: CustomCheckboxWidget,
  };

  return (
    <Container>
      <ExtendedAlert open={!!alertErrorMsg} onClose={onClose} severity='error' sx={{ margin: 2 }}>
        {alertErrorMsg ? getText(alertErrorMsg) : null}
      </ExtendedAlert>
      <ApiMessageDialog
        open={confirmationDialog}
        buttonLabelKey='dialog-ok'
        descriptionMessageKey='message-success-generic-title'
        onClose={onClose}
      />
      <Stack
        direction='column'
        width='100%'
        p={overlayDialogPadding}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={1}
      >
        {permitFormOptions != null && permitFormOptions.length > 0 && permitForm?.formSchema ? (
          <Stack direction='column' width={width} spacing={1}>
            <DropdownSelect
              items={permitFormOptions.map((option) => ({ value: option.id, text: option.formName }))}
              value={permitSelection}
              onChange={selectPermitForm}
            />
            <ThemeProvider theme={theme}>
              {
                <Form
                  templates={templates}
                  schema={JSON.parse(permitForm.formSchema)}
                  uiSchema={isUiSchemaEmpty(permitForm.uiSchema)}
                  onSubmit={onSubmit}
                  onChange={handleChange}
                  showErrorList={false}
                  noHtml5Validate
                  validator={validator}
                  fields={fields}
                  widgets={customWidgets}
                  transformErrors={(e) => templateFormErrors(e, getText)}
                  omitExtraData={true}
                  liveOmit={true}
                  formData={permitFormData}
                  formContext={context}
                >
                  <button
                    ref={(ref) => {
                      submitFormRef.current = ref;
                    }}
                    type='submit'
                    style={{ display: 'none' }}
                  />
                </Form>
              }
            </ThemeProvider>
            <Button variant='contained' disabled={isLoadingSendPermitApplication} onClick={handleFormSubmit}>
              {isLoadingSendPermitApplication ? (
                <ProgressComponent variant='circle' isLoading={isLoadingSendPermitApplication} />
              ) : (
                <Typography py={1} variant='button'>
                  {getText('permit-application-submit')}
                </Typography>
              )}
            </Button>
          </Stack>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' marginTop={10}>
            <ProgressComponent variant='circle' isLoading />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default NewPermitApplication;
