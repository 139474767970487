import { SetStateAction, useContext, useMemo, useState, Dispatch, useEffect } from 'react';
import { Stack, Container, Box, Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductCategorySectionForm from '../order-product-form/ProductCategorySectionForm';
import { overlayDialogPadding } from '../../../../components/common-materialui/dialogs/OverlayDialog';
import ExceptionHandler from '../../../../components/common-materialui/status-handling/ExceptionHandler';
import { LanguageContext } from '../../../../contexts/language-context';
import ApiContext from '../../../../contexts/api-context';
import { ProductCategories } from '../../../../model';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';

interface Props {
  emptyingId: string;
  onSelectedCategoryId: Dispatch<SetStateAction<string>>;
  onPhaseChange: () => void;
  onCancel: () => void;
}

const OrderProductCategory = ({ emptyingId, onSelectedCategoryId, onPhaseChange, onCancel }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { fetchProductCategories } = useContext(ApiContext);
  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';

  const [customerType, setCustomerType] = useState<string>('');

  const { data, error } = useQuery<ProductCategories>(['-product-categories'], () =>
    fetchProductCategories(emptyingId)
  );

  const sortedCategories = useMemo(
    () => data && [...data.productCategories].sort((a, b) => (a.productType > b.productType ? 1 : -1)),
    [data]
  );

  const selectedCategory = data?.productCategories.find((category) => category.name === customerType);

  useEffect(() => {
    onSelectedCategoryId(selectedCategory?.id || '');
  }, [selectedCategory]);

  return (
    <Stack direction='column'>
      <ExceptionHandler error={error}>
        <Container>
          <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
            <Typography variant={titleTypographyVariant}>
              {sortedCategories && sortedCategories.length > 0
                ? getText('emptying-location-order-product-select-phase-header')
                : getText('emptying-location-order-product-select-phase-header-no-services')}
            </Typography>
            <ProductCategorySectionForm
              categories={sortedCategories ? sortedCategories : []}
              radioValue={customerType}
              setRadioValue={setCustomerType}
            />
          </Stack>
        </Container>
        <Container>
          <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
            <Box>
              <Button size='large' color='secondary' onClick={onCancel}>
                {getText('dialog-cancel')}
              </Button>
            </Box>
            <Box>
              <Button variant='contained' size='large' onClick={onPhaseChange} disabled={customerType.length === 0}>
                {getText('dialog-next')}
              </Button>
            </Box>
          </Stack>
        </Container>
      </ExceptionHandler>
    </Stack>
  );
};

export default OrderProductCategory;
