import { Stack, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';

type Props = {
  headerKey?: keyof UiTexts;
  descriptionKey?: keyof UiTexts;
  children?: ReactNode;
  imageElement?: JSX.Element;
  extraElements?: JSX.Element;
};

/**
 * Info box for showing informative messages.
 *
 * All params are optional. You can give only headerKey or descriptionKey.
 * Children elements are always rendered if given.
 *
 * @param headerKey if given then header text with corresponding translation is shown
 * @param descriptionKey if given then a description is shown
 */
const InfoBox = (props: Props): JSX.Element => {
  const { headerKey, descriptionKey, children, imageElement, extraElements } = props;

  const { getText } = useContext(LanguageContext);

  return (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
      {imageElement ? imageElement : null}

      {headerKey ? <Typography variant='h5'> {getText(headerKey)}</Typography> : null}

      {descriptionKey ? <Typography variant='body1'> {getText(descriptionKey)}</Typography> : null}

      {extraElements ? extraElements : null}

      {children}
    </Stack>
  );
};

export default InfoBox;
