import { useContext } from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { TreatmentPlantForm, WeeklyFrequencyEnum } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';
import { ShowSummaryItem } from '../../../../../util/showDataUtils';
import { LanguageContext } from '../../../../../contexts/language-context';

interface Props {
  treatmentPlantOption: string;
  treatmentPlant: Partial<TreatmentPlantForm>;
}

const PropertyTreatmentPlantSummary = ({ treatmentPlantOption, treatmentPlant }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const subtitleMargin = LargerThanBreakpoint('md') ? 2 : 1;

  const translateWeeklyFrequencyCode = (code: string) => {
    switch (code) {
      case WeeklyFrequencyEnum.EveryHalfAYear:
        return getText('need-for-emptying-weekly-frequency-half-year');
      case WeeklyFrequencyEnum.EveryYear:
        return getText('need-for-emptying-weekly-frequency-year');
      case WeeklyFrequencyEnum.EverySecondYear:
        return getText('need-for-emptying-weekly-frequency-two-year');
      case WeeklyFrequencyEnum.EveryThirdyear:
        return getText('need-for-emptying-weekly-frequency-three-year');
      case WeeklyFrequencyEnum.Other:
        return getText('need-for-emptying-weekly-frequency-other');
    }
  };

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6' sx={{ paddingTop: '10px' }} marginBottom={subtitleMargin}>
          {treatmentPlantOption}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='treatment-plant-manufacturer' data={treatmentPlant.manufacturer} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='treatment-plant-model' data={treatmentPlant.model} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='treatment-plant-emptying-guide' data={treatmentPlant.emptyingInstructions} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='treatment-plant-volume' data={treatmentPlant.volume?.toString()} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='treatment-plant-vacuum-truck'
          data={
            treatmentPlant.vacuumTruckEmptied
              ? getText('treatment-plant-vacuum-yes-options')
              : getText('treatment-plant-vacuum-no-options')
          }
        />
      </Grid>
      {treatmentPlant.blackWater?.residentCount && (
        <Grid item xs={1}>
          <ShowSummaryItem
            labelKey='need-for-emptying-black-water-resident-count'
            data={treatmentPlant.blackWater?.residentCount?.toString()}
          />
        </Grid>
      )}
      {(treatmentPlant.grayWater?.weeklyFrequency && (
        <>
          <Grid item xs={1}>
            <ShowSummaryItem
              labelKey='need-for-emptying-weekly-frequency-dropdown-label'
              data={translateWeeklyFrequencyCode(treatmentPlant.grayWater?.weeklyFrequency)}
            />
          </Grid>
          {treatmentPlant.grayWater.other && (
            <Grid item xs={1}>
              <ShowSummaryItem labelKey='need-for-emptying-other-input-label' data={treatmentPlant.grayWater?.other} />
            </Grid>
          )}
        </>
      )) ||
        (treatmentPlant.blackWater?.weeklyFrequency && (
          <>
            <Grid item xs={1}>
              <ShowSummaryItem
                labelKey='need-for-emptying-weekly-frequency-dropdown-label'
                data={translateWeeklyFrequencyCode(treatmentPlant.blackWater?.weeklyFrequency)}
              />
            </Grid>
            {treatmentPlant.blackWater.other && (
              <Grid item xs={1}>
                <ShowSummaryItem
                  labelKey='need-for-emptying-other-input-label'
                  data={treatmentPlant.blackWater?.other}
                />
              </Grid>
            )}
          </>
        ))}
      <Grid item xs={2}>
        <Typography variant='body2' color={palette.text.secondary}>
          {getText('septic-tank-access-limitations-header')}
        </Typography>
        {treatmentPlant.noLimits && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-limits')}`</Typography>
          </Stack>
        )}
        {treatmentPlant.noWinterEmptyings && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-no-winter')}</Typography>
          </Stack>
        )}
        {treatmentPlant.locked && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-locked')}</Typography>
          </Stack>
        )}
        {treatmentPlant.weightLimit && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-option-weight-limit')}</Typography>
          </Stack>
        )}
        {treatmentPlant.other && (
          <Stack direction={'row'} useFlexGap spacing={0.5}>
            <Typography variant='body1'>-</Typography>
            <Typography variant='body1'>{getText('septic-tank-access-limitations-take-other-steps')}</Typography>
          </Stack>
        )}
      </Grid>
      {treatmentPlant.weightLimit && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-weight-limit-details'
            data={treatmentPlant.weightLimitDetails}
          />
        </Grid>
      )}
      {treatmentPlant.other && (
        <Grid item xs={2}>
          <ShowSummaryItem
            labelKey='septic-tank-access-limitations-option-weight-other'
            data={treatmentPlant.otherDetails}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <ShowSummaryItem labelKey='septic-tank-access-limitations-location' data={treatmentPlant.location} />
      </Grid>
    </Grid>
  );
};

export default PropertyTreatmentPlantSummary;
