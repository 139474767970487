import { useContext } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { LocationInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import TextInput from '../../../../components/common-materialui/form/TextInput';
import FormErrorField from '../../../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../../../util/input/validation';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import { LanguageContext } from '../../../../contexts/language-context';
import { addDays } from 'date-fns';
import DateInput from '../../../../components/common-materialui/form/DateInput';

interface Props {
  control: Control<LocationInfoFormData, any>;
  errors: FieldErrors<LocationInfoFormData>;
}

const ContractInfoSection = ({ control, errors }: Props): JSX.Element => {
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  return (
    <Stack direction='column' width={width}>
      {/* TODO: correct ids for label and date component or use some MUI datepicker */}
      <label>
        <Typography variant='body2' color={palette.text.secondary} ml={1}>
          {getText('add-emptying-location-contract-info-start-date')}
        </Typography>
      </label>

      <Controller
        control={control}
        name='startDate'
        rules={{
          validate: { validateFunc: (v) => validateInput(v ? v.toString() : '', ['required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Box mb={1} mt={0.5}>
              <DateInput date={value} onChange={onChange} minDate={addDays(new Date(), 1)} />
            </Box>
          );
        }}
      />
      <FormErrorField errorMsg={errors.startDate?.message} />

      <Controller
        control={control}
        name='referenceNumber'
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput val={value} setter={onChange} label={'add-emptying-location-contract-info-reference-number'} />
          );
        }}
      />
    </Stack>
  );
};

export default ContractInfoSection;
