import { useContext } from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';

type InputProps = {
  open: boolean;
  titleMessageKey?: keyof UiTexts;
  buttonLabelKey?: keyof UiTexts;
  descriptionMessageKey: keyof UiTexts;
  onClose: () => void;
};

const ApiMessageDialog = ({
  open,
  titleMessageKey,
  buttonLabelKey,
  descriptionMessageKey,
  onClose,
}: InputProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {titleMessageKey && (
        <DialogTitle id='approve-dialog-title'>
          <Typography>{getText(titleMessageKey)}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Typography>{getText(descriptionMessageKey)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button size='large' variant='contained' onClick={() => handleClose()}>
          {buttonLabelKey ? getText(buttonLabelKey) : getText('dialog-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiMessageDialog;
