import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import { UiTexts } from '../../../model';
import { Validation, validateInput } from '../../../util/input/validation';
import TextInput from './TextInput';
import FormErrorField from './FormErrorField';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: FieldError | undefined;
  label: keyof UiTexts;
  validations: Validation[];
  inputType?: string;
  multiline?: boolean;
  maxLength?: number;
  guideText?: string;
  maxValue?: number;
  disabled?: boolean;
  valueTransformer?: (value: any) => string | null;
}

const ControlTextInput = <T extends FieldValues>({
  control,
  label,
  name,
  error,
  validations,
  inputType,
  multiline,
  maxLength,
  guideText,
  maxValue,
  disabled,
  valueTransformer,
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: { validateFunc: (v) => validateInput(v, validations)[0] },
      }}
      render={({ field: { onChange, value } }) => {
        const displayValue = valueTransformer ? valueTransformer(value) : value ?? '';

        return (
          <>
            <TextInput
              val={displayValue !== null ? displayValue : ''}
              setter={(inputValue: any) => {
                if (maxValue && inputType === 'number') {
                  const numericValue = parseInt(inputValue, 10);
                  if (!isNaN(numericValue) && numericValue > maxValue) return;
                }
                onChange(inputValue);
              }}
              label={label}
              inputType={inputType}
              multiline={multiline}
              maxLength={maxLength}
              guideText={guideText}
              disabled={disabled ?? false}
            />
            <FormErrorField errorMsg={error?.message} />
          </>
        );
      }}
    />
  );
};

export default ControlTextInput;
