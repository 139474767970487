import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { BillingInfoFormData, LocationInfoFormData } from './models';
import { LargerThanBreakpoint, SmallerThanBreakpoint } from '../../../util/viewportUtils';
import { overlayDialogPadding } from '../../../components/common-materialui/dialogs/OverlayDialog';
import BillingSummary from './summary/BillingSummary';
import LocationSummary from './summary/LocationSummary';

interface Props {
  onClose: () => void;
  customerType: 'private' | 'company';
  billingInfoFormData: BillingInfoFormData;
  locationInfoFormData: LocationInfoFormData;
}

const Summary = ({ onClose, customerType, billingInfoFormData, locationInfoFormData }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const smallViewMargin = LargerThanBreakpoint('md') ? 8 : 0;

  function renderSmallView() {
    return (
      <>
        <Container>
          <Stack p={overlayDialogPadding} direction='column' spacing={3}>
            <Typography variant={'h6'}>{getText('add-emptying-location-billing-data')}</Typography>
            <Box pl={smallViewMargin}>
              <BillingSummary billingInfoFormData={billingInfoFormData} customerType={customerType} />
            </Box>
          </Stack>
        </Container>
        <Divider />
        <Container>
          <Stack p={overlayDialogPadding} direction='column' spacing={3}>
            <Typography variant={'h6'}>{getText('add-emptying-location-data')}</Typography>
            <Box pl={smallViewMargin}>
              <LocationSummary customerType={customerType} locationInfoFormData={locationInfoFormData} />
            </Box>
          </Stack>
        </Container>
      </>
    );
  }

  function renderLargeView() {
    return (
      <>
        <Container>
          <Stack p={overlayDialogPadding} direction='row' justifyContent='space-between'>
            <Typography variant={'h5'}>{getText('add-emptying-location-billing-data')}</Typography>
            <BillingSummary billingInfoFormData={billingInfoFormData} customerType={customerType} />
          </Stack>
        </Container>
        <Divider />
        <Container>
          <Stack p={overlayDialogPadding} direction='row' justifyContent='space-between'>
            <Typography variant={'h5'}>{getText('add-emptying-location-data')}</Typography>
            <LocationSummary customerType={customerType} locationInfoFormData={locationInfoFormData} />
          </Stack>
        </Container>
      </>
    );
  }

  return (
    <Stack direction='column'>
      {SmallerThanBreakpoint('lg') ? renderSmallView() : renderLargeView()}

      <Divider />

      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button variant='contained' size='large' onClick={onClose}>
              {getText('add-emptying-location-close')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Summary;
