import { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Stack, Container, Divider, Typography, Button } from '@mui/material';
import OverlayDialog, { overlayDialogPadding } from '../../../../components/common-materialui/dialogs/OverlayDialog';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import ProgressComponent from '../../../../components/common-materialui/ProgressComponent';
import {
  AllInvoicingMethodTypes,
  BillingInfo,
  ConsumerEInvoiceType,
  ExtendedInvoiceMethodType,
  SaveCompanyBillingInfoReq,
  SavePrivateCustomerBillingInfoReq,
  OnlineBroker,
} from '../../../../model';
import ApiContext from '../../../../contexts/api-context';
import { LanguageContext } from '../../../../contexts/language-context';
import EditBillingInfoCompanyForm from './EditBillingInfoCompanyForm';
import EditBillingInfoPrivateForm from './EditBillingInfoPrivateForm';
import EditBillingInfoContactPersonForm from './EditBillingInfoContactPersonForm';
import { convertExtendedInvoicingMethod, determineInvoicingMethod } from '../../../../util/invoicingMethodUtils';
import { LoadingButton } from '@mui/lab';

export interface EditBillingInfoFormData {
  companyName: string;
  businessId: string;
  isCompany: boolean;
  firstName: string;
  lastName: string;
  street: string;
  postalCode: string;
  postOffice: string;
  phoneNumber: string;
  emailInvoicing: boolean;
  email: string;
  contactFirstname: string;
  contactLastname: string;
  contactPhonenumber: string;
  contactEmail: string;
  invoicingMethod: string;
  eInvoicingAddress: string;
  eInvoicingOperator: string;
  eInvoicingEdiCode: string;
}

interface Props {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  billingInfo?: BillingInfo;
  emptyingId: string;
}

function makeCompanySavingData(
  formData: EditBillingInfoFormData,
  invoicingMethodType: AllInvoicingMethodTypes
): SaveCompanyBillingInfoReq {
  const data: SaveCompanyBillingInfoReq = {
    companyName: formData.companyName,
    phoneNumber: formData.phoneNumber?.replace(/\s/g, ''),
    billingEmail: formData.email,
    billingAddress: formData.street + ',' + formData.postalCode + ' ' + formData.postOffice,
    contactName: formData.contactLastname + ' ' + formData.contactFirstname,
    eInvoiceInformation: undefined,
    billingContactPhone: formData.contactPhonenumber?.replace(/\s/g, ''),
    consumerEInvoiceType: convertExtendedInvoicingMethod(invoicingMethodType),
  };

  if (invoicingMethodType === ExtendedInvoiceMethodType.OnlineInvoicing) {
    data.eInvoiceInformation = {
      address: formData.eInvoicingAddress,
      operator: formData.eInvoicingOperator,
      ediCode: formData.eInvoicingEdiCode,
    };
  }
  return data;
}

function makePrivateCustomerSavingData(
  formData: EditBillingInfoFormData,
  invoicingMethodType: AllInvoicingMethodTypes
): SavePrivateCustomerBillingInfoReq {
  const data: SavePrivateCustomerBillingInfoReq = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    name: formData.firstName + ' ' + formData.lastName,
    phoneNumber: formData.phoneNumber.replace(/\s/g, ''),
    billingEmail: formData.email,
    billingAddress: formData.street + ',' + formData.postalCode + ' ' + formData.postOffice,
    contactPerson: formData.contactLastname + ' ' + formData.contactFirstname,
    consumerEInvoiceType: convertExtendedInvoicingMethod(invoicingMethodType),
  };
  return data;
}

const EditBillingInfo: FC<Props> = ({ open, handleClose, billingInfo, emptyingId }) => {
  const { getText } = useContext(LanguageContext);
  const { saveCompanyBillingInfo, savePrivateCustomerBillingInfo, fetchOnlineBrokers } = useContext(ApiContext);
  const queryClient = useQueryClient();
  const [invoicingMethod, setInvoicingMethod] = useState<AllInvoicingMethodTypes>(
    billingInfo !== undefined ? determineInvoicingMethod(billingInfo).invoicingMethodType : ConsumerEInvoiceType.Unknown
  );
  const [emailInvoicing, setEmailInvoicing] = useState<boolean>(
    billingInfo ? billingInfo.consumerEInvoiceType === ConsumerEInvoiceType.EmailInvoicing : false
  );
  const isCompany = billingInfo?.isCompany && billingInfo?.isCompany === true ? true : false;
  const billingNameArray = billingInfo?.name?.split(/ (.+)/).slice(0, 2) ?? ['', ''];
  const contactNameArray = billingInfo?.contactPerson?.name.split(/ (.+)/).slice(0, 2) ?? ['', ''];

  const [brokers, setBrokers] = useState<OnlineBroker[]>([]);

  useEffect(() => {
    setEmailInvoicing(invoicingMethod === ConsumerEInvoiceType.EmailInvoicing);
    if (isCompany) {
      fetchOnlineBrokers().then((data) => setBrokers(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicingMethod]);

  const defaultFormData = {
    companyName: billingInfo?.name,
    businessId: billingInfo?.companyId,
    isCompany: isCompany,
    firstName: billingNameArray[1],
    lastName: billingNameArray[0],
    street: billingInfo?.address.street,
    postalCode: billingInfo?.address.postalCode,
    postOffice: billingInfo?.address.postOffice,
    phoneNumber: billingInfo?.billingPhone ? billingInfo?.billingPhone : '',
    emailInvoicing: emailInvoicing,
    email: billingInfo?.billingEmail ? billingInfo.billingEmail : '',
    contactFirstname: contactNameArray[1] ?? '',
    contactLastname: contactNameArray[0] ?? '',
    contactPhonenumber: billingInfo?.contactPerson?.phoneNumber,
    contactEmail: billingInfo?.billingEmail ? billingInfo.billingEmail : '',
    invoicingMethod: invoicingMethod,
    eInvoicingAddress: billingInfo?.eInvoicing?.address,
    eInvoicingOperator: billingInfo?.eInvoicing?.operator,
    eInvoicingEdiCode: billingInfo?.eInvoicing?.ediCode,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm<EditBillingInfoFormData>({
    defaultValues: defaultFormData,
  });

  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  function closeAndInvalidateOnSuccess() {
    queryClient.invalidateQueries(['billing-info', emptyingId]);
    handleClose(false);
  }

  const { mutate: updateBillingInformationCompany, isLoading: updateBillingInformationCompanyIsLoading } = useMutation(
    (data: SaveCompanyBillingInfoReq) => saveCompanyBillingInfo(billingInfo?.id ?? '', data),
    {
      onSuccess: () => closeAndInvalidateOnSuccess(),
    }
  );

  const { mutate: updateBillingInformationPrivate, isLoading: updateBillingInformationPrivateIsLoading } = useMutation(
    (data: SavePrivateCustomerBillingInfoReq) => savePrivateCustomerBillingInfo(billingInfo?.id ?? '', data),
    {
      onSuccess: () => closeAndInvalidateOnSuccess(),
    }
  );

  function submitUpdate(formData: any) {
    if (!isDirty) {
      handleClose(false);
      return;
    }

    if (isCompany) {
      updateBillingInformationCompany(makeCompanySavingData(formData, invoicingMethod));
    } else {
      updateBillingInformationPrivate(makePrivateCustomerSavingData(formData, invoicingMethod));
    }
    reset(getValues());
  }

  return (
    <OverlayDialog open={open} onClose={() => handleClose(false)} headerTextKey='billing-data-edit'>
      <Container>
        <Stack direction={orientation} p={overlayDialogPadding} justifyContent={'space-between'} spacing={1}>
          <Typography variant='h6'>{getText('billing-data')}</Typography>
          {isCompany ? (
            <EditBillingInfoCompanyForm
              control={control}
              getFormValues={getValues}
              handleSubmit={handleSubmit}
              setInvoicingMethod={setInvoicingMethod}
              errors={errors}
              brokers={brokers}
            />
          ) : (
            <EditBillingInfoPrivateForm
              control={control}
              getFormValues={getValues}
              handleSubmit={handleSubmit}
              setInvoicingMethod={setInvoicingMethod}
              errors={errors}
            />
          )}
        </Stack>
      </Container>

      <Divider />

      <Container>
        <Stack direction={orientation} p={overlayDialogPadding} justifyContent={'space-between'} spacing={1}>
          <Typography variant='h6'>{getText('billing-data-billing-contact-person')}</Typography>
          <EditBillingInfoContactPersonForm control={control} getFormValues={getValues} errors={errors} />
        </Stack>
      </Container>

      <Divider />

      <ProgressComponent
        isLoading={isCompany ? updateBillingInformationCompanyIsLoading : updateBillingInformationPrivateIsLoading}
      />
      <Container>
        <Stack spacing={1} direction='row' justifyContent={'end'}>
          <Button color='secondary' onClick={() => handleClose(false)}>
            {getText('billing-data-save-cancel')}
          </Button>
          <LoadingButton
            variant='contained'
            onClick={handleSubmit((x) => submitUpdate(x))}
            sx={{ paddingY: 1.75, marginTop: 2 }}
            loading={updateBillingInformationCompanyIsLoading || updateBillingInformationPrivateIsLoading}
          >
            {getText('billing-data-save')}
          </LoadingButton>
        </Stack>
      </Container>
    </OverlayDialog>
  );
};

export default EditBillingInfo;
