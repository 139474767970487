import { useContext, useEffect } from 'react';
import ApiContext from '../../contexts/api-context';
import { Language } from '../../model';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';

type AuthenticateProps = {
  language: Language;
};

const Authenticate = (props: AuthenticateProps): JSX.Element => {
  const api = useContext(ApiContext);
  const language = props.language;

  useEffect(() => {
    RedirectToLogin();
  }, []);

  const RedirectToLogin = () => {
    const url = api.getSamlAuthenticationUrl() + '?language=' + language;
    window.location.replace(url);
  };

  return <ProgressComponent isLoading />;
};

export default Authenticate;
