import { useContext } from 'react';
import { Stack, Container, Box, Button, Divider } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';
import PropertyWaterSupplySummary from './summary/PropertyWaterSupplySummary';
import { WellCollectionState } from './models';
import PropertyWasteWaterTreatmentSummary from './summary/PropertyWasteWaterTreatment';

interface Props {
  onPreviousPhaseChange: () => void;
  wellCollectionInfo: WellCollectionState;
}

const WasteWaterReportSummary = ({ onPreviousPhaseChange, wellCollectionInfo }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  return (
    <Stack direction={'column'}>
      <PropertyWaterSupplySummary waterSupplyInfo={wellCollectionInfo.houseHoldWaterInfo} />
      <Divider />
      <PropertyWasteWaterTreatmentSummary
        wasteWaterOptions={wellCollectionInfo.wasteWater}
        septicTank={wellCollectionInfo.septicTank}
        septicTankOptionB={wellCollectionInfo.septicTankOptionB}
        treatmentPlant={wellCollectionInfo.treatmentPlant}
        closedTank={wellCollectionInfo.closedTank}
        other={wellCollectionInfo.other}
      />
      <Divider />
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='primary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Box>
            <Button variant='contained' size='large'>
              {getText('dialog-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default WasteWaterReportSummary;
