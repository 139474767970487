import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import { CompostingForm, CompostingNotificationInput, EmptyingInfo, UiTexts } from '../../model';
import Form from '@rjsf/mui';
import { LanguageContext } from '../../contexts/language-context';
import { templateFormErrors } from '../../util/util';
import AutoPopulateSelect from '../../util/custom-widgets/AutoPopulateSelect';
import CustomDateWidget from '../../util/custom-widgets/CustomDateWidget';
import { ArrayFieldTemplateAddAndRemove } from '../../util/templateUtils';
import { flatten } from 'flat';
import CustomEnumWidget from '../../util/custom-widgets/CustomEnumWidget';
import CustomCheckboxesWidget from '../../util/custom-widgets/CustomCheckboxesWidget';
import { TemplatesType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import CustomRadioWidget from '../../util/custom-widgets/CustomRadioWidget';
import CustomNullField from '../../util/custom-widgets/CustomNullField';
import { Box, Button, Container, SelectChangeEvent, Stack, ThemeProvider, Typography } from '@mui/material';
import { LargerThanBreakpoint } from '../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from '../../views/account-settings/models';
import { useMutation, useQuery } from '@tanstack/react-query';
import ApiMessageDialog from '../../components/common-materialui/dialogs/ApiMessageDialog';
import DropdownSelect, { ValueText } from '../../components/common-materialui/DropdownSelect';
import { overlayDialogPadding } from '../../components/common-materialui/dialogs/OverlayDialog';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import ExtendedAlert from '../../components/common-materialui/status-handling/ExtendedAlert';
import FormThemeProvider from '../../util/FormThemeProvider';
import CustomCheckboxWidget from '../../util/custom-widgets/CustomCheckboxWidget';
import CustomDescriptionField from '../../util/custom-widgets/CustomDescriptionField';
import { parseFlattenedDataForSchema } from '../../util/schemaValueConversionUtil';

interface NewCompostingNotificationProps {
  emptyingInfos: EmptyingInfo[];
  onClose: () => void;
}

const NewCompostingNotification = ({ emptyingInfos, onClose }: NewCompostingNotificationProps): JSX.Element => {
  const theme = FormThemeProvider;
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const { getText, lang } = useContext(LanguageContext);
  const [compostingForm, setCompostingForm] = useState<CompostingForm>();
  const [compostingFormData, setCompostingFormData] = useState<object>({});
  const [emptyingIdSelection, setEmptyingSelection] = useState<string>('');
  const [emptyingInfoDropDownOptions, setEmptyingInfoDropDownOptions] = useState<ValueText[]>([]);
  const [alertErrorMsg, setAlertErrorMsg] = useState<keyof UiTexts | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const submitFormRef = useRef<HTMLButtonElement | null>();
  const formContentRef = useRef<HTMLDivElement | null>();

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  const copyEmptyingInfo = useCallback(
    (emptyingId: string) => {
      const currentEmptyingInfo = emptyingInfos?.find((i) => i.id === emptyingId);
      if (currentEmptyingInfo && compostingForm) {
        const flatted: any = flatten(currentEmptyingInfo);
        let cleaned: any = parseFlattenedDataForSchema(flatted, 'Compost');

        setCompostingFormData((prevData) => ({
          ...prevData,
          ...cleaned,
        }));
      }
    },
    [emptyingInfos, compostingForm]
  );

  useEffect(() => {
    setEmptyingInfoDropDownOptions(
      emptyingInfos.map((info) => ({
        value: info.id,
        text:
          info.name + ', ' + info.address?.street + ', ' + info.address?.postalCode + ' ' + info.address?.postOffice,
      }))
    );
    if (emptyingInfos.length > 0) {
      setEmptyingSelection(emptyingInfos[0].id);
    }
  }, [emptyingInfos]);

  // Sets initial formdata
  // checks from ref that form has loaded
  // needs separate usestate for form data, otherwise inconsistent loading
  useEffect(() => {
    if (emptyingInfos && formContentRef.current?.firstChild) {
      copyEmptyingInfo(emptyingInfos[0].id);
    }
  }, [emptyingInfos, compostingForm, formContentRef.current?.firstChild, copyEmptyingInfo]);

  const fetchCompostingForm = async (): Promise<CompostingForm> => {
    const response = await api.fetchCompostingForm(lang);
    setCompostingForm(response);
    return response;
  };

  useQuery<CompostingForm>(['composting-form'], fetchCompostingForm, {
    onError: () => setAlertErrorMsg('error-service-break-message'),
  });

  const sendComposting = async (data: CompostingNotificationInput) => {
    await api.sendCompostingNotification(data, emptyingIdSelection);
    setConfirmationDialog(true);
  };

  const { mutate: sendCompostingNotification, isLoading: sendCompostingNotificationIsLoading } = useMutation(
    (data: CompostingNotificationInput) => sendComposting(data),
    {
      onError: () => setAlertErrorMsg('error-service-break-message'),
    }
  );

  const onSubmit = (data: any) => {
    if (compostingForm?.id) {
      const newCompostNotification: CompostingNotificationInput = {
        formId: compostingForm.id,
        data: data.formData,
      };
      sendCompostingNotification(newCompostNotification);
    }
  };

  const handleChange = (data: any) => {
    setCompostingFormData(data.formData);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    submitFormRef.current?.click();
  };

  const selectEmptyingId = (e: SelectChangeEvent<string | number>): void => {
    const selectedId = e.target.value as string;
    setEmptyingSelection(selectedId);
    // Override form data on emptying id change
    copyEmptyingInfo(selectedId);
  };

  const isUiSchemaEmpty = (uiSchema: string) => {
    if (uiSchema === '') {
      return {};
    } else {
      return JSON.parse(uiSchema);
    }
  };

  const fields = { NullField: CustomNullField };

  const context = {
    domain: authService.getDomain(),
    tenantId: authService.getTenantId(),
    language: lang,
    formId: compostingForm?.id ?? null,
  };

  const customWidgets = {
    date: CustomDateWidget,
    auto: AutoPopulateSelect,
    select: CustomEnumWidget,
    checkboxes: CustomCheckboxesWidget,
    radio: CustomRadioWidget,
    checkbox: CustomCheckboxWidget,
  };

  const templates: Partial<TemplatesType> = {
    ArrayFieldTemplate: ArrayFieldTemplateAddAndRemove,
    DescriptionFieldTemplate: CustomDescriptionField,
  };

  return (
    <Container ref={(ref) => (formContentRef.current = ref)}>
      <ExtendedAlert open={!!alertErrorMsg} onClose={onClose} severity='error' sx={{ margin: 2 }}>
        {alertErrorMsg ? getText(alertErrorMsg) : null}
      </ExtendedAlert>
      <ApiMessageDialog
        open={confirmationDialog}
        buttonLabelKey='dialog-ok'
        descriptionMessageKey='add-new-composting-notification-success-message'
        onClose={onClose}
      />
      <Stack
        direction='column'
        width='100%'
        p={overlayDialogPadding}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={1}
      >
        {compostingForm?.schema ? (
          <Stack direction='column' width={width} spacing={1}>
            <DropdownSelect
              items={emptyingInfoDropDownOptions}
              value={emptyingIdSelection}
              onChange={selectEmptyingId}
            />
            <ThemeProvider theme={theme}>
              {
                <Form
                  validator={validator}
                  id='compost-form'
                  templates={templates}
                  schema={JSON.parse(compostingForm.schema)}
                  uiSchema={isUiSchemaEmpty(compostingForm.uiSchema)}
                  onSubmit={onSubmit}
                  showErrorList={false}
                  noHtml5Validate
                  liveValidate={false}
                  fields={fields}
                  omitExtraData={true}
                  liveOmit={true}
                  widgets={customWidgets}
                  formData={compostingFormData}
                  onChange={handleChange}
                  transformErrors={(e) => templateFormErrors(e, getText)}
                  formContext={context}
                >
                  <button
                    ref={(ref) => {
                      submitFormRef.current = ref;
                    }}
                    type='submit'
                    style={{ display: 'none' }}
                  />
                </Form>
              }
            </ThemeProvider>
            <Button variant='contained' disabled={sendCompostingNotificationIsLoading} onClick={handleFormSubmit}>
              {sendCompostingNotificationIsLoading ? (
                <ProgressComponent variant='circle' isLoading={sendCompostingNotificationIsLoading} />
              ) : (
                <Typography py={1} variant='button'>
                  {getText('emptying-info-send')}
                </Typography>
              )}
            </Button>
          </Stack>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' marginTop={10}>
            <ProgressComponent variant='circle' isLoading />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default NewCompostingNotification;
