import { useContext } from 'react';
import { Container, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Control, FieldErrors, Controller, useWatch } from 'react-hook-form';
import { LanguageContext } from '../../../../../contexts/language-context';
import { Validation, validateInput } from '../../../../../util/input/validation';
import FormErrorField from '../../../../../components/common-materialui/form/FormErrorField';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import { OtherForm, OtherRadioGroupOptionsEnum, WellCollectionWCType } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';

export const defaultOtherValues = {
  selection: OtherRadioGroupOptionsEnum.grayWaterFilter,
  handlingMethod: '',
};
interface Props {
  control: Control<OtherForm>;
  errors: FieldErrors<OtherForm>;
  wcType: string;
}

const Other = ({ control, errors, wcType }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';

  const radioGroupValue = useWatch({
    control,
    name: 'selection',
  });
  const validations: Validation[] = [];

  if (wcType === WellCollectionWCType.WaterWC) {
    validations.push('required');
  }

  return (
    <Container>
      <Grid container wrap='nowrap' marginTop={2} direction={direction} spacing={1} justifyContent={'space-between'}>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant='h6'>{getText('waste-water-treatment-option-G')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Controller
            control={control}
            name='selection'
            rules={{
              validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <RadioGroup aria-labelledby='radio-group-for-selecting-contract-edit' value={value} onChange={onChange}>
                  <Stack direction={'column'}>
                    <FormControlLabel
                      sx={{ marginRight: 5 }}
                      value={OtherRadioGroupOptionsEnum.grayWaterFilter}
                      control={<Radio />}
                      label={getText('other-grey-water-filter-option')}
                    />
                    <FormControlLabel
                      sx={{ marginRight: 5 }}
                      value={OtherRadioGroupOptionsEnum.groundFilter}
                      control={<Radio />}
                      label={getText('other-ground-filter-option')}
                    />
                    <FormControlLabel
                      sx={{ marginRight: 5 }}
                      value={OtherRadioGroupOptionsEnum.noWasteWaterTreatmentSystem}
                      control={<Radio />}
                      label={getText('other-no-waste-water-treatment-system-option')}
                    />
                  </Stack>
                  <FormErrorField errorMsg={errors.selection?.message} />
                </RadioGroup>
              );
            }}
          />
          {radioGroupValue === OtherRadioGroupOptionsEnum.noWasteWaterTreatmentSystem && (
            <ControlTextInput
              multiline
              label='other-no-waste-water-treatment-system-input'
              control={control}
              name={'handlingMethod'}
              validations={validations}
              inputType='string'
              error={errors.handlingMethod}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Other;
