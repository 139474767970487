import React, { useEffect } from 'react';
import FormThemeProvider from '../FormThemeProvider';
import { FormControl, FormLabel, ThemeProvider } from '@mui/material';
import { getUiSchemaTitle } from './utils';

const CustomNullField = function (props: any) {
  const [label, setLabel] = React.useState<string>(props.label);
  const theme = FormThemeProvider;

  useEffect(() => {
    setLabel(getUiSchemaTitle(props));
  }, [props.formContext.formId, props.formContext.language]);

  return (
    <ThemeProvider theme={theme}>
      <FormControl required={props.required}>
        <FormLabel>{label}</FormLabel>
      </FormControl>
    </ThemeProvider>
  );
};

export default CustomNullField;
