import {
  AddExtraEmptyingReq,
  AddNewContractReq,
  BillingInfo,
  CategoryWithProducts,
  ContractInfo,
  EmptyingInfo,
  FeedbackInfo,
  FeedbackInput,
  InvoiceInfo,
  NewOrderReq,
  NewPickUpOrderReq,
  NewPickUpOrderReply,
  Order,
  ProductCategories,
  SaveCompanyBillingInfoReq,
  SavePrivateCustomerBillingInfoReq,
  SaveUserInfoReq,
  TemplateInfo,
  UserAccountInfo,
  EmptyingIntervalUpdate,
  IdentifiedPersonInfo,
  PermitApplicationInput,
  PermitApplicationReply,
  PermitFormReply,
  NewBillingInfoPrivate,
  NewBillingInfoCompany,
  NewEmptyingInfo,
  TemplateType,
  Language,
  MessageCategory,
  CompostingForm,
  CompostingNotificationInput,
  PriceCalculationResult,
  PriceCalculationRequest,
  NewDumpsterOrderReq,
  VingoProduct,
  UpdateEmptyingInfoPrivateReq,
  UpdateEmptyingInfoCompanyReq,
  UserLanguageUpdateInput,
  BuildingType,
  BuildingClassification,
  Area,
  CustomerEServiceSettings,
  Conurbation,
  OnlineBroker,
} from '../model';
import AuthService from './authService';
import _ from 'lodash';

/**
 * Functions for using endpoints that need authentication.
 */
export default class ApiService {
  private authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  async fetchIsDuplicatePersonalIdentifier(identificationId: string): Promise<boolean> {
    return this.authService.fetchIsDuplicatePersonalIdentifier(identificationId);
  }

  public getSamlAuthenticationUrl = () => {
    return this.authService.getSamlAuthenticationUrl();
  };

  public getSamlLogoutUrl = () => {
    return this.authService.getSamlLogoutUrl();
  };

  async fetchEmptyingInfos(): Promise<EmptyingInfo[]> {
    const infos = await this.authService.fetch('Customers/emptying-infos', {
      method: 'GET',
    });

    if (infos) {
      return infos.sort((a: EmptyingInfo, b: EmptyingInfo) => a.address.street.localeCompare(b.address.street));
    }

    return infos;
  }

  fetchBuildingUsageTypes = async (language: Language): Promise<BuildingClassification[]> => {
    return this.authService.fetch(
      `Company/${this.authService.getTenantId()}/usage-types/${language}`,

      { method: 'GET' }
    );
  };

  fetchBuildingTypes = async (language: Language): Promise<BuildingType[]> => {
    return this.authService.fetch(
      `Company/${this.authService.getTenantId()}/building-types/${language}`,

      { method: 'GET' }
    );
  };

  fetchAreas = async (language: Language): Promise<Area[]> => {
    return this.authService.fetch(
      `Company/${this.authService.getTenantId()}/municipalities/${language}`,

      { method: 'GET' }
    );
  };

  fetchConurbations = async (): Promise<Conurbation[]> => {
    return this.authService.fetch(
      `Company/${this.authService.getTenantId()}/conurbations`,

      { method: 'GET' }
    );
  };

  async fetchIdentifiedPersonInfo(identificationId: string): Promise<IdentifiedPersonInfo> {
    return this.authService.fetchIdentificationInfo(identificationId);
  }

  fetchEmptyingInfo = async (emptyingId: string): Promise<EmptyingInfo> => {
    const info = await this.authService.fetch(`Customers/emptying-infos/${emptyingId}`, {
      method: 'GET',
    });
    return info;
  };

  async fetchEmptyingBillingInfo(emptyingId: string): Promise<BillingInfo[]> {
    return this.authService.fetch(`Customers/emptying-infos/${emptyingId}/billing-info`, {
      method: 'GET',
    });
  }

  // TODO: Check if it's really ok to just return the first element.
  fetchFirstBillingInfoForEmptyingData = async (emptyingId: string): Promise<BillingInfo | undefined> => {
    const billingInfos = await this.authService.fetch(`Customers/emptying-infos/${emptyingId}/billing-info`, {
      method: 'GET',
    });

    return _.head(billingInfos) || undefined;
  };

  updateEmptyingInfoPrivate = async (emptyingId: string, input: UpdateEmptyingInfoPrivateReq): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${emptyingId}/private`, {
      method: 'PATCH',
      body: JSON.stringify(input),
    });
  };

  updateEmptyingInfoCompany = async (emptyingId: string, input: UpdateEmptyingInfoCompanyReq): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${emptyingId}/company`, {
      method: 'PATCH',
      body: JSON.stringify(input),
    });
  };

  fetchContracts = async (emptyingId: string): Promise<ContractInfo[]> => {
    return this.authService.fetch(`Customers/emptying-infos/${emptyingId}/contracts`, { method: 'GET' });
  };

  endContract = async (
    customerNumber: string,
    position: string,
    endTime: Date,
    endReason?: string,
    endAdditionalInformation?: string
  ): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/end`, {
      method: 'PATCH',
      body: JSON.stringify({ endTime, endReason, endAdditionalInformation }),
    });
  };

  pauseContract = async (
    customerNumber: string,
    position: string,
    startTime: Date,
    endTime: Date,
    pauseReason: string,
    pauseAdditionalInformation: string
  ): Promise<void> => {
    startTime = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
    endTime = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000);
    return this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/pause`, {
      method: 'PATCH',
      body: JSON.stringify({ startTime, endTime, pauseReason, pauseAdditionalInformation }),
    });
  };

  changeAmountContract = async (customerNumber: string, position: string, amount: number): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/amount`, {
      method: 'PATCH',
      body: JSON.stringify({ amount }),
    });
  };

  changeContractDriverInstructions = async (customerNumber: string, position: string, gateKeyCode: string) => {
    return this.authService.fetch(
      `Customers/emptying-infos/${customerNumber}/contracts/${position}/driver-instructions`,
      {
        method: 'PATCH',
        body: JSON.stringify({ gateKeyCode }),
      }
    );
  };

  approveContract = async (customerNumber: string, position: string): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/status`, {
      method: 'PATCH',
      body: JSON.stringify({ status: 'Approved' }),
    });
  };

  rejectContract = async (customerNumber: string, position: string): Promise<void> => {
    return this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/status`, {
      method: 'PATCH',
      body: JSON.stringify({ status: 'Rejected' }),
    });
  };

  async fetchOrders(emptyingId: string): Promise<Order[]> {
    return this.authService.fetch(`Customers/emptying-infos/contracts/orders?customerNumbers=${emptyingId}`, {
      method: 'GET',
    });
  }

  fetchOrdersBetweenDates = async (emptyingId: string, begin: string, end: string): Promise<Order[]> => {
    return this.authService.fetch(
      `Customers/emptying-infos/contracts/orders/between?customerNumbers=${emptyingId}&begin=${begin}&end=${end}`,
      { method: 'GET' }
    );
  };

  fetchUserAccountInfo = async (): Promise<UserAccountInfo> => {
    const info = await this.authService.fetch('Users/account', {
      method: 'GET',
    });
    return info;
  };

  async fetchCustomerBillingInfo(): Promise<BillingInfo> {
    const billingInfos = await this.authService.fetch('Customers/billing-infos', {
      method: 'GET',
    });
    const info = billingInfos[0];
    return info;
  }

  async fetchCustomerBillingInfos(): Promise<BillingInfo[]> {
    const billingInfos = await this.authService.fetch('Customers/billing-infos', {
      method: 'GET',
    });
    return billingInfos;
  }

  saveUserAccountInfo = async (userAccountInfo: SaveUserInfoReq): Promise<void> => {
    await this.authService.fetch('Users/account', {
      method: 'PUT',
      body: JSON.stringify(userAccountInfo),
    });
  };

  savePrivateCustomerBillingInfo = async (
    billingId: string,
    info: SavePrivateCustomerBillingInfoReq
  ): Promise<void> => {
    await this.authService.fetch(`Customers/billing-infos/${billingId}/private`, {
      method: 'PUT',
      body: JSON.stringify(info),
    });
  };

  saveCompanyBillingInfo = async (billingId: string, info: SaveCompanyBillingInfoReq): Promise<void> => {
    await this.authService.fetch(`Customers/billing-infos/${billingId}/company`, {
      method: 'PUT',
      body: JSON.stringify(info),
    });
  };

  addExtraEmptying = async (
    customerNumber: string,
    position: string,
    emptyingData: AddExtraEmptyingReq
  ): Promise<void> => {
    await this.authService.fetch(`Customers/emptying-infos/${customerNumber}/contracts/${position}/extra-emptying`, {
      method: 'POST',
      body: JSON.stringify(emptyingData),
    });
  };

  async addOrders(customerNumber: string, reqBody: NewOrderReq): Promise<void> {
    await this.authService.fetch(`Customers/orders/${customerNumber}`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
  }

  async addDumpsterOrders(customerNumber: string, reqBody: NewDumpsterOrderReq): Promise<void> {
    await this.authService.fetch(`Customers/orders/${customerNumber}/container`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
  }

  async addPickUpOrders(customerNumber: string, reqBody: NewPickUpOrderReq): Promise<NewPickUpOrderReply> {
    const pickUpOrderReply = await this.authService.fetch(`Customers/orders/${customerNumber}/pickup`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
    return pickUpOrderReply;
  }

  fetchContractDetails = async (customerNumber: string, position: number): Promise<ContractInfo> => {
    const contract = await this.authService.fetch(`Customers/contracts/${customerNumber}/${position}`, {
      method: 'GET',
    });
    return contract;
  };

  fetchProductCategories = async (emptyingId: string): Promise<ProductCategories> => {
    const contract = await this.authService.fetch(`product-categories?emptyingId=${emptyingId}`, {
      method: 'GET',
    });
    return contract;
  };

  fetchCategoryWithProducts = async (emptyingId: string, categoryId: string): Promise<CategoryWithProducts> => {
    let contract: CategoryWithProducts = await this.authService.fetch(
      `product-categories/${categoryId}?emptyingId=${emptyingId}`,
      {
        method: 'GET',
      }
    );

    contract.products = _.sortBy(contract.products, ['name', 'id']);
    return contract;
  };

  fetchProductRules = async (emptyingId: string, productId: string): Promise<VingoProduct> => {
    const contract = await this.authService.fetch(
      `product-categories/product-details/${productId}?emptyingId=${emptyingId}`,
      {
        method: 'GET',
      }
    );
    return contract;
  };

  async addNewContract(emptyingId: string, addNewOrderReq: AddNewContractReq): Promise<void> {
    await this.authService.fetch(`Customers/emptying-infos/${emptyingId}/contracts`, {
      method: 'POST',
      body: JSON.stringify(addNewOrderReq),
    });
  }

  async fetchFeedbackItems(): Promise<FeedbackInfo[]> {
    const feedbackInfo = await this.authService.fetch(`customers/feedbacks`, {
      method: 'GET',
    });
    return feedbackInfo;
  }

  async sendFeedback(input: FeedbackInput): Promise<void> {
    await this.authService.fetch('customers/feedbacks', {
      method: 'POST',
      body: JSON.stringify(input),
    });
  }

  async fetchInvoices(): Promise<InvoiceInfo[]> {
    const invoiceInfo = await this.authService.fetch(`customers/invoices`, {
      method: 'GET',
    });
    return invoiceInfo;
  }

  updateEmptyingIntervals = async (
    customerNumber: string,
    position: string,
    emptyingIntervals: EmptyingIntervalUpdate[]
  ): Promise<void> => {
    await this.authService.fetch(`Customers/emptying-interval/${customerNumber}/${position}/update`, {
      method: 'PATCH',
      body: JSON.stringify({ emptyingIntervals }),
    });
  };

  async fetchTemplate(Id: string): Promise<TemplateInfo> {
    const templateInfo = await this.authService.fetch(`template/${Id}`, {
      method: 'GET',
    });
    return templateInfo;
  }

  async fetchActiveTemplate(type: TemplateType, language: Language): Promise<TemplateInfo> {
    const templateInfo = await this.authService.fetch(`template/${type}/${language}/active`, {
      method: 'GET',
    });
    return templateInfo;
  }

  async fetchInvoicePdf(invoiceNumber: number): Promise<any> {
    const pdf = await this.authService.fetchFile(`customers/invoices/${invoiceNumber}/pdf`, {
      method: 'GET',
      typeOfFile: 'pdf',
    });
    return pdf;
  }

  async fetchCompostingForm(languageCode: string): Promise<CompostingForm> {
    const form = this.authService.fetch(`Template/CompostInformation/${languageCode}/active`, {
      method: 'GET',
    });
    return form;
  }

  async sendCompostingNotification(
    newCompostingNotification: CompostingNotificationInput,
    emptyingId: string
  ): Promise<void> {
    await this.authService.fetch(`compost/${emptyingId}`, {
      method: 'POST',
      body: JSON.stringify(newCompostingNotification),
    });
  }

  async getCompostingNotifications(emptyingIds: string[]): Promise<any> {
    const queryString = emptyingIds.map((id) => `emptyingIds=${encodeURIComponent(id)}`).join('&');
    const compostingNotifications = await this.authService.fetch(`compost?${queryString}`, { method: 'GET' });
    return compostingNotifications;
  }

  async sendPermitApplication(newPermitApplication: PermitApplicationInput): Promise<void> {
    await this.authService.fetch(`PermitApplication/permit-application`, {
      method: 'POST',
      body: JSON.stringify(newPermitApplication),
    });
  }

  async fetchPermitDecisionPdf(permitAppId: string): Promise<any> {
    const pdf = await this.authService.fetchFile(`PermitApplication/permit-application/${permitAppId}/decision`, {
      method: 'GET',
      typeOfFile: 'pdf',
    });
    return pdf;
  }

  async fetchPermitApplications(): Promise<PermitApplicationReply[]> {
    const permitApplications: PermitApplicationReply[] = await this.authService.fetch(
      `PermitApplication/permit-application`,
      {
        method: 'GET',
      }
    );
    return permitApplications;
  }

  async fetchPermitForms(languageCode: string): Promise<PermitFormReply[]> {
    const permitForms: PermitFormReply[] = await this.authService.fetch(
      `PermitApplication/permit-forms/${languageCode}`,
      {
        method: 'GET',
      }
    );
    return permitForms;
  }

  async fetchPermitFormById(formId: string): Promise<PermitFormReply> {
    const permitForm: PermitFormReply = await this.authService.fetch(`PermitApplication/permit-form/${formId}`, {
      method: 'GET',
    });
    return permitForm;
  }

  async addBillingAccountPrivate(billingData: NewBillingInfoPrivate): Promise<void> {
    await this.authService.fetch(`Users/account/billing-infos`, {
      method: 'POST',
      body: JSON.stringify(billingData),
    });
  }

  async addBillingAccountCompany(billingData: NewBillingInfoCompany): Promise<void> {
    await this.authService.fetch(`Users/account/billing-infos`, {
      method: 'POST',
      body: JSON.stringify(billingData),
    });
  }

  async addEmptyingAccount(billingAccount: string, emptyingData: NewEmptyingInfo): Promise<any> {
    const emptyingNumber = await this.authService.fetch(`customers/billing-infos/${billingAccount}/emptying-info`, {
      method: 'POST',
      body: JSON.stringify(emptyingData),
    });
    return emptyingNumber;
  }

  calculatePrice = async (newPriceCalculationRequest: PriceCalculationRequest): Promise<PriceCalculationResult> => {
    const priceResult: PriceCalculationResult = await this.authService.fetch(`ProductPrice/calculate`, {
      method: 'POST',
      body: JSON.stringify(newPriceCalculationRequest),
    });
    return priceResult;
  };

  async fetchMessageCategories(tenantId: string, languageCode: string): Promise<MessageCategory[]> {
    const categories = await this.authService.fetch(`Company/${tenantId}/feedback-categories/${languageCode}`, {
      method: 'GET',
    });
    return categories;
  }

  updateUserLanguage = async (languageInput: UserLanguageUpdateInput): Promise<void> => {
    await this.authService.fetch(`Users/language`, {
      method: 'PATCH',
      body: JSON.stringify(languageInput),
    });
  };

  fetchEServiceCustomerSettings = async (keys: string[]): Promise<CustomerEServiceSettings> => {
    const url = `Company/${this.authService.getTenantId()}/settings`;
    const response: CustomerEServiceSettings = await this.authService.fetch(url, {
      method: 'GET',
    });
    return response;
  };

  fetchOnlineBrokers = async (): Promise<OnlineBroker[]> => {
    const url = `Company/${this.authService.getTenantId()}/online-brokers`;
    const brokers: OnlineBroker[] = await this.authService.fetch(url, {
      method: 'GET',
    });

    return brokers.sort((a, b) => a.name.localeCompare(b.name));
  };

  // Check if start weeks need to be changed to 1
  private checkStartWeek = (startWeek: number) => {
    switch (startWeek) {
      case 52:
      case 53:
      case 1:
        return 1;
      default:
        return startWeek;
    }
  };

  // Check if start weeks need to be changed to 53
  private checkEndWeek = (endWeek: number) => {
    switch (endWeek) {
      case 52:
      case 53:
      case 1:
        return 53;
      default:
        return endWeek;
    }
  };
}
