import { createContext } from 'react';
import ApiService from '../service/apiService';
import AuthService from '../service/authService';

const dummyApiService = new ApiService(
  new AuthService({
    domain: '',
    tenantId: '',
    language: 'fi',
    environment: {},
  })
);

const ApiContext = createContext<ApiService>(dummyApiService);

export default ApiContext;
