import { ReactNode, memo, useContext } from 'react';
import colors from '../../../colors';
import { CONTENT_MARGIN } from '../../../styleConstants';
import { UiTexts } from '../../../model';
import { LanguageContext } from '../../../contexts/language-context';
import { Button, Dialog, Link, Stack, Typography } from '@mui/material';

type InputProps = {
  titleKey: keyof UiTexts;
  open: boolean;
  closeAction: () => void;
  children?: ReactNode;

  // If null, primary action will be closeAction and back button will be hidden
  primaryAction?: (() => void) | null;
  // If not given, primary button label will be the default text "Ok"
  buttonLabelKey?: keyof UiTexts;
};

const TypographyHeaderSx = {
  fontFamily: 'SpaceGrotesk-Medium',
  fontSize: '1.25rem',
  fontStyle: 'normal',
  fontWeight: 500,
  color: colors.secondaryHeader,
  overflowWrap: 'anywhere',
};

function VingoDialog(props: InputProps) {
  const { titleKey, buttonLabelKey, open, closeAction, primaryAction, children } = props;

  const { getText } = useContext(LanguageContext);

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: colors.contentBackground,
          boxShadow: 'none',
          borderRadius: 0,
          padding: CONTENT_MARGIN,
        },
      }}
      onClose={closeAction}
      aria-labelledby='vingo2-dialog-title'
      open={open}
    >
      <Typography variant='h2' id='vingo2-dialog-title' sx={TypographyHeaderSx}>
        {getText(titleKey)}
      </Typography>
      {children}
      <Stack direction={'row-reverse'} spacing={2} sx={{ marginTop: CONTENT_MARGIN }}>
        <Button variant='contained' onClick={primaryAction ?? closeAction} sx={{ width: '120px' }}>
          <Typography py={1} variant='button'>
            {buttonLabelKey ? getText(buttonLabelKey) : getText('dialog-ok')}
          </Typography>
        </Button>
        {primaryAction ? (
          <Link component='button' underline='none' onClick={closeAction}>
            {getText('dialog-cancel')}
          </Link>
        ) : null}
      </Stack>
    </Dialog>
  );
}

export default memo(VingoDialog);
